import { Dialog, DialogPanel } from '@headlessui/react';
import IconSvg from '../ui/IconSvg';
import { useState, useEffect } from 'react';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  openRedeemedModal: (value: boolean) => void;
  rerollsLeft: number;
  nftId: string;
  claimNft: () => void;
  rerollNft: () => void;
  isClaimingNft: boolean;
  isRerollingNft: boolean;
  selectedNft: { cover: string; name: string; credits: number } | null; // Add this line
}

function ClaimNftLotteryModal({
  isOpen,
  onClose,
  openRedeemedModal,
  rerollsLeft,
  claimNft,
  isClaimingNft,
  selectedNft,
  isRerollingNft,
  rerollNft,
  nftId,
}: Props) {
  return (
    <Dialog open={isOpen} as="div" className="relative z-20 focus:outline-none" onClose={onClose}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-grayscale-900/50">
        <div className="flex items-center justify-center min-h-full p-4">
          <DialogPanel
            transition
            className="relative w-full p-5 3xl:px-8 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700 max-h-[95vh] overflow-y-auto no-scrollbar"
          >
            {/* <button className="absolute top-[11px] right-[13px]" onClick={() => onClose(false)}>
              <IconSvg icon="closeM" className="text-grayscale-400" />
            </button> */}

            <div className="max-w-[595px] space-y-6">
              <img
                src={
                  nftId ? `https://lootyfi.s3.eu-central-1.amazonaws.com/arena-claim.png` : 'https://lootyfi.s3.eu-central-1.amazonaws.com/arena-claim.png'
                }
                className="max-w-[35vh] 4xl:max-w-full rounded-md w-full mx-auto"
                alt={`NFT ID#${nftId ? nftId : 'N/A'}`}
              />

              <h2 className="uppercase text-brand-primary-light text-[32px] 3xl:text-[48px]">
                {nftId ? `${(parseFloat(nftId.toString() || "0") / Math.pow(10, 18)).toFixed(2)} THE ARENA` : 'N/A'}
              </h2>

              <div className="w-full border-b-2 border-grayscale-700"></div>

              <div className="flex items-center justify-between">
                {/* <div>
                  <div className="mb-[5px] text-sm text-grayscale-300">Estimated value</div>

                  <div className="font-semibold 3xl:text-lg text-grayscale-100">≈ 0,000</div>
                </div> */}

                <div>
                  <div className="mb-[5px] text-sm text-grayscale-300">Points earned</div>

                  <div className="flex items-center gap-[9px]">
                    <IconSvg icon="starS" />

                    <div className="font-semibold 3xl:text-lg text-grayscale-100">220</div>
                  </div>
                </div>

                <button
                  className={`py-[6px] text-grayscale-200 px-[16px] bg-rarity-uncommon-dark flex gap-2 font-semibold text-sm h-fit rounded-md items-center`}
                >
                  <IconSvg icon="uncommonM" />
                  <span>Uncommon</span>
                </button>
              </div>

              <div className="flex flex-col">
                <h5 className="text-grayscale-200 mb-[11px]">Description</h5>

                <p className="text-grayscale-400 text-[14px] 3xl:text-[16px]">
                Congratulations! You’ve won Arena tokens! Claim your reward to confirm the transaction and receive your tokens. If your key has re-rolls, you can also try your luck by re-rolling. Please note that once you re-roll, you won’t be able to claim this reward.
                </p>
              </div>

              <div
                className="flex flex-wrap items-center justify-center gap-x-6 gap-y-4"
                // onClick={() => onClose(false)}
              >
                <Button
                  size="m"
                  type="primary"
                  classNames="min-w-[232px] !text-base 3xl:!text-lg"
                  onClick={rerollNft}
                  disabled={isClaimingNft || isRerollingNft || rerollsLeft == 0}
                >
                  {isRerollingNft ? (
                    <SyncLoader color="#fff" size={8} />
                  ) : (
                    `Re-roll (${rerollsLeft})`
                  )}
                </Button>

                  
                <Button
                  size="m"
                  type="secondary"
                  classNames="min-w-[232px] !text-base 3xl:!text-lg"
                  onClick={claimNft}
                  disabled={isRerollingNft || isClaimingNft}
                >
                  {isClaimingNft ? <SyncLoader color="#fff" size={8} /> : 'Claim'}
                </Button>
              </div>

              {/* <div className="flex items-center justify-center gap-1 mx-auto text-center cursor-pointer text-brand-primary-light">
                <div className="underline underline-offset-2">Share on</div>

                <IconSvg icon="twitterM" className="text-brand-primary-light" />
              </div> */}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default ClaimNftLotteryModal;
