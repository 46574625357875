import { ethers, utils } from 'ethers';
import LootyArena2TokenABI from '../contracts/LootyArena2TokenABI.json';
import LootyFlatDisbursement from '../contracts/LootyFlatDisbursement.json';

export async function getArenaBalanceOf(
  provider: ethers.providers.Web3Provider,
  contractAddress: string,
  userAddress: string,
  abi: any
): Promise<string> {
  const contract = new ethers.Contract(contractAddress, abi, provider);
  const balance = await contract.balanceOf(userAddress);
  console.log('getArenaBalanceOf return',balance.toString())
  return utils.formatEther(balance.toString());
}

export async function checkIsHashUsed(
  provider: ethers.providers.Web3Provider,
  contractAddress: string,
  hash: string,
): Promise<boolean> {
  const contract = new ethers.Contract(contractAddress, LootyFlatDisbursement, provider);
  const isUsed: boolean = await contract.usedHashes(hash);
  console.log('checkIsHashUsedPhase1() return', isUsed)

  return isUsed;
}



export async function checkIsHashUsedPhase2(
  provider: ethers.providers.Web3Provider,
  contractAddress: string,
  address: string,
  whitelistEntry: {
    signature: string;
    baseTokenAmount: number;
    tokenAmount: string[];
  },
  arenaToken: string
): Promise<boolean> {
  if (!address || !whitelistEntry) {
    console.error("Invalid address or whitelist entry");
    return false;
  }


  const messageHash = ethers.utils.solidityKeccak256(
    ['address', 'uint256', 'uint256', 'address[]', 'uint256[]'],
    [
      address,
      whitelistEntry.baseTokenAmount,
      1,
      [arenaToken],
      whitelistEntry.tokenAmount,
    ]
  );

  const contract = new ethers.Contract(contractAddress, LootyFlatDisbursement, provider);
  const isUsed: boolean = await contract.usedHashes(messageHash);
  console.log('checkIsHashUsedPhase2() return', isUsed)
  return isUsed;
}
