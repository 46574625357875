import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';

interface Props {
  collection: Collection;
}


function AvaxCollectionBanner({ collection }: Props) {
  console.log('colection',collection)
  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{ backgroundImage: `url(/assets/images/avax-banner.png)`, backgroundSize: 'cover' }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="z-10 flex justify-end">
        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of rewards</div>

            <div className="text-[24px] font-bold text-grayscale-100">≈ $2,118.00</div>
          </div>

          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Minted keys</div>

            <div className="text-[24px] font-bold text-grayscale-100">0,000/0,000</div>
          </div>
        </div>
      </div>

      <div className="z-10 flex items-end justify-between">
        <div className="flex gap-[19px] items-center">
          <div className="w-[109px] h-[109px] bg-grayscale-900 rounded-md"></div>

          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-end">
              <h3 className="uppercase text-brand-primary-light">{collection.name}</h3>

              <div className="text-sm text-grayscale-200">AVAX</div>
            </div>

            <div>
              <div className="flex gap-[9px] items-center">
                <div className="space-y-[5px]">
                  <button className="flex items-center gap-2 px-4 py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.11111 1H1V11H2.11111H9.88889H11V1H9.88889H2.11111ZM9.88889 2.11111V9.88889H2.11111V2.11111H9.88889ZM4.33333 5.44444H3.22222V8.77778H4.33333V5.44444ZM5.44444 3.22222H6.55556V8.77778H5.44444V3.22222ZM8.77778 6.55556H7.66667V8.77778H8.77778V6.55556Z"
                        fill="#E6E6E6"
                      />
                    </svg>

                    <span>Leaderboard</span>
                  </button>
                </div>

                <IconSvg icon="webM" className="text-grayscale-400" />

                <IconSvg icon="twitterM" className="text-grayscale-400" />

                <IconSvg icon="discordM" className="text-grayscale-400" />

                <IconSvg icon="hyperspaceM" className="text-grayscale-400" />
              </div>

              <a className="underline text-xss text-grayscale-300" href="#">
                Get more points
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
            <IconSvg icon="liveS" className="text-system-success" />

            <span className="text-sm text-grayscale-100">Claiming live</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvaxCollectionBanner;
