import { useState, useEffect } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onTimeOver: () => void;
}


// const date = process.env.REACT_APP_RAFFLE_DATE;
const date = '2024-09-12T04:19:00';

const RaffleTimerModal = ({ isOpen, onClose, onTimeOver }: Props) => {
  const futureDate = new Date(date || '');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [hasTimeOverTriggered, setHasTimeOverTriggered] = useState(false); // State to track if onTimeOver has been called

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);

      // Check if the countdown has reached zero and onTimeOver has not been called yet
      if (
        timeLeft.days === 0 &&
        timeLeft.hours === 0 &&
        timeLeft.minutes === 0 &&
        timeLeft.seconds === 0 &&
        !hasTimeOverTriggered
      ) {
        onTimeOver(); // Call onTimeOver once
        setHasTimeOverTriggered(true); // Set flag to true to prevent further calls
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hasTimeOverTriggered, onTimeOver]); // Add dependencies

  // Function to calculate the time left
  function calculateTimeLeft() {
    const difference = +futureDate - +new Date();
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }

  return (
    <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-grayscale-900/50">
        <div className="flex items-center justify-center min-h-full p-4">
          <DialogPanel className="relative w-full p-10 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700">
            <div className="w-[760px] space-y-6 text-center">
              <h3 className="uppercase text-brand-primary-light">
                Raffle is <span className="text-brand-primary">live</span>
              </h3>

              {/* Countdown Timer */}
              <div className="flex justify-center space-x-4">
                {['days', 'hours', 'minutes', 'seconds'].map((unit, index) => (
                  <div key={index} className="text-center">
                    <div className="text-[48px] font-joystix font-normal text-brand-primary-light bg-grayscale-900 p-4 rounded-md">
                      {timeLeft[unit as keyof typeof timeLeft] < 10
                        ? `0${timeLeft[unit as keyof typeof timeLeft]}`
                        : timeLeft[unit as keyof typeof timeLeft]}
                      <div className="uppercase text-xs font-normal font-jetbrains leading-4 text-grayscale-500">
                        {unit}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <p className="text-sm font-normal font-jetbrains text-grayscale-200">
                Your raffle tickets: <span className="text-lg font-semibold text-grayscale-100">05</span>
              </p>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default RaffleTimerModal;
