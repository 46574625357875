import { useEffect, useState } from 'react';
import IconSvg from 'src/components/common/ui/IconSvg';
import { useHttp } from 'src/hooks';
import { Collection } from 'src/components/common/cards/types/types';
import { useNavigate } from 'react-router-dom';
import OpenboxTableRow from './components/OpenboxTableRow';
import OpenBoxCard from './components/OpenBoxCard';

export default function OpenBox() {
  const navigate = useNavigate();

  const { sendRequest } = useHttp();

  const [activeTab, setActiveTab] = useState(() => localStorage.getItem('activeTab') || 'all');
  const [activeView, setActiveView] = useState(() => localStorage.getItem('activeView') || 'grid');

  const tabs: { value: string; label: string; icon: React.ReactElement }[] = [
    { value: 'all', label: 'All projects', icon: <IconSvg icon="allS" className="text-white" /> },
    {
      value: 'expired',
      label: 'Expired projects',
      icon: <IconSvg icon="calendarS" className="text-white" />,
    },
    {
      value: 'airdrop',
      label: 'Airdrop',
      icon: <IconSvg icon="mintS" className="text-white" />,
    },
  ];

  const [collections, setCollections] = useState<Collection[]>([]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
    localStorage.setItem('activeView', activeView);
  }, [activeTab, activeView]);

  const getCollections = () => {
    sendRequest(
      {
        url: 'collections',
        method: 'GET',
      },
      (data: any) => {
        const sortedCollections = data.sort((a: any, b: any) => {
          const stageA = a.stages[0] || {};
          const stageB = b.stages[0] || {};

          if (stageA.isLive && !stageB.isLive) return -1;
          if (!stageA.isLive && stageB.isLive) return 1;

          if (stageA.isSoon && !stageB.isSoon) return -1;
          if (!stageA.isSoon && stageB.isSoon) return 1;

          if (stageA.isExpired && !stageB.isExpired) return 1;
          if (!stageA.isExpired && stageB.isExpired) return -1;

          return 0;
        });
        setCollections(sortedCollections);

        if (activeTab === 'expired') {
          const expiredProjects = data.filter((collection: any) => {
            return collection.stages && collection.stages[0]?.isExpired === true;
          });
          setCollections(expiredProjects);
        } else {
          setCollections(sortedCollections);
        }
      },
    );
  };

  useEffect(() => {
    getCollections();
  }, [activeTab]);

  const handleClick = (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => {
    switch (buttonType) {
      case 'openbox':
        navigate(`/openbox/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: collection?.id },
        });
        break;
      case 'mint':
        navigate(`/mint/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: collection?.id },
        });
        break;
      case 'checkReward':
        navigate(`/mint/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: collection?.id },
        });
        break;
      default:
        console.warn('Unhandled button type:', buttonType);
        break;
    }
  };

  return (
    <>
      <div className="flex flex-col mb-[51px]">
        <p className="font-joystix font-normal lg:text-[48px] text-brand-primary-light uppercase">
          Open box to get <span className="text-brand-primary">rewards</span>
        </p>

        <div className="flex items-center justify-between">
          <p className="font-jetbrains font-normal text-sm text-[#D7CCE0] mt-3 leading-[19px]">
            Open box using your keys and get rewards from your favorite project. Your NFT will be
            burned once you use your <br className="hidden lg:block" /> first key. Every key will
            get you one reward.
          </p>

          <div className="flex items-center space-x-8 divide-x-2 divid divide-[#3E3E3E]">
            <div className="flex flex-col space-y-2">
              <p className="text-xs font-normal leading-4 text-grayscale-400">
                Total value of rewards
              </p>

              <p className="text-lg font-semibold leading-6 text-right text-grayscale-100">
                ≈ $12,118.00
              </p>
            </div>

            <div className="flex flex-col pl-4 space-y-2">
              <p className="text-xs font-normal leading-4 text-grayscale-400">Total minted keys</p>

              <p className="text-lg font-semibold leading-6 text-right text-grayscale-100">1,991</p>
            </div>

            <div className="flex flex-col pl-4 space-y-2">
              <p className="text-xs font-normal leading-4 text-grayscale-400">Total rewards won</p>

              <p className="text-lg font-semibold leading-6 text-right text-grayscale-100">445</p>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="flex items-center justify-between px-5 py-3 mb-10 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded flex items-center gap-2 cursor-pointer ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                {tab.icon}

                <div
                  className={`text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>

          <div className="flex gap-2">
            <div
              onClick={() => setActiveView('grid')}
              className={`flex items-center justify-center w-10 h-10 rounded-md cursor-pointer ${
                activeView === 'grid' ? 'bg-brand-secondary text-grayscale-800' : 'bg-gray-700'
              }`}
            >
              <IconSvg
                icon="gridM"
                className={activeView === 'grid' ? 'text-grayscale-800' : 'text-grayscale-200'}
              />
            </div>

            <div
              onClick={() => setActiveView('list')}
              className={`flex items-center justify-center w-10 h-10 rounded-md cursor-pointer ${
                activeView === 'list' ? 'bg-brand-secondary text-grayscale-800' : 'bg-gray-700'
              }`}
            >
              <IconSvg
                icon="listM"
                className={activeView === 'list' ? 'text-grayscale-800' : 'text-grayscale-200'}
              />
            </div>
          </div>
        </div>
      </section>

      {activeView === 'list' ? (
        <div className="flex flex-col my-10">
          <div className="grid grid-cols-12">
            <div className="col-span-4 text-sm font-normal text-grayscale-300">Project</div>
            <div className="col-span-2 text-sm font-normal text-grayscale-300">Rewards pool</div>
            <div className="col-span-3 text-sm font-normal text-grayscale-300">Rewards claimed</div>
            <div className="col-span-1 text-sm font-normal text-grayscale-300">Time</div>
            <div className="col-span-2"></div>
          </div>

          {collections?.map((collection: Collection, index: number) => (
            <OpenboxTableRow key={index} collection={collection} handleClick={handleClick} />
          ))}
        </div>
      ) : (
        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(412px,1fr))]">
          {collections?.map((collection: Collection, index: number) => (
            <OpenBoxCard key={index} collection={collection} handleClick={handleClick} />
          ))}
        </div>
      )}
    </>
  );
}
