import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collection } from 'src/components/common/cards/types/types';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { useHttp } from 'src/hooks';
import MintCard from './components/MintCard';
import MintTableRow from './components/MintTableRow';
import BlurTableRow from './components/BlurTableRow';
import {ethers} from "ethers";
import {useAccount} from "wagmi";

function Mint() {
  const [collections, setCollections] = useState<Collection[]>([]);
  const { sendRequest } = useHttp();
  const navigate = useNavigate();
  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const [activeTab, setActiveTab] = useState(() => localStorage.getItem('activeTab') || 'all');

  const [activeView, setActiveView] = useState('list');

  const tabs: { value: string; label: string; icon: Icons }[] = [
    { value: 'all', label: 'All projects', icon: 'allS' },
    {
      value: 'soon',
      label: 'Soon',
      icon: 'soonS',
    },
    {
      value: 'expired',
      label: 'Expired',
      icon: 'calendarS',
    },
  ];

  useEffect(() => {
    // Save the selected tab and view in localStorage whenever they change
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    getCollections();
  }, [activeTab]);

  const getCollections = () => {
    sendRequest(
      {
        url: 'collections',
        method: 'GET',
      },
      (data: any) => {
        setCollections(data);
      },
    );
  };

  const { address, isConnected } = useAccount();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const readMintedKeyEvents = async () => {
    try {
      const arenaCollection = collections.filter(collection => collection.slug == 'arena')[0];
      const phase1Config = arenaCollection?.stages?.filter(stage => stage.order === 1)[0]?.metadata[
        environment
      ]; // right now just for arena
      if (!phase1Config) return;

      const provider = new ethers.providers.JsonRpcProvider(arenaCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const eventFilter = contract1.filters.TransferSingle(
        null,
        '0x0000000000000000000000000000000000000000',
      );
      const fromBlock = 1;
      const toBlock = 'latest';

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events);
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        return sum + ev?.args?.value.toNumber();
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
    } catch (error) {
      console.error('Error initializing event listener:', error);
    }
  };
  useEffect(() => {
    if (address && isConnected && collections?.length > 0) readMintedKeyEvents();
  }, [address, isConnected, collections]);

  const handleClick = (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => {
    switch (buttonType) {
      case 'openbox':
        // navigate(`/openbox/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
        //   state: { id: collection?.id },
        // });
        navigate(`/avax/openbox/${collection?.id}`);
        break;
      case 'mint':
        navigate(`/mint/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: collection?.id },
        });
        break;
      case 'checkReward':
        navigate(`/mint/${collection?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: collection?.id },
        });
        break;
      default:
        console.warn('Unhandled button type:', buttonType);
        break;
    }
  };

  const filteredCollections = collections.filter(collection => {
    if (activeTab === 'all') return true; // Show all projects
    if (activeTab === 'soon') return collection.status === 'Soon';
    if (activeTab === 'expired') return collection.status === 'Expired';
    return false;
  });
  // if (searchQuery)
  //   filteredCollectionsEarn = filteredCollectionsEarn.filter(
  //     collection =>
  //       collection.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
  //       !collection.isEarnBlured,
  //   );

  return (
    <>
      <section className="mb-[51px]">
        <div className="flex flex-col gap-5 justify-between items-start lg:items-end lg:flex-row">
          <div>
            <h2 className="uppercase mb-[19px] text-brand-primary-light">
              <span className="text-brand-primary">mint keys</span> to open box
            </h2>

            <p className="text-[#D7CCE0] max-w-[1032px] text-sm lg:text-lg text-grayscale-20">
              Our top rewards celebrate exceptional achievements, offering unique digital
              collectibles and unforgettable real-life experiences. Browse through the latest
              claimed rewards. Join our community of winners and claim your hot rewards!
            </p>
          </div>

          <div>
            <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
              Total value of rewards
            </div>

            <div className="text-lg font-semibold text-grayscale-100 lg:text-end">≈ $370,000</div>
          </div>
        </div>
      </section>

      <section className="mb-10">
        <div className="flex justify-between items-center px-5 py-3 mb-5 rounded-md lg:mb-10 bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none flex items-center gap-2 ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <IconSvg
                  icon={tab.icon}
                  className={activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'}
                />

                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeView === 'list' ? (
          filteredCollections?.length === 0 ? (
            <div className="px-5 py-[15px] rounded-md items-center bg-grayscale-800 h-[90px] flex">
              <h5 className="text-brand-primary-light">No projects found</h5>
            </div>
          ) : (
            <div>
              <div className="hidden mb-5 lg:grid grid-cols-15">
                <div className="col-span-3 pl-5 text-xs 2xl:text-sm text-grayscale-300">
                  Project
                </div>
                <div className="col-span-3 text-xs text-center 2xl:text-sm text-grayscale-300">
                  Total Reward Value
                </div>
                <div className="col-span-2 text-xs 2xl:text-sm text-grayscale-300 text-start">
                  Network Name
                </div>
                <div className="col-span-3 text-xs 2xl:text-sm text-grayscale-300">Keys Minted</div>
                <div className="col-span-2 text-xs 2xl:text-sm text-grayscale-300">Status</div>
                <div className="col-span-2"></div>
              </div>

              {[...filteredCollections]
                ?.filter(collection => !collection.isEarnBlured)
                .map((collection: Collection, index: number) => (
                  <MintTableRow
                    arenaTotalMintedKeys={arenaTotalMintedKeys}
                    key={index}
                    index={index + 1}
                    collection={collection}
                    handleClick={handleClick}
                  />
                ))}

              {[...filteredCollections]
                ?.filter(collection => collection.isEarnBlured)
                .slice(0, 2)
                .map((collection: Collection, index: number) => (
                  <MintTableRow
                    arenaTotalMintedKeys={arenaTotalMintedKeys}
                    key={index}
                    index={index + 1}
                    collection={collection}
                    handleClick={handleClick}
                  />
                ))}
            </div>
          )
        ) : (
          <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(412px,1fr))]">
            {filteredCollections
              ?.filter(collection => !collection.isEarnBlured)
              .map((collection: Collection, index: number) => (
                <MintCard key={index} collection={collection} handleClick={handleClick} />
              ))}

            {filteredCollections
              ?.filter(collection => collection.isEarnBlured)
              .map((collection: Collection, index: number) => (
                <MintCard key={index} collection={collection} handleClick={handleClick} />
              ))}
          </div>
        )}
      </section>
    </>
  );
}

export default Mint;
