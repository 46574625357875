import { Dialog, DialogPanel } from '@headlessui/react';
import IconSvg from '../ui/IconSvg';
import { useState } from 'react';
import Button from 'src/components/button/Button';

interface Step {
  step: number;
  title: string;
  link?: string;
  linkText?: string;
  button?: boolean;
  buttonText?: string;
  description?: string;
  image: string;
}

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  steps: Step[];
  handleEnter?: () => void;
}

function BuyEgldTutorialModal({ isOpen, onClose, steps, handleEnter }: Props) {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < steps.length - 1) setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => {
    if (currentStep > 0) setCurrentStep(prev => prev - 1);
  };

  const handleClose = (value: boolean) => {
    setCurrentStep(0); // Reset currentStep to the initial value
    onClose(value); // Call the parent onClose function
  };
  

  const currentData = steps[currentStep];

  return (
    <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={handleClose}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-grayscale-900/50">
        <div className="flex items-center justify-center min-h-full p-4">
          <DialogPanel className="relative w-full lg:p-8 p-5 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700">
            <button className="absolute top-[11px] right-[13px]" onClick={() => onClose(false)}>
              <IconSvg icon="closeM" className="text-grayscale-400" />
            </button>

            <div className="max-w-[550px] flex flex-col lg:mt-0 mt-4">
              <img src={currentData.image} className="rounded-md w-[400px] mx-auto" alt="Step" />

              <p className="lg:text-[32px] text-lg font-joystix mt-8 font-normal text-brand-primary-light lg:leading-10 leading-5">
                {currentData.title}
              </p>
              {console.log('currentData.link', currentData.link)}
              {currentData.link && currentData.linkText && (
                <a
                  href={currentData.link}
                  className="lg:text-lg text-sm lg:font-extrabold mt-2 font-normal text-brand-primary underline underline-offset-2"
                  rel="noreferrer"
                  target="_blank"
                >
                  {currentData.linkText}
                </a>
              )}

              <div className="flex justify-between mt-10">
                {currentStep > 0 ? (
                  <div className="flex items-center cursor-pointer">
                    <IconSvg icon="arrowLeftM" className="text-grayscale-500 w-6 h-6" />
                    <button className="px-1 py-2 text-sm text-grayscale-500" onClick={prevStep}>
                      Back
                    </button>
                  </div>
                ) : (
                  <div></div> // Keeps alignment
                )}

                <div className="flex items-center cursor-pointer">
                  {currentData.button ? ( // Check if button exists in the current step
                    <Button size="xs" type="primary" classNames="" onClick={() => handleEnter?.()}>
                      {currentData.buttonText}
                    </Button>
                  ) : (
                    <>
                      <button
                        className={`px-1 py-2 cursor-pointer text-base font-bold ${
                          currentStep === steps.length - 1
                            ? 'text-grayscale-500'
                            : 'text-grayscale-100'
                        }`}
                        disabled={currentStep === steps.length - 1}
                        onClick={nextStep}
                      >
                        Next
                      </button>
                      <IconSvg icon="arrowRightM" className="text-grayscale-100 w-6 h-6" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default BuyEgldTutorialModal;
