import IconSvg from 'src/components/common/ui/IconSvg';

type Props = {
  status: 'claimable' | 'claimed';
  onClick: () => void;
};

export default function MultiversClaimBtn({ status, onClick }: Props) {
  return (
    <button
      className={`flex items-center justify-center gap-2 py-2 rounded-md text-sm font-bold  ${
        status === 'claimable'
          ? 'text-grayscale-900 bg-brand-primary-light w-[132px]'
          : 'text-system-success w-[111px]'
      }`}
      onClick={onClick}
    >
      <span>{status === 'claimable' ? 'Claim' : 'Claimed'}</span>

      <IconSvg icon={status === 'claimable' ? 'reloadS' : 'checkS'} />
    </button>
  );
}
