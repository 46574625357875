import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Collection } from 'src/components/common/cards/types/types';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';
import SyncLoader from "react-spinners/SyncLoader";

interface Props {
  index?: number;
  arenaTotalMintedKeys?: string;
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
}

function HomeEarnItem({ index, collection, handleClick, arenaTotalMintedKeys }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to toggle accordion
  const navigate = useNavigate();
  const getIconName = (networkCurrency: string): Icons => {
    switch (networkCurrency) {
      case 'AVAX':
        return 'avaxM';
      case 'ETH':
        return 'ethM';
      case 'BNB':
        return 'bnbM';
      case 'EGLD':
        return `egld`;
      case 'SOL':
        return 'solM';
      case 'SUI':
        return 'suiM';
      default:
        return 'ethM'; // Default icon if currency is not listed
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  const iconName = getIconName(collection.network.networkCurrency);

  const isDesktop = window.innerWidth > 1024;

  return (
    <>
      {isDesktop ? (
        <div
          className={`hidden lg:grid grid-cols-10 px-5 py-[15px] rounded-md mb-2.5 last-of-type:mb-0 items-center justify-between cursor-pointer ${
            isAccordionOpen ? 'bg-grayscale-900 border-2 border-grayscale-700' : 'bg-grayscale-800'
          }`}
          onClick={!collection?.isEarnBlured ? toggleAccordion : undefined}
        >
          <div className="flex col-span-3 gap-x-5 items-center text-sm text-grayscale-300">
            <img
              src={
                !collection?.isEarnBlured
                  ? collection.logo
                  : `/assets/images/blur-logo-${index}.png`
              }
              className="w-[60px] h-[60px] bg-cover bg-center"
            />

            <h5 className="text-brand-primary-light max-w-[148px] !leading-[22px] rounded-[5px]">
              {!collection?.isEarnBlured ? (
                collection.name
              ) : (
                <img
                  src={'/assets/images/blur-collection-name-sm.png'}
                  alt="blur collection name"
                />
              )}
            </h5>
          </div>

          <div className="col-span-2">
            {!collection?.isEarnBlured ? (
              <>
                <p className="text-lg font-semibold text-grayscale-100 mb-1.5">
                  ≈ $
                  {collection?.rewardsTotalValue
                    ? Number(collection?.rewardsTotalValue).toLocaleString()
                    : ''}
                </p>

                <p className="text-[11px] text-grayscale-500">rewards total value</p>
              </>
            ) : (
              <img src={'/assets/images/blur-info.png'} alt="blur rewards total value" />
            )}
          </div>

          <div className="col-span-2">
            {!collection?.isEarnBlured ? (
              <>
                <div className="flex items-center gap-[9px] mb-1.5">
                  {/* <p className="text-lg font-semibold text-grayscale-100">from</p> */}
                  <IconSvg icon={iconName} />
                  {/* <p className="text-lg font-semibold text-grayscale-100">0,000</p> */}
                </div>

                <p className="text-[11px] text-grayscale-500">{collection?.network?.networkName}</p>
              </>
            ) : (
              <img src={'/assets/images/blur-info-2.png'} alt="blur price" />
            )}
          </div>

          <div className="col-span-1 text-sm text-grayscale-300">
            {(() => {
              if (collection?.stages[0]?.isExpired) {
                // Expired project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />
                    <span className="text-sm text-grayscale-100">Expired</span>
                  </div>
                );
              } else if (collection?.stages[0]?.isLive) {
                // Live project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="live2M" />
                    <span className="text-sm text-grayscale-100">Live</span>
                  </div>
                );
              } else {
                // Soon project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoon2M" />
                    <span className="text-sm text-grayscale-100">Soon</span>
                  </div>
                );
              }
            })()}
          </div>

          <div className="flex col-span-2 justify-end items-center">
            {!collection?.isEarnBlured ? (
              <button
                onClick={toggleAccordion}
                className={`flex items-center p-2 text-xs rounded-md gap-x-2 w-[107px] justify-center ${
                  isAccordionOpen
                    ? 'bg-brand-primary-light text-grayscale-800'
                    : 'bg-grayscale-700 text-grayscale-200'
                }`}
              >
                <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                <IconSvg
                  icon="arrowDownS"
                  className={`transition-transform duration-300 ${
                    isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                  }`}
                />
              </button>
            ) : (
              <div className="h-[25px] w-[115px] bg-grayscale-700"></div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
            isAccordionOpen
              ? 'bg-grayscale-900 border-grayscale-700'
              : 'bg-grayscale-800 border-transparent'
          }`}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
              <img
                src={
                  !collection?.isEarnBlured
                    ? collection.logo
                    : `/assets/images/blur-logo-${index}.png`
                }
                className="w-[32px] h-[32px] bg-cover bg-center"
              />

              <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                {!collection?.isEarnBlured ? (
                  collection.name
                ) : (
                  <img
                    src={'/assets/images/blur-collection-name-sm.png'}
                    alt="blur collection name"
                  />
                )}
              </h5>
            </div>

            <div className="flex col-span-2 justify-end items-center">
              {!collection?.isEarnBlured ? (
                <button
                  onClick={toggleAccordion}
                  className={`flex items-center p-2 text-xs rounded-md gap-x-2 w-[107px] justify-center ${
                    isAccordionOpen
                      ? 'bg-brand-primary-light text-grayscale-800'
                      : 'bg-grayscale-700 text-grayscale-200'
                  }`}
                >
                  <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                  <IconSvg
                    icon="arrowDownS"
                    className={`transition-transform duration-300 ${
                      isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                    }`}
                  />
                </button>
              ) : (
                <div className="h-[25px] w-[115px] bg-grayscale-700"></div>
              )}
            </div>
          </div>

          <div className="border border-grayscale-700 my-2.5 w-full"></div>

          <div className="flex justify-between items-center">
            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <p className="font-semibold text-grayscale-100 mb-1.5">
                    ≈ $
                    {collection?.rewardsTotalValue
                      ? Number(collection?.rewardsTotalValue).toLocaleString()
                      : ''}
                  </p>

                  <p className="text-xss text-grayscale-500">rewards total value</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info.png'} alt="blur rewards total value" />
              )}
            </div>

            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <IconSvg icon={iconName} className=" mb-1.5" />

                  <p className="text-xss text-grayscale-500">{collection?.network?.networkName}</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info-2.png'} alt="blur price" />
              )}
            </div>

            <div>
              {(() => {
                if (collection?.stages[0]?.isExpired) {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="expired" />
                      <span className="text-xss text-grayscale-100">Expired</span>
                    </div>
                  );
                } else if (collection?.stages[0]?.isLive) {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="liveS" />
                      <span className="text-xss text-grayscale-100">Live</span>
                    </div>
                  );
                } else {
                  return (
                    <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                      <IconSvg icon="comingSoonS" />
                      <span className="text-xss text-grayscale-100">Soon</span>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      )}

      {isAccordionOpen && (
        <>
          {collection.stages
            .filter(st => st?.isShow)
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((stage, index) => (
              <>
                {isDesktop ? (
                  <div
                    key={index}
                    className="hidden lg:grid grid-cols-10 px-5 py-[15px] rounded-md bg-grayscale-900 mb-2.5 last-of-type:mb-0 items-center justify-between border-2 border-grayscale-700"
                  >
                    <div className="flex col-span-3 gap-x-5 items-center text-sm text-grayscale-300">
                      <img src={collection.logo} className="w-[60px] h-[60px]" />
                      <h5 className="text-brand-primary-light max-w-[145px] !leading-[22px]">
                        {stage?.stageName}
                      </h5>
                    </div>

                    <div className="col-span-2">
                      <p className="text-lg font-semibold text-grayscale-100 mb-1.5">
                        ≈ $
                        {collection?.rewardsTotalValue
                          ? Number(collection?.rewardsTotalValue).toLocaleString()
                          : ''}
                      </p>
                      <p className="text-[11px] text-grayscale-500">rewards total value</p>
                    </div>

                    {collection.slug === 'multiversx' && (
                      <>
                        <div className="col-span-2"></div>

                        <div className="col-span-1 text-sm text-grayscale-300">
                          {stage.isExpired ? (
                            <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                              <IconSvg icon="expired" />
                              <span className="text-sm text-grayscale-100">Expired</span>
                            </div>
                          ) : stage.isLive ? (
                            <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                              <IconSvg icon="live2M" />
                              <span className="text-sm text-grayscale-100">Live</span>
                            </div>
                          ) : (
                            <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                              <IconSvg icon="comingSoon2M" />
                              <span className="text-sm text-grayscale-100">Soon</span>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {collection.slug !== 'multiversx' && stage?.isLive && (
                      <>
                        <div className="col-span-3 px-2 text-center">
                          {arenaTotalMintedKeys !== '' ? (
                            <>
                              {stage?.isLive ? (
                                <ProgressBar
                                  progress={
                                    arenaTotalMintedKeys
                                      ? Math.floor((Number(arenaTotalMintedKeys) / 1629) * 100)
                                      : 0
                                  }
                                  size="Full"
                                  totalValue="1629"
                                  estimatedValue={
                                    arenaTotalMintedKeys ? Number(arenaTotalMintedKeys) : 0
                                  }
                                />
                              ) : (
                                '-'
                              )}
                            </>
                          ) : (
                            <SyncLoader color="#B73FFF" size={5} />
                          )}
                        </div>
                      </>
                    )}

                    {stage?.isLive && (
                      <div className="flex col-span-2 justify-end items-center">
                        <Button
                          onClick={() =>
                            collection.slug === 'multiversx'
                              ? navigate(`/leaderboards/${collection.slug}`)
                              : navigate(`/arena/${collection.id}/${stage?.id}`)
                          }
                          type="primary"
                          size="xs"
                          classNames="!min-w-[74px]"
                        >
                          {collection.slug === 'multiversx' ? 'Earn' : 'Claim'}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
                      isAccordionOpen
                        ? 'bg-grayscale-900 border-grayscale-700'
                        : 'bg-grayscale-800 border-transparent'
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
                        <img
                          src={collection.logo}
                          className="w-[32px] h-[32px] bg-cover bg-center"
                        />

                        <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                          {stage?.stageName}
                        </h5>
                      </div>

                      {stage?.isLive && (
                        <Button
                          onClick={() =>
                            collection.slug === 'multiversx'
                              ? navigate(`/leaderboards/${collection.slug}`)
                              : navigate(`/arena/${collection.id}/${stage?.id}`)
                          }
                          type="primary"
                          size="xs"
                          classNames="!min-w-[74px]"
                        >
                          {collection.slug === 'multiversx' ? 'Earn' : 'Claim'}
                        </Button>
                      )}
                    </div>

                    <div className="border border-grayscale-700 my-2.5 w-full"></div>

                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-semibold text-grayscale-100 mb-1.5">
                          ≈ $
                          {collection?.rewardsTotalValue
                            ? Number(collection?.rewardsTotalValue).toLocaleString()
                            : ''}
                        </p>

                        <p className="text-xss text-grayscale-500">rewards total value</p>
                      </div>

                      {collection.slug === 'multiversx' && (
                        <div>
                          {(() => {
                            if (collection?.stages[0]?.isExpired) {
                              return (
                                <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                                  <IconSvg icon="expired" />
                                  <span className="text-xss text-grayscale-100">Expired</span>
                                </div>
                              );
                            } else if (collection?.stages[0]?.isLive) {
                              return (
                                <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                                  <IconSvg icon="liveS" />
                                  <span className="text-xss text-grayscale-100">Live</span>
                                </div>
                              );
                            } else {
                              return (
                                <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                                  <IconSvg icon="comingSoonS" />
                                  <span className="text-xss text-grayscale-100">Soon</span>
                                </div>
                              );
                            }
                          })()}
                        </div>
                      )}

                      {collection.slug === 'arena' && (
                        <div className="w-[51%]">
                          <ProgressBar
                            progress={
                              arenaTotalMintedKeys
                                ? Math.floor((Number(arenaTotalMintedKeys) / 1629) * 100)
                                : 0
                            }
                            size="Full"
                            totalValue="1629"
                            estimatedValue={arenaTotalMintedKeys ? Number(arenaTotalMintedKeys) : 0}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ))}
        </>
      )}
    </>
  );
}

export default HomeEarnItem;
