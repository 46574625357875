import Button from 'src/components/button/Button';
import { Collection } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

interface Props {
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
}

export default function OpenboxTableRow({ collection, handleClick }: Props) {
  const getIconName = (networkCurrency: string): Icons => {
    switch (networkCurrency) {
      case 'AVAX':
        return 'avaxM';
      case 'ETH':
        return 'ethM';
      case 'BNB':
        return 'bnbM';
      case 'SOL':
        return 'solM';
      case 'SUI':
        return 'suiM';
      default:
        return 'ethM'; // Default icon if currency is not listed
    }
  };

  const iconName = getIconName(collection.network.networkCurrency);

  return (
    <>
      {/* Table */}
      <div className="flex flex-col">
        <div className="grid grid-cols-12 px-5 py-4 mt-3 rounded-md bg-grayscale-800">
          <div className="flex items-center col-span-4 space-x-3 text-sm font-normal font-jetbrains text-grayscale-300">
            <img src={collection.logo} className="w-[60px] h-[60px]" />

            <div className="flex items-center gap-5">
              <p className="font-joystix text-brand-primary-light text-lg font-normal leading-[22px]">
                {collection?.name}
              </p>

              <div className="py-1 px-2.5 rounded bg-brand-primary-light w-fit leading-tight">
                <span className="text-sm text-grayscale-900">airdrop</span>
              </div>
            </div>
          </div>

          <div className="flex items-center col-span-2 space-x-2 text-sm font-normal font-jetbrains text-grayscale-300">
            <p className="text-lg font-semibold leading-6 font-jetbrains text-grayscale-100">
              ≈ {collection.rewardsTotalValue}
            </p>

            <IconSvg icon={iconName} />
          </div>

          <div className="col-span-3">
            <ProgressBar
              progress={0}
              size="Small"
              estimatedValue={0}
              totalValue={collection.rewardsTotalValue}
            />
          </div>

          <div className="col-span-1 text-sm font-normal font-jetbrains text-grayscale-300">
            {(() => {
              if (collection?.stages[0].isExpired) {
                // Expired project
                return (
                  <div className="py-2 px-[15px] rounded-md bg-system-error-dark border-system-error mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />
                    <span className="text-sm text-grayscale-100">Expired</span>
                  </div>
                );
              } else if (collection?.stages[0].isLive) {
                // Live project
                return (
                  <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="live2M" />
                    <span className="text-sm text-grayscale-100">Live</span>
                  </div>
                );
              } else {
                // Soon project
                return (
                  <div className="py-2 px-[15px] rounded-md bg-system-warning-dark border-system-warning mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoon2M" />
                    <span className="text-sm text-grayscale-100">Soon</span>
                  </div>
                );
              }
            })()}

            <div className="text-sm font-semibold text-grayscale-200">
              <Countdown stage={collection?.stages[0]} />
            </div>
          </div>

          <div className="flex items-center justify-end col-span-2">
            {(() => {
              if (collection?.stages[0].isExpired) {
                // Expired project
                return (
                  <Button
                    type="ghost"
                    size="m"
                    onClick={() => handleClick(collection, 'checkReward')}
                  >
                    Check rewards
                  </Button>
                );
              } else if (collection?.stages[0].isLive) {
                // Live project
                return (
                  <Button
                    type="primary"
                    size="m"
                    onClick={() => handleClick(collection, 'openbox')}
                  >
                    Open box
                  </Button>
                );
              } else {
                // Soon project
                return (
                  <Button type="secondary" size="m" onClick={() => handleClick(collection, 'mint')}>
                    Mint
                  </Button>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
}
