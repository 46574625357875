import IconSvg from 'src/components/common/ui/IconSvg';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import React, { useEffect, useRef, useState } from 'react';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { ethers } from 'ethers';

import ArenaCollectionBanner from './components/ArenaCollectionBanner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { Collection } from 'src/components/common/cards/types/types';
import RaffleTimerModal from 'src/components/common/modal/RaffleTimerModal';
import RaffleClaimKeysModal from 'src/components/common/modal/RaffleClaimKeysModal';
import NoKeysToClaimModal from 'src/components/common/modal/NoKeysToClaimModal';
import RewardCardQiibee from 'src/components/common/cards/RewardCardQiibee';
import SuccessMintModal from 'src/components/common/modal/SuccessMintModal';
import SuccessRedeemedModal from 'src/components/common/modal/SuccessRedeemedModal';
import {
  useAccount,
  useSignMessage,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import { toast } from 'react-toastify';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';

import LootyKeyABI from '../../../contracts/LootyKeyABI.json';
import LootyTokenLotteryABI from '../../../contracts/LootyTokenLotteryABI.json';

import PoolSelect from 'src/components/common/PoolSelect.tsx';
import { ConnectWallet } from 'src/components/common/ConnectWallet';
import ClaimNftLotteryModal from 'src/components/common/modal/ClaimNftLotteryModal';
import { useArenaBalanceOf } from 'src/utilities/looty';

const environment = process.env.REACT_APP_ENVIRONMENT;

import whitelist_dev_p1 from '../../../whitelist_dev_p1.json';
import CollectionDetail from 'src/pages/openbox/CollectionDetail';
import RewardArenaCard from 'src/components/common/cards/RewardArenaCard';
import { rewardArenaCardData } from 'src/dummydata';
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string // For cases where the value is just a string
    | {
        signature: string;
        keysOfEachType: number[];
      }; // For cases where the value is an object with these properties
}
const whitelistTypedP1: Whitelist = whitelistP1;

const nftLotteryContractConfig = {
  address: process.env.REACT_APP_LOOTY_TOKEN_LOTTERY_CONTRACT_ADDRESS as `0x${string}`,
  abi: LootyTokenLotteryABI,
};

const KEY_NAMES = {
  0: 'Silver Key',
  1: 'Gold Key',
  2: 'Platinum Key',
} as const;

const rewards = [
  [168.37, 1039.53, 3462.66], // Silver tier
  [336.75, 2079.06, 6925.33], // Gold tier
  [673.50, 4158.13, 13850.66] // Platinum tier
];


const rewardIcons = [
  'common-reward.svg',
  'epic-reward.svg',
  'legendary-reward.svg',
  'mythical-reward.svg',
  'rare-reward.svg',
  'uncommon-reward.svg',
];

const data = Array.from({ length: 300 }, (_, index) => {
  const rewardGroup = rewards[index % rewards.length]; // Cycle through rewards groups
  const rewardValue = rewardGroup[index % rewardGroup.length]; // Cycle through rewards in the group

  const imageIndex = (index % 6) + 1; // Cycle through images (1-6)
  const iconIndex = Math.floor(Math.random() * rewardIcons.length); // Randomly pick an icon

  return {
    cover: `/assets/images/reward/arena-reward-${imageIndex}.png`, // Dynamically assign image based on index
    icon: `/assets/images/reward/${rewardIcons[iconIndex]}`, // Dynamically assign random icon
    name: `${rewardValue} ARENA`,
    credits: rewardValue,
  };
});

interface RewardEvent {
  user: string;
  requestId: string;
  nftId?: string;
}

function ArenaCollectionDetail() {
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const { collectionName } = useParams();

  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  const [userLotteryData, setUserLotteryData] = useState<any>(null);
  const [isClaimingNft, setIsClaimingNft] = useState(false);

  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean | null>(null);
  const [isPauseNftContract, setIsPauseNftContract] = useState<boolean | null>(null);
  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);

  const [isClaimNftModal, setIsClaimNftModal] = useState(false);
  const [isRerolling, setIsRerolling] = useState(false);

  const [isRerollingNft, setIsRerollingNft] = useState(false);
  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);

  const [transactionMessage, setTransactionMessage] = useState('');

  const [loadingOpenBoxBtn, setLoadingOpenBoxBtn] = useState(false);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedNft, setSelectedNft] =
    useState<{ cover: string; name: string; credits: number } | null>(null);

  const [formattedEvents, setFormattedEvents] = useState<RewardEvent[]>([]);

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
    isError: isError,
  } = useWriteContract();
  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  const {
    data: hashClaimNft,
    writeContract: claimNftContractWrite,
    isPending: isOpeningNftLottery,
    isError: isErrorClaimNft,
  } = useWriteContract();

  const {
    isLoading: isConfirmingClaimNft,
    isSuccess: isConfirmedClaimNft,
    data: receiptClaimNft,
  } = useWaitForTransactionReceipt({
    hash: hashClaimNft,
  });

  useEffect(() => {
    console.log('isConfirmedClaimNft', isConfirmedClaimNft);
    console.log('isConfirmingClaimNft', isConfirmingClaimNft);
    console.log('isErrorClaimNft', isErrorClaimNft);
  }, [isConfirmingClaimNft, isConfirmedClaimNft]);

  const {
    data: hashReroll,
    writeContract: RerollContractWrite,
    isPending: isPendingReroll,
    isError: isErrorReroll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingReroll,
    isSuccess: isConfirmedReroll,
    data: receiptReroll,
  } = useWaitForTransactionReceipt({
    hash: hashReroll,
  });

  useEffect(() => {
    console.log('================================');
    console.log('isConfirmingReroll', isConfirmingReroll);
    console.log('isConfirmedReroll', isConfirmedReroll);
    console.log('receiptReroll', receiptReroll);
    console.log('isPendingReroll', isPendingReroll);
    console.log('isErrorReroll', isErrorReroll);
    console.log('================================');
  }, [isConfirmedReroll, isConfirmingReroll]);

  const {
    data: hashSetApprovedForAll,
    writeContract: writeApprovelForAll,
    isPending: isPendingApprovalForAll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingApprovalForAll,
    isSuccess: isConfirmedApprovalForAll,
    data: receiptApprovalForAll,
  } = useWaitForTransactionReceipt({
    hash: hashSetApprovedForAll,
  });

  // useEffect(() => {
  //   console.log('isConfirmedOpenBox', isConfirmedOpenBox);
  //   console.log('isConfirmingOpenBox', isConfirmingOpenBox);

  //   console.log('receiptOpenBox', receiptOpenBox);
  //   if (isConfirmedOpenBox && receiptOpenBox) {
  //     // setLoadingOpenBoxBtn(false); // Ensure loading state is reset
  //     // stopSpin(0); // Ensure spinning stops
  //     setTransactionMessage('Openbox Successful.');
  //     getUserData(); // Update user data after the transaction
  //     handleOpenBox();
  //   }

  //   if (isError) {
  //     setLoadingOpenBoxBtn(false); // Stop loader on error
  //     stopSpin(0);
  //   }

  //   if (isErrorReroll) {
  //     setLoadingOpenBoxBtn(false); // Stop loader on error
  //     setIsRerollingNft(false);
  //   }

  //   if (isErrorClaimNft) {
  //     setLoadingOpenBoxBtn(false); // Stop loader on error
  //     setIsClaimingNft(false);
  //   }
  // }, [
  //   isConfirmingOpenBox,
  //   isConfirmedOpenBox,
  //   receiptOpenBox,
  //   isError,
  //   isErrorReroll,
  //   isErrorClaimNft,
  // ]);

  // useEffect(() => {
  //   if (address) {
  //     readRewardBalance(address);
  //   }
  // }, [address]);

  const getRarity = (reward: number) => {
    if (reward === 20 || reward === 30 || reward === 45) {
      return 'Common';
    } else if (reward === 60 || reward === 80) {
      return 'Uncommon';
    } else if (reward === 100 || reward === 150) {
      return 'Rare';
    }
    return 'Common'; // Default to Common if no match
  };

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = async () => {
    await sendRequest(
      {
        url: `collections/${collectionName}`,
        method: 'GET',
      },
      (data: any) => {
        console.log('data', data);
        setCollectionDetail(data);
      },
    );
  };

  // useEffect(() => {
  //   getCollectionDetail();
  // }, [collectionName]);

  const openRedeemedModal = () => {
    setIsClaimNftModal(false);
    setIsSuccessRedeemedModalOpen(true);
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }
    setLoadingKeys(false);

    console.log('updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  // useEffect(() => {
  //   if (isConnected && collectionDetail) {
  //     fetchBlockchainKeyBalances();
  //     getUserData();
  //   }
  // }, [collectionDetail, address, isConnected, location.pathname]);

  // useEffect(() => {
  //   if (isConnected) {
  //     getCollectionDetail();
  //   }
  // }, [address, isConnected, collectionName]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];
    console.log('readKeyBalanceeeeeeeeeeeeeee phase1Config', phase1Config);
    console.log('RPC OpenBox', collectionDetail?.network.RPCUrl);

    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);

      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log('Number(balance)', Number(balance));
      console.log(' keyIndex', keyIndex, Number(balance));
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const getUserData = async () => {
    try {
      const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
        environment
      ];
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );
      const userData = await contract.userLotteryData(address);
      const isPause = await contract.paused();
      console.log('userData', userData);
      setIsPauseNftContract(isPause);
      setUserLotteryData(userData);
    } catch (error) {
      console.error('Error reading balance:', error);
    }
  };

  const readRewardBalance = async (walletAddress: string) => {
    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log('readRewardBalance RPC', collectionDetail?.network.RPCUrl);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const lootyNftContract = new ethers.Contract(
        nftLotteryContractConfig.address,
        nftLotteryContractConfig.abi,
        provider,
      );

      const filter = lootyNftContract.filters.Fulfilled(walletAddress);
      const events = await lootyNftContract.queryFilter(filter);

      const formattedEvents = events.map(event => ({
        user: event?.args?.user,
        requestId: event?.args?.requestId.toString(),
        nftId: event?.args?.amount.toString(),
      }));
      console.log('formattedEvents', formattedEvents);

      setFormattedEvents(formattedEvents);
    } catch (error) {
      console.error('Error reading rewards:', error);
      return 0;
    }
  };

  const openLootbox = async (nKeys: number) => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];

    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }
    console.log('selectedKey', selectedKey);
    console.log('blockchainKeyBalances', blockchainKeyBalances);
    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    if (isPauseNftContract) {
      toast.error('Contract is Paused, contact admin');
      return;
    }

    try {
      console.log(`startLottery.... ${selectedKey}...`);
      startSpin(); // Start the carousel spin

      setLoadingOpenBoxBtn(true);
      // if (isApprovedForAll) {
      // console.log('openlootbox->isApprovedForAll', isApprovedForAll)
      await openLootboxContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'startLottery',
        args: [selectedKey, nKeys],
      });
      // }
      //  else {
      //   console.log('writeApprovelForAll')
      //   await writeApprovelForAll({
      //     address:phase1Config.LOOTY_KEY.address,
      //     abi:phase1Config.LOOTY_KEY.abi,
      //     functionName: 'setApprovalForAll',
      //     args: [process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS, true],
      //   });
      // }
    } catch (error) {
      setLoadingOpenBoxBtn(false);
      stopSpin(0);
      console.log('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  // useEffect(() => {
  //   if (collectionDetail) {
  //     const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
  //       environment
  //     ];

  //     // const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
  //     const contract = new ethers.Contract(
  //       phase1Config.LOOTY_TOKEN_LOTTERY.address,
  //       phase1Config.LOOTY_TOKEN_LOTTERY.abi,
  //       provider,
  //     );

  //     const onClaimed = (
  //       user: string,
  //       requestId: ethers.BigNumber,
  //       nftContract: string,
  //       nftId: ethers.BigNumber,
  //     ) => {
  //       console.log('Claimed event detected');
  //       if (user.toLowerCase() === address?.toLowerCase()) {
  //         getUserData();
  //         fetchBlockchainKeyBalances();
  //         setIsClaimingNft(false);
  //         setIsClaimNftModal(false);
  //         toast.success('Arena Token Claimed Successfully');
  //       }
  //     };

  //     contract.on('Claimed', onClaimed);

  //     return () => {
  //       contract.off('Claimed', onClaimed);
  //     };
  //   }
  // }, [address, collectionDetail]);

  const claimNft = async () => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];
    console.log('claimNft');
    if (!isConnected) {
      toast.error('Please connect your wallet before claiming the NFT.');
      return;
    }

    // if (Number(userLotteryData?.state) === 2) {
    //   setIsClaimNftModal(false);
    // }

    try {
      console.log('phase1Config', phase1Config);
      setIsClaimingNft(true); // Start loader
      await claimNftContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'claimReward',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsClaimingNft(false);
    }
  };

  // useEffect(() => {
  //   if (collectionDetail) {
  //     const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
  //       environment
  //     ];
  //     // const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
  //     const contract = new ethers.Contract(
  //       phase1Config.LOOTY_TOKEN_LOTTERY.address,
  //       phase1Config.LOOTY_TOKEN_LOTTERY.abi,
  //       provider,
  //     );

  //     const onRolled = (
  //       user: string,
  //       requestId: ethers.BigNumber,
  //       nftContract: string,
  //       nftId: ethers.BigNumber,
  //     ) => {
  //       console.log('ReRoll event detected');
  //       if (user.toLowerCase() === address?.toLowerCase()) {
  //         getUserData();
  //         fetchBlockchainKeyBalances();
  //         // setIsRerollingNft(false);
  //       }
  //     };

  //     contract.on('Rolled', onRolled);

  //     return () => {
  //       contract.off('Rolled', onRolled);
  //     };
  //   }
  // }, [address, collectionDetail]);

  const rerollNft = async () => {
    const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
      environment
    ];

    if (!isConnected) {
      toast.error('Please connect your wallet before re-rolling the NFT.');
      return;
    }

    try {
      setIsRerolling(true);
      setIsRerollingNft(true);
      startSpin();
      await RerollContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'reroll',
      });
    } catch (error) {
      console.log('Error Re-rolling NFT:', error);
      toast.error('Failed to re-roll NFT.');
      setIsRerollingNft(false);
    }
  };

  const startSpin = () => {
    console.log('startSpin().......');
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    console.log('startSpin() cross');
    setSpinning(true);
    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  const stopSpin = (targetCredits: number) => {
    console.log('stopSpin()......', targetCredits);
    console.log('spinning', spinning);
    // if (!spinning) return;
    console.log('spinIntervalRef.current', spinIntervalRef.current);
    if (spinIntervalRef.current) {
      console.log('tututtartara');
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }

    console.log('setSpinning(false)');
    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);
    console.log('targetSlideIndex', targetSlideIndex);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      console.log('aaayooooo');
      const steps = (targetSlideIndex + data.length - activeSlideIndex) % data.length;
      console.log('stpes', steps);
      stackedCarouselRef.current.swipeTo(steps); // Swipe to the selected NFT slide
    }
  };

  // useEffect(() => {
  //   console.log('collectionDetail', collectionDetail);

  //   if (collectionDetail) {
  //     console.log('useEffect => collectionDetail', collectionDetail);
  //     const phase1Config = collectionDetail?.stages.filter(stage => stage.order === 1)[0].metadata[
  //       environment
  //     ];

  //     // const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
  //     const contract = new ethers.Contract(
  //       phase1Config.LOOTY_TOKEN_LOTTERY.address,
  //       phase1Config.LOOTY_TOKEN_LOTTERY.abi,
  //       provider,
  //     );

  //     const onFulfilled = (
  //       user: string,
  //       requestId: ethers.BigNumber,
  //       nftContract: string,
  //       nftId: ethers.BigNumber,
  //     ) => {
  //       console.log('onFulfilled................');
  //       console.log('user......', user);
  //       console.log('address......', address);
  //       if (user.toLowerCase() === address?.toLowerCase()) {
  //         const randomIndex = Math.floor(Math.random() * data.length);
  //         console.log('randomIndex', randomIndex);
  //         const selectedItem = data[randomIndex];
  //         console.log('data[randomIndex]', data[randomIndex]);
  //         setSelectedNft(selectedItem);
  //         getUserData();
  //         fetchBlockchainKeyBalances();

  //         console.log('selectedItem.credits', selectedItem.credits);
  //         stopSpin(selectedItem.credits);
  //         setTransactionMessage('NFT Claimed!');
  //         setLoadingOpenBoxBtn(false);
  //         setIsClaimNftModal(true);
  //         setIsRerolling(false);

  //         setIsRerollingNft(false);
  //         toast.success('Reward Claimed Successfully');
  //       }
  //     };
  //     console.log('onFulfilled calling...........');
  //     contract.on('Fulfilled', onFulfilled);

  //     return () => {
  //       contract.off('Fulfilled', onFulfilled);
  //     };
  //   }
  // }, [address, collectionDetail]);

  useEffect(() => {
    // console.log('isRerolling123', isRerolling)
    console.log('123data', userLotteryData?.state);
  }, [userLotteryData]);

  const handleOpenBox = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-open-box-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
    console.log('Open Box button clicked');
    // Add your logic here
  };

  return (
    <>
      <ClaimNftLotteryModal
        openRedeemedModal={openRedeemedModal}
        isOpen={isRerolling || Number(userLotteryData?.state) === 2}
        onClose={() => setIsClaimNftModal(false)}
        rerollsLeft={userLotteryData?.rerollsLeft}
        nftId={userLotteryData?.candidateTokenAmount}
        claimNft={claimNft}
        isClaimingNft={isClaimingNft}
        rerollNft={rerollNft}
        isRerollingNft={isRerollingNft}
        selectedNft={selectedNft}
      />

      <section className="mb-[36px] xl:mb-[40px]">
        {window.innerWidth > 1024 ? (
          <ArenaCollectionBanner collection={collectionDetail} />
        ) : (
          <div className="w-full bg-gray-800 rounded-md">
            <img src={'/assets/images/arena-banner.png'} className="w-full h-auto max-h-[418px]" />
          </div>
        )}
      </section>

      {isConnected ? (
        <>
          <section className="mb-[72px]">
            <div className="mb-10">
              <ResponsiveContainer
                carouselRef={stackedCarouselRef}
                render={(parentWidth, carouselRef) => {
                  const currentVisibleSlide = 7;

                  return (
                    <StackedCarousel
                      ref={carouselRef}
                      slideComponent={Card}
                      slideWidth={268}
                      carouselWidth={parentWidth}
                      data={data}
                      currentVisibleSlide={currentVisibleSlide}
                      maxVisibleSlide={7}
                      disableSwipe
                      onActiveSlideChange={activeSlide => {
                        setActiveSlideIndex(activeSlide);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="space-y-[32px] flex flex-col items-center justify-center">
              <div className="flex gap-[18px] items-center justify-center">
                {whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType?.map((key, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      blockchainKeyBalances[Number(index)] > 0 && handleCardClick(Number(index))
                    }
                    className={`w-[268px] rounded-md bg-grayscale-800 p-[15px] pb-[17px] cursor-pointer ${
                      selectedKey === Number(index) && blockchainKeyBalances[Number(index)] > 0
                        ? 'border-2 border-purple-500'
                        : ''
                    }`}
                  >
                    <img
                      src={`/assets/images/arenaKeys/${Number(index)}.png`}
                      className={`${
                        blockchainKeyBalances[Number(index)] > 0
                          ? ''
                          : 'opacity-50 cursor-not-allowed' // Apply opacity only to the image if disabled
                      } w-[238px] h-[238px] rounded mb-[18px]`}
                      alt={`Key ${key}`}
                    />

                    <div className="space-y-[9px]">
                      <h5 className="uppercase text-[#FAFAFA]">
                        {`${KEY_NAMES[index as unknown as keyof typeof KEY_NAMES]}`}
                      </h5>

                      <div className="text-xs text-gray-300 space-x-[7px]">
                        <span>Ready to use:</span>
                        <span className="font-semibold pl-[7px]">
                          {isLoadingKeys ? (
                            <SyncLoader color="#B73FFF" size={5} />
                          ) : (
                            blockchainKeyBalances[Number(index)] ?? 0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-[#D7CCE0] text-center">
                <p>Open box using your keys and get rewards from your favorite project.</p>

                <p className="mb-4">
                  Your NFT will be burned once you use your first key. With each key, you get one
                  reward, but some keys allow <br /> re-roll for a chance at another reward.
                </p>

                <p className="mb-4 font-bold">
                  Silver keys don’t include re-rolls, Gold keys include 1 re-roll and Platinum keys
                  include 2 re-rolls. After <br /> each spin, you can either keep your reward or try
                  again until your re-roll limit is reached.
                </p>

                <p>Remember, only one reward can be claimed per key!</p>
              </div>

              <div className="mx-auto w-fit">
                {loadingOpenBoxBtn ? (
                  <SyncLoader color="#B73FFF" size={10} />
                ) : (
                  <div className="flex items-center">
                    <Button size="m" type="primary" classNames="" onClick={() => openLootbox(1)}>
                      Open box
                    </Button>

                    <Button
                      size="m"
                      type="primary"
                      classNames="ml-8"
                      onClick={() => openLootbox(5)}
                    >
                      Open 5 boxes
                    </Button>

                    <Button
                      size="m"
                      type="primary"
                      classNames="ml-8"
                      onClick={() => openLootbox(10)}
                    >
                      Open 10 boxes
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section>
            <div className="mb-[36px] flex justify-between items-center">
              <h3 className="text-brand-primary-light">Rewards</h3>

              <PoolSelect />
            </div>

            <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
              {/* {formattedEvents.map((event, index) => (
                <RewardCardQiibee
                  key={index}
                  name={`${event?.nftId} credits`} // Pass reward value
                  estimatedValue={event?.nftId} // Use reward as estimated value
                  rarity={getRarity(Number(event?.nftId))} // Dynamically calculate rarity
                  image={`/assets/images/arena-card-placeholder.png`} // Use a static or dynamic image path
                  size="medium"
                />
              ))} */}
              {rewardArenaCardData.map((card, index) => (
                <RewardArenaCard
                  key={index}
                  name={card.name}
                  estimatedValue={card.estimatedValue}
                  rarity={card.rarity}
                  image={card.image}
                  size="medium"
                />
              ))}
            </div>
          </section>
        </>
      ) : (
        <div className={`text-center`}>
          <ConnectWallet />
        </div>
      )}
    </>
  );
}

export default ArenaCollectionDetail;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, name, icon } = data[dataIndex];

  return (
    <div className="w-[268px] h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="w-[238px] h-[238px] bg-grayscale-700 rounded relative"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          {/* <IconSvg icon="rareS" className="text-rarity-rare-light" /> */}
          <img src={icon} className="w-4 h-4" />
          <div>{name} </div>
        </div>
      </div>
    </div>
  );
});
