import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Button from 'src/components/button/Button';
import { Raffle } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

interface Props {
  raffle: Raffle;
  handleClick: (raffle: Raffle, buttonType: 'raffle') => void;
}

const getIconName = (networkCurrency: string): Icons => {
  switch (networkCurrency) {
    case 'AVAX':
      return 'avaxS';
    case 'ETH':
      return 'ethS';
    case 'BNB':
      return 'bnbS';
    case 'SOL':
      return 'solS';
    case 'SUI':
      return 'suiS';
    default:
      return 'ethS';
  }
};

function RaffleCard({ raffle, handleClick }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const iconName = getIconName(raffle.network.networkCurrency);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  const icons: { icon: Icons; className: string; label: string }[] = [
    {
      icon: 'usersS',
      className: 'cursor-pointer text-brand-primary-light',
      label: 'Partners raffle',
    },
    { icon: 'starS', className: 'cursor-pointer', label: 'Looty points boost' },
    { icon: 'logoS', className: 'cursor-pointer text-brand-primary', label: 'Looty raffle' },
  ];

  return (
    <>
      <div
        className={`py-5 px-[19.5px] rounded-md ${
          isAccordionOpen ? 'bg-grayscale-900 border-2 border-grayscale-800' : 'bg-grayscale-800'
        }`}
      >
        <div
          className="bg-grayscale-900 w-full h-[262px] rounded-md mb-[16px] relative p-3 flex justify-between items-end"
          style={{
            backgroundImage: `url(${raffle.image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <img src={raffle?.logo} className="w-[60px] h-[60px]" />

          <button
            onClick={toggleAccordion}
            className={`flex items-center p-2 rounded-md gap-x-2 w-[153px] justify-center ${
              isAccordionOpen
                ? 'bg-brand-primary-light text-grayscale-800'
                : 'bg-grayscale-700 text-grayscale-200'
            }`}
          >
            <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

            <IconSvg
              icon="arrowRightM"
              className={`transition-transform duration-300 ${
                isAccordionOpen ? '-rotate-180 text-grayscale-800' : 'text-brand-primary-light'
              }`}
            />
          </button>
        </div>

        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center gap-3.5">
            <h5 className="text-brand-primary-light max-w-[255px] truncate">{raffle?.name}</h5>

            <div className="flex items-center gap-[5px]">
              {icons.map(({ icon, label, className }, index) => (
                <div key={index}>
                  <div key={index} data-tooltip-id={`raffle-icons-tooltip-${index}`}>
                    <IconSvg icon={icon} className={className} />
                  </div>

                  <Tooltip
                    id={`raffle-icons-tooltip-${index}`}
                    content={label}
                    place="top"
                    className="!py-1.5 !px-3 !text-sm !rounded !text-grayscale-700 !bg-brand-primary-light !z-50"
                  />
                </div>
              ))}
            </div>
          </div>

          <p className="mt-1 text-grayscale-200 text-xss">{raffle?.network?.networkCurrency}</p>
        </div>

        <div className="w-full mt-4 mb-20 border-b-2 border-grayscale-700"></div>

        <div className="flex items-end justify-between">
          <div>
            <div className="mb-[5px] text-xs text-grayscale-300">Floor price</div>

            <p className="font-semibold text-grayscale-100">≈ {raffle?.ticketsTotalValue}</p>
          </div>

          <div>
            <div className="mb-[5px] text-xs text-grayscale-300">Tickets price/TTV</div>

            <div className="flex items-center gap-[9px]">
              <IconSvg icon={iconName} />

              <p className="font-semibold text-grayscale-100">≈ {raffle?.ticketsTotalValue}</p>
            </div>
          </div>

          <div className="flex flex-col items-end justify-end">
            {raffle?.raffleStages[0]?.isLive ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                <IconSvg icon="liveS" />
                <span className="font-normal text-xss text-grayscale-100">Live</span>
              </div>
            ) : raffle?.raffleStages[0]?.isSoon ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                <IconSvg icon="comingSoonS" />
                <span className="font-normal text-xss text-grayscale-100">Soon</span>
              </div>
            ) : raffle?.raffleStages[0]?.isExpired ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                <IconSvg icon="expired" />
                <span className="font-normal text-xss text-grayscale-100">Expired</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {isAccordionOpen && (
        <div className="bg-grayscale-900 py-5 px-[19.5px] rounded-md border-2 border-grayscale-800">
          <div
            className="bg-grayscale-900 w-full h-[262px] rounded-md mb-[16px] relative p-3 flex justify-between items-end"
            style={{
              backgroundImage: `url(${raffle.image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <img src={raffle?.logo} className="w-[60px] h-[60px]" />

            {(() => {
              if (raffle?.raffleStages[0].isExpired) {
                // Expired project
                return (
                  <Button type="ghost" size="xs" onClick={() => handleClick(raffle, 'raffle')}>
                    Open box
                  </Button>
                );
              } else if (raffle.name !== 'Qiibee' && raffle?.raffleStages[0].isLive) {
                // Live project
                return (
                  <Button
                    type="primary"
                    size="xs"
                    onClick={() => handleClick(raffle, 'raffle')}
                  >
                    Play
                  </Button>
                );
              } else {
                // Soon project
                return (
                  <Button
                    type="secondary"
                    size="xs"
                    onClick={() => handleClick(raffle, 'raffle')}
                  >
                    Join
                  </Button>
                );
              }
            })()}
          </div>

          <div className="flex items-center justify-between space-x-2">
            <div className="flex items-center gap-3.5">
              <h5 className="text-brand-primary-light max-w-[255px] truncate">
                {raffle?.name}
              </h5>

              <div className="flex items-center gap-[5px]">
                {icons.map(({ icon, label, className }, index) => (
                  <div key={index}>
                    <div key={index} data-tooltip-id={`raffle-icons-tooltip-${index}`}>
                      <IconSvg icon={icon} className={className} />
                    </div>

                    <Tooltip
                      id={`raffle-icons-tooltip-${index}`}
                      content={label}
                      place="top"
                      className="!py-1.5 !px-3 !text-sm !rounded !text-grayscale-700 !bg-brand-primary-light !z-50"
                    />
                  </div>
                ))}
              </div>
            </div>

            <p className="mt-1 text-grayscale-200 text-xss">
              {raffle?.network?.networkCurrency}
            </p>
          </div>

          <div className="w-full my-4 border-b-2 border-grayscale-700"></div>

          <div className="mb-[26px]">
            <ProgressBar
              progress={40}
              size="Medium"
              estimatedValue={0}
              totalValue={raffle?.ticketsTotalValue}
            />
          </div>

          <div className="flex items-end justify-between">
            <div>
              <div className="mb-[5px] text-xs text-grayscale-300">Floor price</div>

              <p className="font-semibold text-grayscale-100">≈ {raffle?.ticketsTotalValue}</p>
            </div>

            <div>
              <div className="mb-[5px] text-xs text-grayscale-300">Tickets price/TTV</div>

              <div className="flex items-center gap-[9px]">
                <IconSvg icon={iconName} />

                <p className="font-semibold text-grayscale-100">
                  ≈ {raffle?.ticketsTotalValue}
                </p>
              </div>
            </div>

            <div className="flex flex-col items-end justify-end">
              {raffle?.raffleStages[0]?.isLive ? (
                <div className="mb-[5px] py-1 px-3 rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="liveS" />
                  <span className="font-normal text-xss text-grayscale-100">Live</span>
                </div>
              ) : raffle?.raffleStages[0]?.isSoon ? (
                <div className="mb-[5px] py-1 px-3 rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="comingSoonS" />
                  <span className="font-normal text-xss text-grayscale-100">Soon</span>
                </div>
              ) : raffle?.raffleStages[0]?.isExpired ? (
                <div className="mb-[5px] py-1 px-3 rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="expired" />
                  <span className="font-normal text-xss text-grayscale-100">Expired</span>
                </div>
              ) : null}

              <div className="font-normal text-xss text-grayscale-300">
                <Countdown stage={raffle?.raffleStages[0]} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RaffleCard;
