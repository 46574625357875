import { useEffect, useState } from 'react';
import { ListboxOptions, Listbox, ListboxButton, ListboxOption } from '@headlessui/react';
import IconSvg from './ui/IconSvg';
import { useAccount } from 'wagmi';
import { useHttp } from 'src/hooks';
import { Network } from './cards/types/types';
import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useWebWalletLogin } from '@multiversx/sdk-dapp/hooks';
import { disconnect } from '@wagmi/core';
import { config } from 'src/providers/config';
import { useNavigate } from 'react-router-dom';

type ChooseNetworkProps = {
  selectedNetwork: {
    id?: string;
    chainId?: number | null;
    networkCurrency?: string;
  } | null;
  setSelectedNetwork: React.Dispatch<
    React.SetStateAction<{
      id?: string;
      chainId?: number | null;
      networkCurrency?: string;
    } | null>
  >;
  handleLogOutMVX: () => void; // Pass slug to handle the click
};

const ChooseNetwork = ({
  selectedNetwork,
  setSelectedNetwork,
  handleLogOutMVX,
}: ChooseNetworkProps) => {
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const navigate = useNavigate();
  const [networks, setNetworks] = useState<Network[]>([]);
  const { isLoggedIn: isLoggedInMultiversX } = useGetLoginInfo();
  const { address: addressMultiversX } = useGetAccountInfo();

  const [initiateLogin] = useWebWalletLogin({
    callbackRoute: window.location.pathname,
    nativeAuth: false,
  });

  const getNetworks = () => {
    sendRequest(
      {
        url: 'networks',
        method: 'GET',
      },
      (data: any) => {
        setNetworks(data);
      },
    );
  };

  // Fetch networks when the address changes
  useEffect(() => {
    getNetworks();
  }, []);

  const handleNetworkChange = (network: Network) => {
    setSelectedNetwork(network);
    if (network.networkCurrency.toLowerCase() === 'avax') {
      // Trigger Metamask login
      const openConnectModal = window.ethereum ? () => window.ethereum.enable() : null;
      openConnectModal && openConnectModal();
    } else if (network.networkCurrency.toLowerCase() === 'egld') {
      // Trigger MultiversX login
      initiateLogin();
    }
  };
  const handleSignOut = async () => {
    const jwtString = localStorage.getItem('jwt');
    const data = JSON.parse(jwtString);
    if (data.isMvxLoggedIn && data.isMvxLoggedIn === true) {
      localStorage.setItem(
        'jwt',
        JSON.stringify({ token: data.token, isXLoggedIn: false, isMvxLoggedIn: true }),
      );
    } else {
      localStorage.removeItem('jwt');
    }

    await disconnect(config);
    setSelectedNetwork(null);
    navigate('/');
  };

  useEffect(() => {
    if (isLoggedInMultiversX && networks && networks.length > 0) {
      const selectedNetwork = networks.find(nw => nw.networkCurrency === 'EGLD');
      setSelectedNetwork({ networkCurrency: selectedNetwork?.networkCurrency });
    }
  }, [isLoggedInMultiversX, networks]);

  return (
    <>
      <Listbox value={selectedNetwork} onChange={handleNetworkChange}>
        <ListboxButton
          className={`flex justify-between items-center space-x-2 rounded-md group lg:bg-grayscale-800 bg-grayscale-900 lg:rounded-[11px] data-[open]:rounded-b-[0px] h-fit ${
            selectedNetwork?.networkCurrency ? 'w-[114px] px-1.5 py-0.5' : 'px-4 py-2'
          } `}
        >
          <div className="text-base font-semibold text-center cursor-pointer text-grayscale-200">
            {selectedNetwork?.networkCurrency ? (
              <div className="inline-flex gap-1 items-center">
                <IconSvg icon={'egldS'} />

                <span className="text-xs text-grayscale-200 leading-[16px]">
                  {selectedNetwork?.networkCurrency}
                </span>
              </div>
            ) : (
              'Connect wallet'
            )}
          </div>

          <IconSvg
            icon="arrowDownS"
            className="text-grayscale-200 group-data-[open]:rotate-180 transition-all duration-300"
          />
        </ListboxButton>

        <ListboxOptions
          anchor={{ to: 'bottom', gap: -3 }}
          className={`flex overflow-hidden flex-col cursor-pointer z-[60] group w-[var(--button-width)] lg:bg-grayscale-800 bg-grayscale-900 rounded-[11px] data-[open]:rounded-t-[0px]`}
        >
          {networks
            .filter(network => network.networkCurrency.toLowerCase() === 'egld')
            .map(network => (
              <ListboxOption
                key={network.id}
                value={network}
                className={`flex gap-1 items-center ${
                  selectedNetwork?.networkCurrency === network.networkCurrency
                    ? ' px-1.5 py-1'
                    : 'px-4 py-2'
                }`}
              >
                <IconSvg icon={'egldS'} />

                <span className="text-xs text-grayscale-400">{network.networkCurrency}</span>
              </ListboxOption>
            ))}
        </ListboxOptions>
      </Listbox>

      {isLoggedInMultiversX && addressMultiversX && (
        <Listbox value={null}>
          <ListboxButton className="group px-4 flex justify-between items-center space-x-2 py-2 rounded-[11px] lg:bg-grayscale-800 bg-grayscale-900 data-[open]:rounded-b-[0px]">
            <div className="font-semibold text-center cursor-pointer text-grayscale-200">
              {addressMultiversX?.slice(0, 6)}...{addressMultiversX?.slice(-4)}
            </div>
          </ListboxButton>

          <ListboxOptions
            anchor={{ to: 'bottom', gap: -3 }}
            className={`flex z-10 flex-col cursor-pointer group w-[var(--button-width)] bg-grayscale-800 rounded-[11px] data-[open]:rounded-t-[0px]`}
          >
            <ListboxOption
              className="px-4 py-2 cursor-pointer"
              onClick={() => {
                handleLogOutMVX(); // Logout MultiversX
              }}
            >
              <span className="text-xs text-grayscale-200">Logout MultiversX</span>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      )}
    </>
  );
};

export default ChooseNetwork;
