import { getDefaultWallets, getDefaultConfig } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { sepolia, baseSepolia, mainnet, avalanche, avalancheFuji, base } from 'viem/chains';
import { trustWallet, ledgerWallet } from '@rainbow-me/rainbowkit/wallets';

const { wallets } = getDefaultWallets();
const projectId = process.env.REACT_APP_PROJECT_ID as string;

export const config = getDefaultConfig({
  appName: 'Looty ',
  projectId: projectId,
  syncConnectedChain: true,
  wallets: [
    ...wallets,
    {
      groupName: 'Other',
      wallets: [trustWallet, ledgerWallet],
    },
  ],
  chains: [
    sepolia, baseSepolia, avalanche, avalancheFuji, mainnet, base
  ],
  ssr: false,
});
