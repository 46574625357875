import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import NumberInputWithMultiplier from 'src/components/common/input/NumberInputWithMultiplier';
import IconSvg from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

import Button from 'src/components/button/Button';

interface Props {
  setShowMintSuccessModal: any;
  networkCurrency?: string;
}

const EarnStagesRaffle = ({ setShowMintSuccessModal, networkCurrency }: Props) => {
  const navigate = useNavigate();

  const { isConnected } = useAccount();
  const [transactionMessage, setTransactionMessage] = useState('');
  const [isMintingInProgress, setIsMintingInProgress] = useState(false);

  const { data: hash } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirming) {
      setTransactionMessage('Transaction in progress...'); // Update progress message
    }

    if (isConfirmed && receipt) {
      // Parse the tokenId from logs[0].topics[3]
      const tokenIdHex = receipt.logs[0].topics[3];
      // const tokenId = ethers.BigNumber.from(tokenIdHex).toString();
      // setTokenId(tokenId); // Set the tokenId state with the parsed value
      // setIsMintingInProgress(false); // Hide progress modal
      setTransactionMessage('Congratulations! Mint Successful.');
      // setIsMintSuccessModalOpen(true); // Show success modal
      // toast.success("Congratulations! Mint Successful.", { autoClose: 6000 });

      setShowMintSuccessModal(true);
    }
  }, [isConfirming, isConfirmed, receipt]);

  const handleMintKeys = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }
    // if (chain?.id !== targetChainId) {
    //   await switchChain?.({ chainId: targetChainId });
    // }
    // if (balanceOf && Number(balanceOf) >= 1) {
    //   toast.info("You have already minted.");
    //   return;
    // }

    // if (
    //   etherBalance?.data?.value &&
    //   parseFloat(formatEther(etherBalance.data.value)) < Number(nftPrice)
    // ) {
    //   toast.error("Insufficient balance to Mint NFT");
    //   return;
    // }

    // try {
    //   setIsMintingInProgress(true);
    //   setTransactionMessage("Transaction in progress..."); // Set the message for progress state
    //   setMintError(undefined);
    //   const priceInEther = utils.parseEther(nftPrice.toString());
    // const keysOfEachType = [3, 5, 2];
    // const messageHash = ethers.utils.solidityKeccak256(["address", "uint256", "uint256[]"], ["0x4d040f3F842d3C3cac908708E46F139BbBbFcF61", keysOfEachType.length, keysOfEachType]);

    // const signature = "";
    // const signature = await wallet.signMessage(ethers.utils.arrayify(messageHash));

    // const priceInEther = ethers.utils.parseEther("0.1"); // Define priceInEther

    // const response = await writeContractAsync({
    //     ...lootyContractConfig,
    //     functionName: "mint",
    //     args: [signature, keysOfEachType],
    //     value: BigInt(priceInEther.toString()),
    // });
    // } catch (error: any) {
    //   console.error("Mint NFT error:", error);
    //   setMintError(error?.message || "Minting failed.");
    //   setTransactionMessage("Minting failed. Please try again.");
    //   toast.error(error?.message || "Minting failed.");
    //   setIsMintingInProgress(false);
    // }
  };

  type IconName =
    | 'avaxM'
    | 'avaxS'
    | 'ethM'
    | 'ethS'
    | 'bnbM'
    | 'bnbS'
    | 'solM'
    | 'solS'
    | 'suiM'
    | 'suiS';

  const getIconName = (networkCurrency: string, size: 'M' | 'S'): IconName => {
    switch (networkCurrency) {
      case 'AVAX':
        return `avax${size}`;
      case 'ETH':
        return `eth${size}`;
      case 'BNB':
        return `bnb${size}`;
      case 'SOL':
        return `sol${size}`;
      case 'SUI':
        return `sui${size}`;
      default:
        return `eth${size}`;
    }
  };

  const iconNameM = getIconName(networkCurrency || '', 'M');

  return (
    <>
      <div className="w-[46.46%] bg-grayscale-800 p-[30px] pb-[40px] rounded-md h-fit">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-[24px] text-brand-primary-light mb-[25px]">Play</div>

          <div className="flex flex-col items-end">
            <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success mb-3 flex gap-1.5 items-center border w-fit">
              <IconSvg icon="liveS" />

              <span className="text-sm text-grayscale-100">live</span>
            </div>

            <div className="text-sm text-grayscale-300">Ticket sales ends in: 00d 00h 00m 00s</div>
          </div>
        </div>

        <div className="border-b-2 border-grayscale-700 my-[25px]"></div>

        <div className="mb-[25px]">
          <ProgressBar progress={30} size="XL" totalValue="1000" estimatedValue={0} />
        </div>

        <div className="flex items-end justify-between mb-[25px]">
          <div className="w-[287px] space-y-[13px]">
            <div className="text-lg text-grayscale-300">Ticket price</div>

            <div className="flex gap-2.5 items-center">
              <IconSvg icon={iconNameM} />

              <div className="text-[24px] text-[#FAFAFA] font-semibold">4.5</div>

              <div className="self-end text-sm text-grayscale-300">
                + 0.5 {networkCurrency} mint fee
              </div>
            </div>
          </div>

          <div className="space-y-[13px] w-fit flex flex-col items-center">
            <div className="text-lg text-grayscale-300">TTV</div>

            <div className="flex gap-2.5 items-center">
              <IconSvg icon="ethM" />

              <div className="text-[24px] text-[#FAFAFA] font-semibold">0.0005</div>
            </div>
          </div>

          <NumberInputWithMultiplier />
        </div>

        <div className="flex justify-center">
          <Button onClick={handleMintKeys} type="primary" size="m">
            Buy
          </Button>
        </div>
      </div>
    </>
  );
};

export default EarnStagesRaffle;
