interface RecentActivityCardProps {
  projectName: string;
  estimatedValue: string;
  rewardOpened: string;
  image: string;
  activity: string;
}

function RecentActivityCard({
  projectName,
  estimatedValue,
  rewardOpened,
  image,
  activity,
}: RecentActivityCardProps) {
  return (
    <div className="p-5 rounded-md bg-grayscale-800">
      <div className="bg-grayscale-900 h-[271px] rounded-md mb-[17px] relative">
        <img src={image} alt={projectName} className="object-cover w-full h-full rounded-md" />

        {/* <div className="absolute top-2.5 left-2.5 bg-gray-600 py-1 px-2.5 rounded text-xs text-grayscale-200">
          {projectName}
        </div> */}
      </div>

      <div className="flex items-center gap-2">
        <span>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 5V3.5H15.485V5H8ZM20 5H21.5V3.50746H20V5ZM8.75751 13.9773H7.25751V12.4848H8.75751V13.9773ZM18.4925 16.9773H16.9925V15.4848H18.4925V16.9773ZM6.52252 18.4773H16.9925V16.9848H6.52252V18.4773ZM5 21.4553H18.4925V19.9628H5V21.4553ZM5.00751 16.9773H6.50751V15.4848H5.00751V16.9773ZM6.50751 7.99268H5.00751V6.50014H6.50751V7.99268ZM16.9925 7.99268H18.4925V6.50014H16.9925V7.99268ZM19.9925 15.4846H18.4925V7.99963H19.9925V15.4846ZM3.5 15.4846H5V7.99963H3.5V15.4846ZM14.015 12.4927H12.515V10.9927H11.015V9.49268H12.515V8.00018H14.015V9.49268H15.5075V10.9927H14.015V12.4927ZM6.5 6.5H8V5.00746H6.5V6.5ZM16.9925 6.5H15.4925V5.00746H16.9925V6.5Z"
              fill="#FF3FF7"
            />
          </svg>
        </span>

        <h5 className="text-brand-primary-light max-w-[250px] truncate">{activity}</h5>
      </div>

      {/* <div className="w-full border-b-2 border-grayscale-700 my-[17px]"></div>

      <div className="flex items-center">
        <div>
          <div className="mb-[5px] text-xs text-grayscale-300">Estimated value</div>

          <div className="font-semibold text-grayscale-100">≈ {estimatedValue}</div>
        </div>

        <div className="flex items-center space-x-3 text-end">
          <div className="text-xs text-grayscale-300">Reward opened</div>

          <div className="font-semibold text-grayscale-100">{rewardOpened}</div>
        </div>
      </div> */}
    </div>
  );
}

export default RecentActivityCard;
