import IconSvg, { Icons } from '../../../components/common/ui/IconSvg';
import ProgressBar from '../../../components/common/ui/ProgressBar';
import { Collection } from '../../../components/common/cards/types/types';
import Countdown from '../../../components/common/Countdown';
import Button from 'src/components/button/Button';

interface Props {
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
}

const getIconName = (networkCurrency: string): Icons => {
  switch (networkCurrency) {
    case 'AVAX':
      return 'avaxS';
    case 'ETH':
      return 'ethS';
    case 'BNB':
      return 'bnbS';
    case 'SOL':
      return 'solS';
    case 'SUI':
      return 'suiS';
    default:
      return 'ethS';
  }
};

function OpenBoxCard({ collection, handleClick }: Props) {
  const iconName = getIconName(collection.network.networkCurrency);

  return (
    <div className="bg-grayscale-800 py-5 px-[19.5px] rounded-md w-[412px]">
      <div
        className="bg-grayscale-900 w-full h-[262px] rounded-md mb-[16px] relative p-3 flex flex-col justify-between"
        style={{
          backgroundImage: `url(${collection.image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="py-1 px-2.5 rounded bg-brand-primary-light w-fit leading-tight">
          <span className="text-sm text-grayscale-900">airdrop</span>
        </div>

        <div className="flex items-end justify-between">
          <img src={collection?.logo} className="w-[60px] h-[60px]" />

          {(() => {
            if (collection?.stages[0].isExpired) {
              // Expired project
              return (
                <Button
                  type="ghost"
                  size="xs"
                  onClick={() => handleClick(collection, 'checkReward')}
                >
                  Check rewards
                </Button>
              );
            } else if (collection?.stages[0].isLive) {
              // Live project
              return (
                <Button type="primary" size="xs" onClick={() => handleClick(collection, 'openbox')}>
                  Open box
                </Button>
              );
            } else {
              // Soon project
              return (
                <Button type="secondary" size="xs" onClick={() => handleClick(collection, 'mint')}>
                  Mint
                </Button>
              );
            }
          })()}
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <h5 className="text-brand-primary-light">{collection?.name}</h5>

        <p className="mt-1 text-grayscale-200 text-xss">{collection?.network?.networkCurrency}</p>
      </div>

      <div className="w-full my-4 border-b-2 border-grayscale-700"></div>

      <div className="mb-[26px]">
        <ProgressBar
          progress={40}
          size="Medium"
          estimatedValue={0}
          totalValue={collection?.rewardsTotalValue}
        />
      </div>

      <div className="flex items-center justify-between">
        <div>
          <div className="mb-[5px] text-xs text-grayscale-300">Rewards pool</div>

          <div className="flex items-center space-x-[9px]">
            <p className="font-semibold text-grayscale-100">≈ {collection?.rewardsTotalValue}</p>

            <IconSvg icon={iconName} />
          </div>
        </div>

        <div className="flex flex-col items-end justify-end">
          {collection?.stages[0]?.isLive ? (
            <div className="mb-[5px] py-1 px-3 rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
              <IconSvg icon="liveS" />
              <span className="font-normal text-xss text-grayscale-100">Live</span>
            </div>
          ) : collection?.stages[0]?.isSoon ? (
            <div className="mb-[5px] py-1 px-3 rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
              <IconSvg icon="comingSoonS" />
              <span className="font-normal text-xss text-grayscale-100">Soon</span>
            </div>
          ) : collection?.stages[0]?.isExpired ? (
            <div className="mb-[5px] py-1 px-3 rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
              <IconSvg icon="expired" />
              <span className="font-normal text-xss text-grayscale-100">Expired</span>
            </div>
          ) : null}

          <div className="font-normal text-xss text-grayscale-300">
            <Countdown stage={collection?.stages[0]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenBoxCard;
