import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RewardCard from 'src/components/common/cards/RewardCard';
import { Collection } from 'src/components/common/cards/types/types';
import ClaimedRewardModal from 'src/components/common/modal/ClaimedRewardModal';
import IconSvg from 'src/components/common/ui/IconSvg';
import { rewardCardData } from 'src/dummydata';
import { useHttp } from 'src/hooks';
import PoolSelect from 'src/components/common/PoolSelect';
import EarnStagesRaffle from './components/EarnStagesRaffle';

function RaffleDetail() {
  const [showMintSuccessModal, setShowMintSuccessModal] = useState(false);
  const [collection, setCollection] = useState<Collection>();
  const location = useLocation();
  const { sendRequest } = useHttp();
  const { id } = location.state || {};
  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections/${id}`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, [id]);

  return (
    <>
      {/* <MIntSuccessModal
        isOpen={showMintSuccessModal}
        onClose={setShowMintSuccessModal}
        mintedKeys={5}
      /> */}

      {/* <RewardInfoModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} /> */}
      <ClaimedRewardModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} />

      <section className="mb-[50px]">
        <div
          className="bg-gray-800 rounded-md h-[418px] w-full"
          style={{
            backgroundImage: `url(/assets/images/raffle-banner.png)`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>
      </section>

      <section className="mb-[72px]">
        <div className="flex justify-between">
          <div className="w-[44.76%]">
            <div className="flex gap-[19px] items-center mb-6">
              <img
                src={collection?.logo}
                className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"
              />

              <div className="space-y-[17px]">
                <h3 className="uppercase text-brand-primary-light">{collection?.name}</h3>

                <div className="flex gap-[9px]">
                  <IconSvg
                    icon="webM"
                    // onClick={() =>
                    //   collection?.webLink
                    //     ? window.open(
                    //         `https://twitter.com/${collection.twitter}`,
                    //         '_blank',
                    //         'noopener,noreferrer',
                    //       )
                    //     : null
                    // }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.webLink ? '' : 'opacity-50 !cursor-default'
                    }`}
                  />
                  <IconSvg
                    icon="twitterM"
                    onClick={() =>
                      collection?.twitter
                        ? window.open(
                            `https://twitter.com/${collection.twitter}`,
                            '_blank',
                            'noopener,noreferrer',
                          )
                        : null
                    }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.twitter ? '' : 'opacity-50 !cursor-default'
                    }`}
                  />

                  <IconSvg
                    icon="discordM"
                    onClick={() =>
                      collection?.discord
                        ? window.open(collection.discord, '_blank', 'noopener,noreferrer')
                        : null
                    }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.twitter ? '' : 'opacity-50 !cursor-default'
                    }`}
                  />
                  <IconSvg
                    icon="hyperspaceM"
                    // onClick={() =>
                    //   collection?.webLink
                    //     ? window.open(
                    //         `https://twitter.com/${collection.twitter}`,
                    //         '_blank',
                    //         'noopener,noreferrer',
                    //       )
                    //     : null
                    // }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.hyperspaceLink ? '' : 'opacity-50 !cursor-default'
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="space-y-6 text-gray-300 mb-9">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>

              <div>
                <div className="mb-2 text-xs text-grayscale-400">Value of rewards</div>

                <div className="text-lg font-semibold text-grayscale-100">≈ $2,118.00</div>
              </div>
            </div>

            <div>
              <div className="mb-3 text-lg text-grayscale-100">Available keys:</div>

              <div className="flex gap-[15px]">
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
              </div>
            </div>
          </div>

          <EarnStagesRaffle
            setShowMintSuccessModal={setShowMintSuccessModal}
            networkCurrency={collection?.network?.networkCurrency}
          />
        </div>
      </section>

      <section>
        <div className="mb-[36px] flex justify-between items-center">
          <h3 className="text-brand-primary-light">Rewards</h3>

          <PoolSelect />
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {rewardCardData.map((card, index) => (
            <RewardCard
              key={index}
              name={card.name}
              estimatedValue={card.estimatedValue}
              rarity={card.rarity}
              image={card.image}
              size="medium"
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default RaffleDetail;
