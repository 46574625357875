import { Collection } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg from 'src/components/common/ui/IconSvg';

interface Props {
  collection?: Collection;
}
function QiibeeCollectionBanner({ collection }: Props) {
  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between"
      style={{
        backgroundImage: `url(${collection?.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="flex justify-between">
        <div>
          <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success mb-3 flex gap-1.5 items-center border w-fit">
            <div>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="2" y="2.5" width="8" height="8" fill="#14F079" />
              </svg>
            </div>

            <span className="text-sm text-grayscale-100">Mint live</span>
          </div>

          <div className="text-sm font-semibold text-grayscale-200">
            <Countdown stage={collection?.stages[0]} />
          </div>
        </div>

        <div className="flex gap-6">
          <div>
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of rewards</div>

            <div className="text-[24px] font-semibold text-grayscale-100">≈ $2,118.00</div>
          </div>

          <div className="border-l-2 h-[59px] border-grayscale-400"></div>

          <div>
            <div className="mb-2 text-sm text-grayscale-200 text-end">Minted keys</div>

            <div className="text-[24px] font-semibold text-grayscale-100">0,000/0,000</div>
          </div>

          <div className="border-l-2 h-[59px] border-grayscale-400"></div>

          <div>
            <div className="mb-2 text-sm text-grayscale-200 text-end">Points to earn</div>

            <div className="flex justify-end gap-2.5 items-center">
              <IconSvg icon="starM" />

              <div className="text-[24px] font-semibold text-grayscale-100">220</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-[19px] items-center">
        <img src={collection?.logo} className="w-[96px] h-[96px] rounded-md" />

        <div className="space-y-[17px]">
          <div className="flex gap-[13px] items-end">
            <h3 className="uppercase text-brand-primary-light">{collection?.name}</h3>

            <div className="text-sm text-grayscale-200">{collection?.network?.networkCurrency}</div>
          </div>

          <div className="flex gap-[9px]">
            <IconSvg
              icon="webM"
              // onClick={() =>
              //   collection?.webLink
              //     ? window.open(
              //         `https://twitter.com/${collection.twitter}`,
              //         '_blank',
              //         'noopener,noreferrer',
              //       )
              //     : null
              // }
              className={`cursor-pointer text-grayscale-400 ${
                collection?.webLink ? '' : 'opacity-50 !cursor-default'
              }`}
            />

            <IconSvg
              icon="twitterM"
              onClick={() =>
                collection?.twitter
                  ? window.open(
                      `https://twitter.com/${collection.twitter}`,
                      '_blank',
                      'noopener,noreferrer',
                    )
                  : null
              }
              className={`cursor-pointer text-grayscale-400 ${
                collection?.twitter ? '' : 'opacity-50 !cursor-default'
              }`}
            />

            <IconSvg
              icon="discordM"
              onClick={() =>
                collection?.discord
                  ? window.open(collection.discord, '_blank', 'noopener,noreferrer')
                  : null
              }
              className={`cursor-pointer text-grayscale-400 ${
                collection?.twitter ? '' : 'opacity-50 !cursor-default'
              }`}
            />

            <IconSvg
              icon="hyperspaceM"
              // onClick={() =>
              //   collection?.webLink
              //     ? window.open(
              //         `https://twitter.com/${collection.twitter}`,
              //         '_blank',
              //         'noopener,noreferrer',
              //       )
              //     : null
              // }
              className={`cursor-pointer text-grayscale-400 ${
                collection?.hyperspaceLink ? '' : 'opacity-50 !cursor-default'
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QiibeeCollectionBanner;
