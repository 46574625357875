import React, { useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { useHttp } from 'src/hooks';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import IconSvg from 'src/components/common/ui/IconSvg';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import AvaxCollectionBanner from './components/AvaxCollectionBanner';
import { Collection } from 'src/components/common/cards/types/types';
import ClaimNftLotteryModal from 'src/components/common/modal/ClaimNftLotteryModal';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';

import LootyNftAbi from '../../../contracts/NFTLottery.json';
import LootyKeyABI from '../../../contracts/LootyKeyABI.json';
import PoolSelect from 'src/components/common/PoolSelect.tsx';
import RewardCardQiibee from 'src/components/common/cards/RewardCardQiibee';

const nftLotteryContractConfig = {
  address: process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS as `0x${string}`,
  abi: LootyNftAbi,
};

const lootyKeyContractConfig = {
  address: process.env.REACT_APP_LOOTY_KEY_CONTRACT_ADDRESS as `0x${string}`,
  abi: LootyKeyABI,
};

const KEY_NAMES = {
  0: 'Bronze Key',
  1: 'Silver Key',
  2: 'Gold Key',
  3: 'Platinum Key',
  4: 'Diamond Key',
} as const;

const data = Array.from({ length: 300 }, (_, index) => ({
  cover: `https://passport.opus.ai/v1/${index + 1}.png`,
  name: `NFT #${index + 1}`,
  credits: (index + 1) * 10, // or set this to a specific pattern or value as needed
}));

interface RewardEvent {
  user: string;
  requestId: string;
  nftId?: string;
}

function AvaxCollectionDetail() {
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const { collectionName } = useParams();

  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  const [userLotteryData, setUserLotteryData] = useState<any>(null);
  const [isClaimingNft, setIsClaimingNft] = useState(false);

  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean | null>(null);
  const [isPauseNftContract, setIsPauseNftContract] = useState<boolean | null>(null);
  const [isClaimNftModal, setIsClaimNftModal] = useState(false);
  const [isRerollingNft, setIsRerollingNft] = useState(false);
  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);

  const [transactionMessage, setTransactionMessage] = useState('');

  const [loadingOpenBoxBtn, setLoadingOpenBoxBtn] = useState(false);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedNft, setSelectedNft] =
    useState<{ cover: string; name: string; credits: number } | null>(null);

  const [formattedEvents, setFormattedEvents] = useState<RewardEvent[]>([]);

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
    isError: isError,
  } = useWriteContract();
  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  const {
    data: hashClaimNft,
    writeContract: claimNftContractWrite,
    isPending: isOpeningNftLottery,
    isError: isErrorClaimNft
  } = useWriteContract();

  const {
    isLoading: isConfirmingClaimNft,
    isSuccess: isConfirmedClaimNft,
    data: receiptClaimNft,
  } = useWaitForTransactionReceipt({
    hash: hashClaimNft,
  });


  const {
    data: hashReroll,
    writeContract: RerollContractWrite,
    isPending: isPendingReroll,
    isError: isErrorReroll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingReroll,
    isSuccess: isConfirmedReroll,
    data: receiptReroll,
  } = useWaitForTransactionReceipt({
    hash: hashReroll,
  });

  const {
    data: hashSetApprovedForAll,
    writeContract: writeApprovelForAll,
    isPending: isPendingApprovalForAll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingApprovalForAll,
    isSuccess: isConfirmedApprovalForAll,
    data: receiptApprovalForAll,
  } = useWaitForTransactionReceipt({
    hash: hashSetApprovedForAll,
  });

  useEffect(() => {
    if (isConfirmedApprovalForAll) {
      openLootboxContractWrite({
        ...nftLotteryContractConfig,
        functionName: 'startLottery',
        args: [selectedKey],
      });
    }
  }, [isConfirmedApprovalForAll]);


  useEffect(() => {
    if (isConfirmedOpenBox && receiptOpenBox) {
      setLoadingOpenBoxBtn(false); // Ensure loading state is reset
      stopSpin(0); // Ensure spinning stops
      setTransactionMessage('Openbox Successful.');
      getUserData(); // Update user data after the transaction
    }
  
    if (isError) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      stopSpin(0);
    }
    
    if(isErrorReroll) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsRerollingNft(false)
    }

    if(isErrorClaimNft) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsClaimingNft(false)
    }

  }, [isConfirmingOpenBox, isConfirmedOpenBox, receiptOpenBox, isError, isErrorReroll, isErrorClaimNft]);
  

  useEffect(() => {
    if (address) {
      readRewardBalance(address);
    }
  }, [address]);

  const getRarity = (reward: number) => {
    if (reward === 20 || reward === 30 || reward === 45) {
      return 'Common';
    } else if (reward === 60 || reward === 80) {
      return 'Uncommon';
    } else if (reward === 100 || reward === 150) {
      return 'Rare';
    }
    return 'Common'; // Default to Common if no match
  };

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections/${collectionName}`,
        method: 'GET',
      },
      (data: any) => {
        setCollectionDetail(data);
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, [collectionName]);

  const openRedeemedModal = () => {
    setIsClaimNftModal(false);
    setIsSuccessRedeemedModalOpen(true);
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};

    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 5; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  useEffect(() => {
    if (isConnected) {
      fetchBlockchainKeyBalances();
      getUserData();
    }
  }, [address, isConnected]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        lootyKeyContractConfig.address,
        lootyKeyContractConfig.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      const isApprovedForAll = await contract.isApprovedForAll(
        walletAddress,
        process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS,
      );
      setIsApprovedForAll(isApprovedForAll);
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const getUserData = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        nftLotteryContractConfig.address,
        nftLotteryContractConfig.abi,
        provider,
      );
      const userData = await contract.userLotteryData(address);
      const isPause = await contract.paused();
      console.log('userData', userData);
      setIsPauseNftContract(isPause);
      setUserLotteryData(userData);
    } catch (error) {
      console.error('Error reading balance:', error);
    }
  };

  const readRewardBalance = async (walletAddress: string) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const lootyNftContract = new ethers.Contract(
        nftLotteryContractConfig.address,
        nftLotteryContractConfig.abi,
        provider,
      );

      const filter = lootyNftContract.filters.Fulfilled(walletAddress);
      const events = await lootyNftContract.queryFilter(filter);

      const formattedEvents = events.map(event => ({
        user: event?.args?.user,
        requestId: event?.args?.requestId.toString(),
        nftId: event?.args?.nftId.toString(),
      }));

      setFormattedEvents(formattedEvents);
    } catch (error) {
      console.error('Error reading rewards:', error);
      return 0;
    }
  };

  const openLootbox = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }

    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    if (isPauseNftContract) {
      toast.error('Contract is Paused, contact admin');
      return;
    }

    try {
      console.log(`startLottery.... ${selectedKey}...`);
      startSpin(); // Start the carousel spin

      setLoadingOpenBoxBtn(true);
      if (isApprovedForAll) {
        await openLootboxContractWrite({
          ...nftLotteryContractConfig,
          functionName: 'startLottery',
          args: [selectedKey],
        });
      } else {
        await writeApprovelForAll({
          ...lootyKeyContractConfig,
          functionName: 'setApprovalForAll',
          args: [process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS, true],
        });
      }
    } catch (error) {
      setLoadingOpenBoxBtn(false);
      stopSpin(0);
      console.log('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      nftLotteryContractConfig.address,
      nftLotteryContractConfig.abi,
      provider,
    );

    const onClaimed = (
      user: string,
      requestId: ethers.BigNumber,
      nftContract: string,
      nftId: ethers.BigNumber,
    ) => {
      console.log('Claimed event detected');
      if (user.toLowerCase() === address?.toLowerCase()) {
        getUserData();
        fetchBlockchainKeyBalances();
        setIsClaimingNft(false);
        toast.success('NFT Claimed Successfully');
      }
    };

    contract.on('Claimed', onClaimed);

    return () => {
      contract.off('Claimed', onClaimed);
    };
  }, [address, nftLotteryContractConfig.address]);

  const claimNft = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before claiming the NFT.');
      return;
    }

    if (Number(userLotteryData?.state) === 2) {
      setIsClaimNftModal(false);
    }

    try {
      setIsClaimingNft(true); // Start loader
      await claimNftContractWrite({
        ...nftLotteryContractConfig,
        functionName: 'claimNFT',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsClaimingNft(false);
    }
  };

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      nftLotteryContractConfig.address,
      nftLotteryContractConfig.abi,
      provider,
    );

    const onRolled = (
      user: string,
      requestId: ethers.BigNumber,
      nftContract: string,
      nftId: ethers.BigNumber,
    ) => {
      console.log('ReRoll event detected');
      if (user.toLowerCase() === address?.toLowerCase()) {
        getUserData();
        fetchBlockchainKeyBalances();
        setIsRerollingNft(false);
        // toast.success('Re-roll Successfully');
      }
    };

    contract.on('Rolled', onRolled);

    return () => {
      contract.off('Rolled', onRolled);
    };
  }, [address, nftLotteryContractConfig.address]);

  const rerollNft = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before re-rolling the NFT.');
      return;
    }

    try {
      setIsRerollingNft(true); // Start loader
      await RerollContractWrite({
        ...nftLotteryContractConfig,
        functionName: 'reroll',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsRerollingNft(false);
    }
  };

  const startSpin = () => {
    console.log('startSpin().......');
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    console.log('startSpin() cross');
    setSpinning(true);
    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  const stopSpin = (targetCredits: number) => {
    console.log('stopSpin()......', targetCredits);
    console.log('spinning', spinning);
    // if (!spinning) return;
    console.log('spinIntervalRef.current', spinIntervalRef.current);
    if (spinIntervalRef.current) {
      console.log('tututtartara');
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }

    console.log('setSpinning(false)');
    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);
    console.log('targetSlideIndex', targetSlideIndex);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      console.log('aaayooooo');
      const steps = (targetSlideIndex + data.length - activeSlideIndex) % data.length;
      console.log('stpes', steps);
      stackedCarouselRef.current.swipeTo(steps); // Swipe to the selected NFT slide
    }
  };

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      nftLotteryContractConfig.address,
      nftLotteryContractConfig.abi,
      provider,
    );

    const onFulfilled = (
      user: string,
      requestId: ethers.BigNumber,
      nftContract: string,
      nftId: ethers.BigNumber,
    ) => {
      console.log('onFulfilled................');
      console.log('user', user);
      console.log('address', address);
      if (user.toLowerCase() === address?.toLowerCase()) {
        const randomIndex = Math.floor(Math.random() * data.length);
        console.log('randomIndex', randomIndex);
        const selectedItem = data[randomIndex];
        console.log('data[randomIndex]', data[randomIndex]);
        setSelectedNft(selectedItem);
        getUserData();
        fetchBlockchainKeyBalances();

        console.log('selectedItem.credits', selectedItem.credits);
        stopSpin(selectedItem.credits);
        setTransactionMessage('NFT Claimed!');
        setLoadingOpenBoxBtn(false);
        setIsClaimNftModal(true);
        setIsRerollingNft(false);
      }
    };

    contract.on('Fulfilled', onFulfilled);

    return () => {
      contract.off('Fulfilled', onFulfilled);
    };
  }, [address, nftLotteryContractConfig.address]);

  return (
    <>
      <ClaimNftLotteryModal
        openRedeemedModal={openRedeemedModal}
        isOpen={
          isClaimNftModal ||
          Number(userLotteryData?.state) === 2 ||
          Number(userLotteryData?.state) === 1
        }
        onClose={() => setIsClaimNftModal(false)}
        rerollsLeft={userLotteryData?.rerollsLeft}
        nftId={userLotteryData?.candidateNFTId}
        claimNft={claimNft}
        isClaimingNft={isClaimingNft}
        rerollNft={rerollNft}
        isRerollingNft={isRerollingNft}
        selectedNft={selectedNft}
      />

      <section className="mb-[40px]">
        <AvaxCollectionBanner collection={collectionDetail} />
      </section>

      <>
        <section className="mb-[72px]">
          <div className="mb-10">
            <ResponsiveContainer
              carouselRef={stackedCarouselRef}
              render={(parentWidth, carouselRef) => {
                const currentVisibleSlide = 7;

                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={Card}
                    slideWidth={268}
                    carouselWidth={parentWidth}
                    data={data}
                    currentVisibleSlide={currentVisibleSlide}
                    maxVisibleSlide={7}
                    disableSwipe
                    onActiveSlideChange={activeSlide => {
                      setActiveSlideIndex(activeSlide);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="space-y-[32px] flex flex-col items-center justify-center">
            <div className="flex gap-[18px] items-center justify-center">
              {[0, 1, 2, 3, 4].map(key => (
                <div
                  key={key}
                  onClick={() => blockchainKeyBalances[key] > 0 && handleCardClick(key)}
                  className={`relative w-[268px] rounded-md p-[15px] pb-[17px] cursor-pointer ${
                    selectedKey === key && blockchainKeyBalances[key] > 0
                      ? 'border-2 border-purple-500'
                      : ''
                  } bg-grayscale-800`}
                >
                  <img
                    src={`/assets/images/keys/${key}.png`}
                    className={`w-[238px] h-[238px] rounded mb-[18px] ${
                      blockchainKeyBalances[key] > 0 ? '' : 'opacity-50' // Apply opacity only to the image if disabled
                    }`}
                    alt={`Key ${key}`}
                  />

                  {/* Show Buy Key button only on hover if the key is not owned */}
                  {(blockchainKeyBalances[key] === 0 || !isConnected) && (
                    <div
                      className="absolute inset-0 flex items-center justify-center transition-opacity bg-black bg-opacity-50 opacity-0 hover:opacity-100"
                      onClick={e => {
                        e.stopPropagation(); // Prevents card click event
                        navigate('/buy-key');
                      }}
                    >
                      <Button size="sm" type="secondary">
                        Buy Keys
                      </Button>
                    </div>
                  )}

                  <div className="space-y-[9px] mt-2">
                    <h5 className="uppercase text-[#FAFAFA]">
                      {`${KEY_NAMES[key as keyof typeof KEY_NAMES]}`}
                    </h5>
                    <div className="text-xs text-gray-300 space-x-[7px]">
                      <span>Ready to use:</span>
                      <span className="font-semibold pl-[7px]">
                        {isConnected ? blockchainKeyBalances[key] ?? 0 : 'Connect Wallet'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-[#D7CCE0] text-center">
              <p>Open box using your keys and get rewards from your favorite project.</p>

              <p className="mb-4">
                Your NFT will be burned once you use your first key. With each key, you get one
                reward, but some keys allow <br /> re-roll for a chance at another reward.
              </p>

              <p className="mb-4 font-bold">
                Bronze keys offer no re-rolls, but Silver keys allow 1 re-roll, Gold keys 2,
                Platinum keys 3, and Diamond <br /> keys 4. After each spin, you can either keep
                your reward or try again until your re-roll limit is reached.
              </p>

              <p>Remember, only one reward can be claimed per key!</p>
            </div>

            <div className="mx-auto w-fit">
              {isConnected ? (
                loadingOpenBoxBtn ? (
                  <SyncLoader color="#B73FFF" size={10} />
                ) : (
                  <Button size="m" type="primary" onClick={() => openLootbox()}>
                    Open box
                  </Button>
                )
              ) : (
                <ConnectButton.Custom>
                  {({ openConnectModal }) => (
                    <Button size="m" type="primary" onClick={openConnectModal}>
                      Connect Wallet
                    </Button>
                  )}
                </ConnectButton.Custom>
              )}
            </div>
          </div>
        </section>

        <section>
          <div className="mb-[36px] flex justify-between items-center">
            <h3 className="text-brand-primary-light">Rewards</h3>

            <PoolSelect />
          </div>

          <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
            {formattedEvents.map((event, index) => (
              <RewardCardQiibee
                key={index}
                name={`${event?.nftId} NFT`} // Pass reward value
                estimatedValue={event?.nftId} // Use reward as estimated value
                rarity={getRarity(Number(event?.nftId))} // Dynamically calculate rarity
                image={
                  event?.nftId
                    ? `https://passport.opus.ai/v1/${event?.nftId}.png`
                    : '/assets/images/credit.png'
                }
                size="medium"
              />
            ))}
          </div>
        </section>
      </>
    </>
  );
}

export default AvaxCollectionDetail;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, name } = data[dataIndex];

  return (
    <div className="w-[268px] h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="w-[238px] h-[238px] bg-grayscale-700 rounded relative"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          <IconSvg icon="rareS" className="text-rarity-rare-light" />
          <div>{name} </div>
        </div>
      </div>
    </div>
  );
});
