import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collection } from 'src/components/common/cards/types/types';
import ArenaClaimedRewardModal from 'src/components/common/modal/ArenaClaimedRewardModal';
import IconSvg from 'src/components/common/ui/IconSvg';
import { rewardArenaCardData } from 'src/dummydata';
import { useHttp } from 'src/hooks';
import ArenaMintSuccessModal from 'src/components/common/modal/ArenaMintSuccessModal';

import whitelist_dev from '../../whitelist_dev.json';
import whitelist_prod from '../../whitelist_prod.json';
import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import { useAccount, useSwitchChain } from 'wagmi';
import { ethers } from 'ethers';
import { getArenaBalanceOf } from 'src/utilities/looty';
import { formatEther } from 'viem';
import RewardArenaCard from 'src/components/common/cards/RewardArenaCard';
import ArenaDistributions from './ArenaDistributions';
import PoolSelect from 'src/components/common/PoolSelect';

const environment = process.env.REACT_APP_ENVIRONMENT;

const whitelist = environment === 'production' ? whitelist_prod : whitelist_dev;
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string
    | {
        signature: string;
        baseTokenAmount: number;
        tokenAmount: string[];
        hash?: string; // Make `hash` optional
      };
}

interface WhitelistP1 {
  [address: string]:
    | string
    | {
        signature: string;
        keysOfEachType: number[];
      };
}

const whitelistTyped: Whitelist = whitelist;
const whitelistTypedP1: WhitelistP1 = whitelistP1;

function ArenaMintProject() {
  const { address, isConnected } = useAccount();
  const [balanceOfArenaToken, setBalanceOfArenaToken] = useState<string | undefined>(undefined);
  const [showMintSuccessModal, setShowMintSuccessModal] = useState(false);
  const [isShowClaimMintSuccessModal, setShowClaimMintSuccessModal] = useState(false);

  const [collection, setCollection] = useState<Collection>();
  const { sendRequest } = useHttp();

  const { switchChain } = useSwitchChain();
  const { id, stageId } = useParams();
  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections/${id}`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
        console.log('data?.network.chainId', data?.network.chainId);
        switchChain({ chainId: data?.network.chainId });
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, [id]);

  useEffect(() => {
    const fetchBalance = async (): Promise<void> => {
      if (address) {
        const phase2Config = collection?.stages?.filter(stage => stage.order === 2)[0]?.metadata[
          environment
        ];

        const provider = new ethers.providers.Web3Provider(window.ethereum as any);
        const balance = await getArenaBalanceOf(
          provider,
          phase2Config?.LOOTY_ARENA_TOKEN?.address || '',
          address,
          phase2Config?.LOOTY_ARENA_TOKEN?.abi,
        );
        setBalanceOfArenaToken(balance);
      }
    };
    if (collection && whitelistTyped[address]) fetchBalance();
  }, [address, showMintSuccessModal, collection]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const readMintedKeyEvents = async () => {
    try {
      const arenaCollection = collection;
      const phase1Config = arenaCollection?.stages?.filter(stage => stage.order === 1)[0]?.metadata[
        environment
      ]; // right now just for arena
      if (!phase1Config) return;

      const provider = new ethers.providers.JsonRpcProvider(arenaCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const eventFilter = contract1.filters.TransferSingle(
        null,
        '0x0000000000000000000000000000000000000000',
      );
      const fromBlock = 1;
      const toBlock = 'latest';

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events);
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        return sum + ev?.args?.value.toNumber();
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
    } catch (error) {
      console.error('Error initializing event listener:', error);
    }
  };
  useEffect(() => {
    if (address && isConnected && collection?.id) readMintedKeyEvents();
  }, [address, isConnected, collection]);

  return (
    <>
      <ArenaMintSuccessModal
        // isOpen={true}
        isOpen={isShowClaimMintSuccessModal}
        onClose={setShowClaimMintSuccessModal}
        mintedKeys={whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType ?? []}
      />

      <ArenaClaimedRewardModal
        balanceOfArenaToken={
          whitelistTyped[address] &&
          whitelistTyped[address?.toLowerCase()]?.tokenAmount &&
          formatEther(whitelistTyped[address?.toLowerCase()]?.tokenAmount)
        }
        isOpen={showMintSuccessModal}
        onClose={setShowMintSuccessModal}
      />

      <section className="mb-[36px] xl:mb-[50px]">
        <div className="w-full bg-gray-800 rounded-md">
          <img src={collection?.image} className="w-full h-auto max-h-[418px]" />
        </div>
      </section>

      <section className="mb-[36px] lg:mb-[72px]">
        <div className="flex flex-col justify-between xl:flex-row space-y-[36px]">
          <div className="xl:w-[44.76%]">
            <div className="flex gap-[19px] items-center mb-6">
              <img
                src={collection?.logo}
                className="lg:w-[96px] lg:h-[96px] w-[60px] h-[60px] bg-grayscale-800 rounded-md"
              />

              <div className="space-y-[8px] lg:space-y-[17px]">
                <h3 className="uppercase text-brand-primary-light text-lg lg:text-[32px]">
                  {collection?.stages.filter(st => st.id === stageId)[0].stageName}
                </h3>

                <div className="flex gap-[9px]">
                  {collection?.website && (
                    <IconSvg
                      icon="webM"
                      onClick={() =>
                        window.open(collection.website, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}

                  {collection?.twitter && (
                    <IconSvg
                      icon="twitterM"
                      onClick={() =>
                        window.open(collection.twitter, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mb-9 space-y-4 text-gray-300 lg:space-y-6">
              <p className="text-sm lg:text-base">
                Looty and The Arena have teamed up to bring an exciting new rewards program for
                $ARENA holders. Over the next two weeks, there will be $10,000 in $ARENA up for
                grabs each week, so a total of $20,000 in $ARENA! For every $2,000 worth of $ARENA
                staked, you&apos;ll receive one exclusive key, with no limit on how many you can
                earn. This partnership is the ultimate opportunity for $ARENA stakers to maximize
                their holdings and unlock valuable rewards.
              </p>

              <p>
                The campaign features three key types—Silver, Gold, and Platinum—each offering a
                chance to win incredible loot. Higher-tier keys come with re-roll options, allowing
                you to try your luck for even better rewards: Gold keys include 1 re-roll, and
                Platinum keys offer 2 re-rolls, while Silver keys do not include re-rolls.
                Don&apos;t miss out—stake your $ARENA, claim your keys, and enjoy this unique
                opportunity to win more $ARENA!
              </p>

              <div>
                <div className="mb-2 text-xs text-grayscale-400">Value of rewards</div>

                <div className="text-lg font-semibold text-grayscale-100">≈ 20,000</div>
              </div>
            </div>

            <div>
              <div className="mb-3 text-lg text-grayscale-100">Available keys:</div>

              <div className="flex gap-[15px]">
                <img src={`/assets/images/keys/1.png`} className="w-[96px] h-[96px] rounded" />
                <img src={`/assets/images/keys/2.png`} className="w-[96px] h-[96px] rounded" />
                <img src={`/assets/images/keys/3.png`} className="w-[96px] h-[96px] rounded" />
              </div>
            </div>
          </div>

          {collection && (
            <ArenaDistributions
              stages={collection?.stages}
              stageId={stageId}
              arenaTotalMintedKeys={arenaTotalMintedKeys}
              rewardsTotalValue={collection.rewardsTotalValue}
              setShowClaimMintSuccessModal={setShowClaimMintSuccessModal}
              setShowMintSuccessModal={setShowMintSuccessModal}
              networkCurrency={collection?.network?.networkCurrency}
            />
          )}
        </div>
      </section>

      <section>
        <div className="mb-[15px] lg:mb-[36px] flex justify-between items-center flex-wrap gap-[15px]">
          <h3 className="text-brand-primary-light text-[32px]">Rewards</h3>

          <PoolSelect />
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(320px,1fr))]">
          {rewardArenaCardData.map((card, index) => (
            <RewardArenaCard
              key={index}
              name={card.name}
              estimatedValue={card.estimatedValue}
              rarity={card.rarity}
              image={card.image}
              size="medium"
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default ArenaMintProject;
