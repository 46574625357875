import { useEffect, useState } from 'react';
import { ethers, utils } from 'ethers';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useSignMessage, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { Stage } from 'src/components/common/cards/types/types';
import NumberInputWithMultiplier from 'src/components/common/input/NumberInputWithMultiplier';
import IconSvg from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

import Button from 'src/components/button/Button';
import Countdown from 'src/components/common/Countdown';
import { checkIsHashUsed, checkIsHashUsedPhase2 } from 'src/utilities/looty';

interface Props {
  setShowClaimMintSuccessModal: any;
  setShowMintSuccessModal: any;
  stages?: Stage[];
  networkCurrency?: string;
  stageId: string;
  arenaTotalMintedKeys: string;
  rewardsTotalValue: string;
}

const environment = process.env.REACT_APP_ENVIRONMENT;

import whitelist_dev from '../../whitelist_dev.json';
import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import whitelist_prod from '../../whitelist_prod.json';
import SyncLoader from 'react-spinners/SyncLoader';
import { useHttp } from 'src/hooks';
const whitelist = environment === 'production' ? whitelist_prod : whitelist_dev;
const whitelistP1 = environment === 'production' ? whitelist_prod : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string
    | {
        signature: string;
        baseTokenAmount: number;
        tokenAmount: string[];
        hash?: string; // Make `hash` optional
      };
}

interface WhitelistP1Entry {
  signature: string;
  keysOfEachType: number[];
}

interface WhitelistP1 {
  [address: string]: WhitelistP1Entry | string; // Allow either an object or a string
}

const whitelistTyped: Whitelist = whitelist;
const whitelistTypedP1: WhitelistP1 = whitelistP1;

const ArenaDistributions = ({
  stages,
  setShowMintSuccessModal,
  networkCurrency,
  setShowClaimMintSuccessModal,
  stageId, arenaTotalMintedKeys, rewardsTotalValue
}: Props) => {
  console.log('stageId', stageId);
  const { sendRequest } = useHttp();
  const { id } = useParams();

  console.log(
    'aaaaa',
    stages?.filter(stage => stage?.id === stageId),
  );
  const [selectedStage, setSelectedStage] = useState<any>();
  const [selectedDistribution, setSelectedDistribution] = useState<any>();

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const phase1Config = stages?.filter(stage => stage.order === 1)[0].metadata[environment];
  const phase2Config = stages?.filter(stage => stage.order === 2)[0].metadata[environment];
  console.log('MintStages => phase1Config', phase1Config);
  console.log('MintStages => phase2Config', phase2Config);

  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();

  const [phase1Hash, setPhase1Hash] = useState<boolean>(false);
  const [phase2Hash, setPhase2Hash] = useState<boolean>(false);
  const { address, isConnected, chain } = useAccount();
  const [transactionMessage, setTransactionMessage] = useState('');
  const [isMintingInProgress, setIsMintingInProgress] = useState(false);

  const [isPhase1Loading, setIsPhase1Loading] = useState(false);
  const [isPhase2Loading, setIsPhase2Loading] = useState(false);

  // =================================================================
  const { data: hash, error, isPending, writeContractAsync } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirming) {
      setTransactionMessage('Transaction in progress...');
    }

    if (isConfirmed && receipt) {
      console.log('MintStages => handleMintKeysPhase2() done....');
      setTransactionMessage('Congratulations! Mint Successful.');
      setIsPhase2Loading(false);

      setShowMintSuccessModal(true);
    }
  }, [isConfirming, isConfirmed, receipt]);

  // =================================================================

  const {
    data: mintHashArena,
    error: errorMintArena,
    isPending: isPendingMintArena,
    writeContractAsync: writeContractMintArena,
  } = useWriteContract();
  const {
    isLoading: isConfirmingMintArena,
    isSuccess: isConfirmedMintArena,
    data: receiptMintArena,
  } = useWaitForTransactionReceipt({
    hash: mintHashArena,
  });

  useEffect(() => {
    console.log('isConfirmingMintArena', isConfirmingMintArena);
    console.log('isConfirmedMintArena', isConfirmedMintArena);
    if (isConfirmingMintArena) {
      setTransactionMessage('Transaction in progress...');
    }

    if (isConfirmedMintArena && receiptMintArena) {
      console.log('MintStages => handleMintKeysPhase1() done...');
      // Parse the tokenId from logs[0].topics[3]
      // const tokenIdHex = receipt.logs[0].topics[3];
      // const tokenId = ethers.BigNumber.from(tokenIdHex).toString();
      // setTokenId(tokenId); // Set the tokenId state with the parsed value
      // setIsMintingInProgress(false); // Hide progress modal
      setTransactionMessage('Congratulations! Mint Successful.');
      // setIsMintSuccessModalOpen(true); // Show success modal
      // toast.success("Congratulations! Mint Successful.", { autoClose: 6000 });
    // Delay opening the modal by 3 seconds
    console.log('updated upppppppppppppppppppp')
    setTimeout(() => {
      console.log('updated setTimeoutsetTimeoutsetTimeout')
      setIsPhase1Loading(false);
      handleMintKey();
      setPhase1Hash(true);
      fetchBlockchainKeyBalances();
      setShowClaimMintSuccessModal(true);
    }, 18000);
    
    console.log('updated downnnnnnnnnnnnnnn')

    }
  }, [isConfirmingMintArena, isConfirmedMintArena, receiptMintArena]);

  // =================================================================

  useEffect(() => {
    if (stageId) setSelectedStage(stages?.filter(stage => stage?.id === stageId)[0]);
  }, [stageId]);
  useEffect(() => {
    if (isConnected && address) {
      readPhase1Hash();
      readPhase2Hash();
    }
  }, [isConnected, address, stages, phase1Hash, phase2Hash]);

  type IconName =
    | 'avaxM'
    | 'avaxS'
    | 'ethM'
    | 'ethS'
    | 'bnbM'
    | 'bnbS'
    | 'egld'
    | 'solM'
    | 'solS'
    | 'suiM'
    | 'suiS';

  const getIconName = (networkCurrency: string, size: 'M' | 'S'): IconName => {
    switch (networkCurrency) {
      case 'AVAX':
        return `avax${size}`;
      case 'ETH':
        return `eth${size}`;
      case 'BNB':
        return `bnb${size}`;
      case 'EGLD':
        return `egld`;
      case 'SOL':
        return `sol${size}`;
      case 'SUI':
        return `sui${size}`;
      default:
        return `eth${size}`;
    }
  };

  const iconNameS = getIconName(networkCurrency || '', 'S');
  const iconNameM = getIconName(networkCurrency || '', 'M');

  const handleStageSelect = (stage: any) => {
    setSelectedStage(stage);
  };

  const handleDistributionSelect = (distribution: any) => {
    setSelectedDistribution(distribution);
  };
  const readPhase1Hash = async (): Promise<void> => {
    if (!address || !whitelistTypedP1[address]) return;

    const keysOfEachType = whitelistTypedP1[address.toLowerCase()]?.keysOfEachType || [];
    const messageHash = ethers.utils.solidityKeccak256(
      ['address', 'address', 'uint256', 'uint256[]'],
      [
        address,
        phase1Config.LOOTY_KEY_MINTER.address,
        // process.env.REACT_APP_LOOTY_KEY_CONTRACT_ADDRESS || '',
        keysOfEachType.length,
        keysOfEachType,
      ],
    );

    console.log('checking => phase1HashUsed.......', messageHash);
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const isHashUsed = await checkIsHashUsed(
      provider,
      phase1Config.LOOTY_KEY_MINTER.address || '',
      messageHash,
    );

    console.log('phase1HashUsed', isHashUsed);
    setPhase1Hash(isHashUsed);
  };

  const readPhase2Hash = async (): Promise<void> => {
    if (!address || !whitelistTyped[address] || whitelistTyped[address] === undefined) return;
    console.log('checking => phase2HashUsed.......');

    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const isHashUsed = await checkIsHashUsedPhase2(
      provider,
      phase2Config.LOOTY_FLAT_DISBURSEMENT.address || '',
      address,
      whitelistTyped[address] as {
        signature: string;
        baseTokenAmount: number;
        tokenAmount: string[];
      },
      phase2Config.LOOTY_ARENA_TOKEN.address || '',
    );

    console.log('phase2HashUsed', isHashUsed);

    setPhase2Hash(isHashUsed);
  };

  useEffect(() => {
    if (isConnected) {
      fetchBlockchainKeyBalances();
    }
  }, [address, isConnected]);

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    console.log('updated loadding....')

    const updatedBlockchainKeys: Record<number, number> = {};
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    console.log('arena disturgbution updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const phase1Config = stages?.filter(stage => stage.order === 1)[0].metadata[environment];
    console.log('readKeyBalanceeeeeeeeeeeeeee phase1Config', phase1Config);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      console.log('walletAddress, keyIndex', walletAddress, keyIndex)
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log('Number(balance)', Number(balance));
      console.log(' keyIndex', keyIndex, Number(balance));
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const hasBlockchainKeyBalance = () => {
    console.log('obbbb', Object.values(blockchainKeyBalances).some(balance => balance > 0))
    return Object.values(blockchainKeyBalances).some(balance => balance > 0);
  };

  const handleMintKeysPhase1 = async () => {
    console.log('handleMintKeysPhase1 start....');
    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }

    // if (!whitelistTyped[address]) {
    //   toast.error('User is not whitelisted.');
    //   return;
    // }

    try {
      setIsPhase1Loading(true);
      console.log('try catch block');

      const keysOfEachType = whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType || [];

      const messageHash = ethers.utils.solidityKeccak256(
        ['address', 'address', 'uint256', 'uint256[]'],
        [address, phase1Config.LOOTY_KEY.address, keysOfEachType.length, keysOfEachType],
      );
      console.log('messageHash', messageHash);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log('provider', provider);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY_MINTER.address,
        phase1Config.LOOTY_KEY_MINTER.abi,
        provider,
      );
      console.log('contract address', phase1Config.LOOTY_KEY_MINTER.address);
      // const isUsedHash = await contract.usedHashes(messageHash);
      // console.log('phase1Hash check', isUsedHash);

      // if (isUsedHash) {
      //   toast.error('Hash is already used');
      //   setIsPhase1Loading(false);
      //   return;
      // }

      console.log('writeContractMintArena....');
      await writeContractMintArena({
        address: phase1Config.LOOTY_KEY_MINTER.address as `0x${string}`,
        abi: phase1Config.LOOTY_KEY_MINTER.abi,
        functionName: 'mint',
        args: [whitelistTypedP1[address.toLowerCase()].signature, phase1Config.LOOTY_KEY.address, keysOfEachType],
      });
      console.log('handleMintKeysPhase1 end....');
    } catch (error: any) {
      setIsPhase1Loading(false);
      console.error('Mint NFT error:', error);
    }
  };

  const handleMintKeysPhase2 = async () => {
    console.log('handleMintKeysPhase2 start....');

    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }

    // if (!whitelistTyped[address]) {
    //   toast.error('User is not whitelisted.');
    //   return;
    // }
    // if (chain?.id !== targetChainId) {
    //   await switchChain?.({ chainId: targetChainId });
    // }
    // if (balanceOf && Number(balanceOf) >= 1) {
    //   toast.info("You have already minted.");
    //   return;
    // }

    // if (
    //   etherBalance?.data?.value &&
    //   parseFloat(formatEther(etherBalance.data.value)) < Number(nftPrice)
    // ) {
    //   toast.error("Insufficient balance to Mint NFT");
    //   return;
    // }

    try {
      setIsPhase2Loading(true);
      console.log('check messagehash value', [
        address,
        whitelistTyped[address].baseTokenAmount,
        1,
        [phase2Config.LOOTY_ARENA_TOKEN.address],
        whitelistTyped[address].tokenAmount,
      ]);
      const messageHash = ethers.utils.solidityKeccak256(
        ['address', 'uint256', 'uint256', 'address[]', 'uint256[]'],
        [
          address,
          whitelistTyped[address].baseTokenAmount,
          1,
          [phase2Config.LOOTY_ARENA_TOKEN.address],
          whitelistTyped[address].tokenAmount,
        ],
      );

      console.log('phase2Hash check');
      console.log('messageHash', messageHash);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        phase2Config.LOOTY_FLAT_DISBURSEMENT.address,
        phase2Config.LOOTY_FLAT_DISBURSEMENT.abi,
        provider,
      );

      console.log('phase2Config', phase2Config);

      const isUsedHash = await contract.usedHashes(messageHash);

      console.log('phase2Hash return', isUsedHash);

      if (isUsedHash) {
        toast.error('Hash is already used');
        setIsPhase2Loading(false);
        return;
      }
      console.log('writeContractMintArenaForPhase2....');

      await writeContractAsync({
        address: phase2Config.LOOTY_FLAT_DISBURSEMENT.address,
        abi: phase2Config.LOOTY_FLAT_DISBURSEMENT.abi,
        functionName: 'mint',
        args: [
          whitelistTyped[address].signature,
          whitelistTyped[address].baseTokenAmount,
          [phase2Config.LOOTY_ARENA_TOKEN.address],
          whitelistTyped[address].tokenAmount,
        ],
        // value: BigInt(priceInEther.toString()),
      });
    } catch (error: any) {
      console.error('Mint NFT error:', error);
      setIsPhase2Loading(false);

      // setMintError(error?.message || "Minting failed.");
      setTransactionMessage('Minting failed. Please try again.');
      toast.error(error?.message || 'Minting failed.');
      setIsMintingInProgress(false);
    }
  };

  const handleMintKey = () => {
    console.log('calling handleMintKey');

    if (address) {
      sendRequest(
        {
          url: `user/add-mint-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
  };

  return (
    <>
      <div className="xl:w-[46.46%] w-full bg-grayscale-800 p-[30px] pb-[40px] rounded-md h-fit">
        <div className="font-semibold text-lg lg:text-[24px] text-brand-primary-light mb-[25px]">
          Earn stages
        </div>

        <div className="space-y-3.5 mb-[25px]">
          {stages &&
            stageId &&
            selectedStage &&
            stages
              ?.filter(stage => stage?.id === stageId)[0]
              .distributions?.slice()
              .sort((a, b) => a.order - b.order)
              .map((distribution, index) => (
                <div
                  key={distribution.id}
                  onClick={() => !distribution.isSoon && handleDistributionSelect(distribution)} // Ensure stage.id is passed
                  className={`grid grid-cols-2 lg:grid-cols-3 lg:px-5 lg:py-[30px] p-5 border-2 rounded-md cursor-pointer space-y-[13px] lg:space-y-0 ${
                    // stage?.isLive
                    selectedDistribution?.id === distribution?.id
                      ? 'border-system-success'
                      : distribution?.isSoon
                      ? 'border-grayscale-700'
                      : 'border-grayscale-700'
                  }`}
                >
                  <div className="lg:col-span-1 col-span-full space-y-1.5">
                    <div className="text-lg font-semibold text-brand-primary-light max-w-[196px] truncate">
                      {distribution.name}
                    </div>

                    {!distribution.isSoon ? (
                      <>
                        {selectedStage.order === 1 &&
                          (phase1Hash ? (
                            <div className="text-xss text-grayscale-300">Keys Already Claimed</div>
                          ) : address && whitelistTypedP1[address.toLowerCase()] ? (
                            <div className="text-xss text-grayscale-300">You&apos;re eligible</div>
                          ) : (
                            <div className="text-xss text-grayscale-300">
                              You&apos;re not eligible
                            </div>
                          ))}

                        {selectedStage.order === 2 &&
                          (phase2Hash ? (
                            <div className="text-xss text-grayscale-300">
                              Reward Already Claimed
                            </div>
                          ) : whitelistTyped[address] ? (
                            <div className="text-xss text-grayscale-300">You&apos;re eligible</div>
                          ) : (
                            <div className="text-xss text-grayscale-300">
                              You&apos;re not eligible
                            </div>
                          ))}
                      </>
                    ) : null}
                  </div>

                  <div className="col-span-full border-t-2 border-grayscale-700 lg:hidden"></div>

                  <div className="col-span-1 space-y-[7px] lg:text-center flex flex-col justify-center lg:justify-start">
                    <div className="text-sm text-grayscale-300 leading-[19px]">Your keys</div>

                    <div className="text-lg font-semibold text-gray-100 leading-[24px]">
                      {address &&
                      whitelistTypedP1[address.toLowerCase()] &&
                      distribution.order === 1
                        ? whitelistTypedP1[address.toLowerCase()].keysOfEachType?.reduce(
                            (sum, key) => sum + key,
                            0,
                          )
                        : '-'}
                    </div>
                  </div>

                  <div className="col-span-1 flex flex-col items-end gap-y-2.5">
                    <div
                      className={`py-2 px-[15px] rounded-md flex gap-1.5 items-center border w-fit ${
                        distribution?.isLive
                          ? 'bg-system-success-dark border-system-success'
                          : distribution?.isSoon
                          ? 'bg-system-warning-dark border-system-warning'
                          : 'bg-system-error-dark border-system-error'
                      }`}
                    >
                      <IconSvg
                        icon={
                          distribution?.isLive
                            ? 'liveS'
                            : distribution?.isSoon
                            ? 'comingSoonS'
                            : 'finishedS'
                        }
                      />
                      <span className="text-sm text-grayscale-100 leading-[19px]">
                        {distribution?.isLive ? 'Live' : distribution?.isSoon ? 'Soon' : 'Expired'}
                      </span>
                    </div>

                    {distribution?.isLive ? (
                      <div className="text-xss text-grayscale-300 leading-[14px]">
                        <Countdown stage={stages[1]} />
                      </div>
                    ) : distribution?.isSoon ? (
                      'TBA'
                    ) : // <div className="text-xss text-grayscale-300">Remaining: 00d 00h 00m 00s</div>
                    null}
                  </div>
                </div>
              ))}
        </div>

        <div className="mb-[25px]">
          {arenaTotalMintedKeys !== '' ? (
            <ProgressBar
              progress={Math.floor((arenaTotalMintedKeys / 1629) * 100)}
              size="Full"
              totalValue={1629}
              estimatedValue={arenaTotalMintedKeys}
            />
          ) : (
            <div className="flex justify-center items-center">
              <SyncLoader color="#B73FFF" size={10} />
            </div>
          )}
        </div>

        <div className="flex items-center justify-center mb-[25px]">
          <div className="flex items-center space-x-3 w-fit">
            <div className="text-lg text-grayscale-300">Points to earn</div>

            <div className="flex gap-2.5 items-center">
              <IconSvg icon="starM" />

              <div className="text-[24px] text-[#FAFAFA] font-semibold">110</div>
            </div>
          </div>
        </div>

        {selectedStage?.order === 1 && selectedDistribution?.isLive && !phase1Hash && (
          <div className="flex justify-center mt-6">
            {isPhase1Loading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <div className="flex flex-col items-center space-y-[10px]">
                <div className="flex items-center space-x-8">
                  <Button
                    onClick={handleMintKeysPhase1}
                    type="primary"
                    size="m"
                    classNames={`${
                      !address || !whitelistTypedP1[address.toLowerCase()] || phase1Hash
                        ? 'cursor-not-allowed'
                        : ''
                    }`}
                    disabled={
                      !address ||
                      isPhase1Loading ||
                      !whitelistTypedP1[address.toLowerCase()] ||
                      phase1Hash
                    } // Disable button when loading
                  >
                    Claim
                  </Button>

                  {hasBlockchainKeyBalance() && (
                    <Button
                      onClick={() => navigate(`/arena/openbox/${id}`)}
                      type="secondary"
                      size="m"
                    >
                      Open Box
                    </Button>
                  )}
                </div>

                <p className="text-xs font-normal text-grayscale-300">Claiming is free, no fees</p>
              </div>
            )}
          </div>
        )}

        {selectedStage?.order === 2 && selectedDistribution?.isLive && !phase2Hash && (
          <div className="flex justify-center mt-5">
            {isPhase2Loading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <Button
                onClick={handleMintKeysPhase2}
                type="primary"
                classNames={`${
                  !address || !whitelistTyped[address] || phase2Hash ? 'cursor-not-allowed' : ''
                }`}
                size="m"
                disabled={!address || isPhase2Loading || phase2Hash || !whitelistTyped[address]} // Disable button when loading
              >
                Claim
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ArenaDistributions;
