import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {useAccount} from "wagmi";
import SyncLoader from "react-spinners/SyncLoader";

interface Props {
  collection?: Collection;
}
function ArenaCollectionBanner({ collection }: Props) {

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const { address, isConnected } = useAccount();
  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const readMintedKeyEvents = async () => {
    try {
      const arenaCollection = collection;
      const phase1Config = arenaCollection?.stages?.filter(stage => stage.order === 1)[0]?.metadata[environment]; // right now just for arena
      if (!phase1Config) return;

      const provider = new ethers.providers.JsonRpcProvider(arenaCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(phase1Config.LOOTY_KEY.address, phase1Config.LOOTY_KEY.abi, provider);
      const eventFilter = contract1.filters.TransferSingle(null, "0x0000000000000000000000000000000000000000");
      const fromBlock = 1;
      const toBlock = 'latest';

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events)
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        return sum + ev?.args?.value.toNumber();
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
    } catch (error) {
      console.error("Error initializing event listener:", error);
    }
  };
  useEffect(() => {
    if(address && isConnected && collection?.id)
      readMintedKeyEvents();

  }, [address, isConnected, collection])


  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{
        backgroundImage: `url(/assets/images/arena-banner.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="z-10 flex justify-end">
        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of Rewards</div>

            <div className="text-[24px] font-bold text-grayscale-100">≈ $20,000</div>
          </div>

          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Minted Keys</div>

            <div className="text-[24px] font-bold text-grayscale-100">
              <div className="flex">
                <div>
                  {arenaTotalMintedKeys !== '' ? Number(arenaTotalMintedKeys).toLocaleString() :
                    <SyncLoader color="#B73FFF" size={5}/>}
                </div>
                <div>/1,629</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="z-10 flex items-end justify-between">
        <div className="flex gap-[19px] items-center">
          {/* <div className="w-[109px] h-[109px] bg-grayscale-900 rounded-md"></div> */}
          <img src="/assets/images/arena-logo.png" className="w-[109px] h-[109px] rounded-md" />

          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-end">
              <h3 className="uppercase text-brand-primary-light">The Arena</h3>

              <div className="text-sm text-grayscale-200">AVAX</div>
            </div>

            <div>
              <div className="flex gap-[9px] items-center">
                {/* <div className="space-y-[5px]">
                  <button className="flex items-center gap-2 px-4 py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.11111 1H1V11H2.11111H9.88889H11V1H9.88889H2.11111ZM9.88889 2.11111V9.88889H2.11111V2.11111H9.88889ZM4.33333 5.44444H3.22222V8.77778H4.33333V5.44444ZM5.44444 3.22222H6.55556V8.77778H5.44444V3.22222ZM8.77778 6.55556H7.66667V8.77778H8.77778V6.55556Z"
                        fill="#E6E6E6"
                      />
                    </svg>

                    <span>Leaderboard</span>
                  </button>
                </div> */}

                <a href="https://arena.social/" target="_blank" rel="noopener noreferrer">
                  <IconSvg icon="webM" className="text-[#E6E6E6]" />
                </a>

                <a href="https://x.com/TheArenaApp" target="_blank" rel="noopener noreferrer">
                  <IconSvg icon="twitterM" className="text-[#E6E6E6]" />
                </a>
              </div>

              {/* <a className="underline text-xss text-grayscale-300" href="#">
                Get more points
              </a> */}
            </div>
          </div>
        </div>

        <div>
          <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
            <IconSvg icon="liveS" className="text-system-success" />

            <span className="text-sm text-grayscale-100">Live</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArenaCollectionBanner;
