import LeaderBoardCard from './components/LeaderboardCard';

export default function Leaderboard() {
  return (
    <>
      <section className="mb-[51px]">
        <div className="flex flex-col gap-5 justify-between items-start lg:items-end lg:flex-row">
          <div>
            <h2 className="uppercase mb-[19px] text-brand-primary-light">
              <span className="text-brand-primary">Looty</span> Leaderboard
            </h2>

            <p className="text-[#D7CCE0] max-w-[1032px] text-sm lg:text-lg text-grayscale-20">
              Each action you take on the app earns you Looty Points, helping you climb the ranks.
              Keep collecting points and aim for the top spot for a chance at exclusive rewards
              coming soon.
            </p>
          </div>

          <div>
            <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
              Total value of rewards
            </div>

            <div className="text-lg font-semibold text-grayscale-100 lg:text-end">≈ $370,000</div>
          </div>
        </div>
      </section>

      <div className="border-t border-grayscale-700 mb-[51px]"></div>

      <section>
        <div className="grid grid-cols-2 mb-3 lg:grid-cols-15">
          <div className="col-span-1 pl-5 text-sm lg:col-span-2 text-grayscale-300">Rank</div>

          <div className="col-span-1 pl-4 text-sm lg:col-span-11 text-grayscale-300 text-end lg:text-start">
            Username
          </div>

          <div className="hidden col-span-full pr-5 text-sm lg:col-span-2 text-grayscale-300 text-end lg:block">
            Points
          </div>
        </div>

        <LeaderBoardCard place={1} wallet="34kklw...hjk223" points={134322} />
        <LeaderBoardCard place={2} wallet="34kklw...hjk223" points={16322} />
        <LeaderBoardCard place={3} wallet="34kklw...hjk223" points={15322} />
        <LeaderBoardCard place={4} wallet="34kklw...hjk223" points={14322} />
        <LeaderBoardCard place={5} wallet="34kklw...hjk223" points={13322} />
        <LeaderBoardCard place={6} wallet="34kklw...hjk223" points={12322} />
        <LeaderBoardCard place={7} wallet="34kklw...hjk223" points={11322} />
      </section>
    </>
  );
}
