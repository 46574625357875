// src/components/progressBar/ProgressBar.tsx
import React from 'react';

interface ProgressBarProps {
  progress: number; // Progress percentage (0-100)
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="w-full h-2 bg-gray-300">
      <div
        className="h-full bg-green-500"
        style={{
          width: `${progress}%`,
          transition: 'width 0.5s ease-in-out', 
        }}
      />
    </div>
  );
};

export default ProgressBar;
