import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Raffle } from 'src/components/common/cards/types/types';
import { useHttp } from 'src/hooks';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import RaffleCard from './components/RaffleCard';
import RaffleTableRow from './components/RaffleTableRow';

function RaffleList() {
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(() => localStorage.getItem('activeTab') || 'all');

  const [activeView, setActiveView] = useState(() => localStorage.getItem('activeView') || 'grid');

  const tabs: { value: string; label: string; icon: Icons }[] = [
    { value: 'all', label: 'All projects', icon: 'allS' },
    {
      value: 'expired',
      label: 'Expired projects',
      icon: 'calendarS',
    },
  ];

  useEffect(() => {
    // Save the selected tab and view in localStorage whenever they change
    localStorage.setItem('activeTab', activeTab);
    localStorage.setItem('activeView', activeView);
  }, [activeTab, activeView]);

  useEffect(() => {
    getCollections();
  }, [activeTab]);

  const getCollections = () => {
    sendRequest(
      {
        url: 'raffle',
        method: 'GET',
      },
      (data: any) => {
        const sortedRaffles = data.sort((a: any, b: any) => {
          const stageA = a.raffleStages[0] || {};
          const stageB = b.raffleStages[0] || {};

          if (stageA.isLive && !stageB.isLive) return -1;
          if (!stageA.isLive && stageB.isLive) return 1;

          if (stageA.isSoon && !stageB.isSoon) return -1;
          if (!stageA.isSoon && stageB.isSoon) return 1;

          if (stageA.isExpired && !stageB.isExpired) return 1;
          if (!stageA.isExpired && stageB.isExpired) return -1;

          return 0;
        });
        setRaffles(sortedRaffles);
        if (activeTab === 'expired') {
          const expiredProjects = data.filter((raffle: any) => {
            return raffle.raffleStages && raffle.raffleStages[0]?.isExpired === true;
          });
          setRaffles(expiredProjects);
        } else {
          setRaffles(sortedRaffles);
        }
      },
    );
  };

  const handleClick = (raffle: Raffle, buttonType: 'raffle') => {
    switch (buttonType) {
      case 'raffle':
        navigate(`/raffle/list/${raffle?.name.toLowerCase().replace(/\s+/g, '-')}`, {
          state: { id: raffle?.id },
        });
        break;
      default:
        console.warn('Unhandled button type:', buttonType);
        break;
    }
  };

  return (
    <>
      <section className="mb-[51px]">
        <div className="flex items-end justify-between">
          <div>
            <h2 className="uppercase mb-[19px] text-brand-primary-light">
              Enter Exclusive <span className="text-brand-primary">Raffles</span>
            </h2>

            <p className="text-[#D7CCE0] max-w-[1032px] text-sm">
              Explore our exciting raffles. Each raffle offers a limited number of tickets and
              rewards such as unique NFTs or crypto tokens. Once the raffle ends, you&apos;ll
              receive keys to open box with chances to win the reward or Looty points. Don&apos;t
              miss your opportunity to enter one or all raffles before time runs out.
            </p>
          </div>

          <div className="flex gap-6">
            <div>
              <div className="mb-2 text-sm text-grayscale-400 text-end">Value of rewards</div>
              <div className="text-lg font-semibold text-grayscale-100 text-end">≈ $2,118.00</div>
            </div>

            <div className="border-l-2 h-[59px] border-grayscale-700"></div>

            <div>
              <div className="mb-2 text-sm text-grayscale-400 text-end">Total minted keys</div>
              <div className="text-lg font-semibold text-grayscale-100 text-end">1,991</div>
            </div>

            <div className="border-l-2 h-[59px] border-grayscale-700"></div>

            <div>
              <div className="mb-2 text-sm text-grayscale-400 text-end">Total reward won</div>
              <div className="text-lg font-semibold text-grayscale-100 text-end">445</div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-10">
        <div className="flex justify-between px-5 py-3 mb-10 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded flex items-center gap-2 cursor-pointer ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <IconSvg
                  icon={tab.icon}
                  className={activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'}
                />

                <div
                  className={`text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>

          <div className="flex gap-2">
            <div
              onClick={() => setActiveView('grid')}
              className={`flex items-center justify-center w-10 h-10 rounded-md cursor-pointer ${
                activeView === 'grid' ? 'bg-brand-secondary text-grayscale-800' : 'bg-gray-700'
              }`}
            >
              <IconSvg
                icon="gridM"
                className={activeView === 'grid' ? 'text-grayscale-800' : 'text-grayscale-200'}
              />
            </div>

            <div
              onClick={() => setActiveView('list')}
              className={`flex items-center justify-center w-10 h-10 rounded-md cursor-pointer ${
                activeView === 'list' ? 'bg-brand-secondary text-grayscale-800' : 'bg-gray-700'
              }`}
            >
              <IconSvg
                icon="listM"
                className={activeView === 'list' ? 'text-grayscale-800' : 'text-grayscale-200'}
              />
            </div>
          </div>
        </div>
      </section>

      {activeView === 'list' ? (
        <div className="flex flex-col">
          <div className="overflow-x-auto scrollbar-dark">
            <div className="grid mb-5 grid-cols-15 min-w-[1450px]">
              <div className="col-span-4 pl-5 text-sm text-grayscale-300">Project</div>
              <div className="col-span-2 text-sm text-grayscale-300">Floor price</div>
              <div className="col-span-2 text-sm text-grayscale-300">Ticket price/TTV</div>
              <div className="col-span-3 text-sm text-grayscale-300">Tickets bought</div>
              <div className="col-span-2 text-sm text-grayscale-300">Time</div>
              <div className="col-span-2"></div>
            </div>

            {raffles?.map((raffle: Raffle, index: number) => (
              <RaffleTableRow key={index} raffle={raffle} handleClick={handleClick} />
            ))}
          </div>
        </div>
      ) : (
        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(412px,1fr))]">
          {raffles?.map((raffle: Raffle, index: number) => (
            <RaffleCard key={index} raffle={raffle} handleClick={handleClick} />
          ))}
        </div>
      )}
    </>
  );
}

export default RaffleList;
