// src/components/VideoPlayer.tsx
import React, { useRef, useEffect } from 'react';

interface VideoPlayerProps {
  videoId: string;
  onProgress: (progress: number) => void; // callback to update progress
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoId, onProgress }) => {
  const playerRef = useRef<HTMLIFrameElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const playerInstance = useRef<any>(null); // Reference to the YouTube player instance

  useEffect(() => {
    // Create the YouTube player
    const onPlayerStateChange = (event: any) => {
      if (event.data === 1) {
        // If the video is playing
        if (intervalRef.current) {
          clearInterval(intervalRef.current); // Clear any previous intervals
        }

        // Update progress every second when the video is playing
        intervalRef.current = setInterval(() => {
          if (playerInstance.current) {
            const currentTime = playerInstance.current.getCurrentTime();
            const duration = playerInstance.current.getDuration();
            if (duration && currentTime) {
              // Calculate progress as a percentage
              const progress = (currentTime / duration) * 100;
              onProgress(progress);
            }
          }
        }, 1000); // Update progress every second
      } else if (event.data === 0 || event.data === 2) {
        // If video is paused or ended, clear the interval
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    };

    // Load YouTube iframe API
    const onYouTubeIframeAPIReady = () => {
      playerInstance.current = new window.YT.Player(playerRef.current!, {
        videoId,
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    };

    if (!window.YT) {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      // Cleanup interval on component unmount
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [videoId]);

  return (
    <iframe
      ref={playerRef}
      width="100%"
      className="lg:h-[567px] h-[180px]"
      // height="567"
      src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

export default VideoPlayer;
