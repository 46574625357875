import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttp } from 'src/hooks';

const DiscordCallback: React.FC = () => {
  const navigate = useNavigate();
  const { sendRequest } = useHttp();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const fetchDiscordUser = () => {
    sendRequest(
      {
        url: `auth/discord/callback?code=${code}&state=${state}`,
        method: 'GET',
      },
      (data: any) => {
        navigate('/');
      },
    );
  };

  useEffect(() => {
    fetchDiscordUser();
  }, []);

  return <div></div>;
};

export default DiscordCallback;
