import { Outlet } from 'react-router-dom';
import AppSidebar from './components/AppSidebar';
import AppNavbar from './components/AppNavbar';
import AppFooter from './components/AppFooter';

function AppLayout() {
  return (
    <>
      <div className="flex bg-grayscale-800">
        <div className="hidden lg:block">
          <AppSidebar />
        </div>

        <div
          className="flex overflow-y-auto flex-col w-full h-screen bg-grayscale-900 no-scrollbar"
          id="app-layout-main"
        >
          <AppNavbar />

          <main className="pb-[50px] lg:pb-[99px] px-[24px] lg:pr-[61px] lg:pl-[67px] flex-1 w-full">
            <Outlet />
          </main>

          <AppFooter />
        </div>
      </div>
    </>
  );
}

export default AppLayout;