import IconSvg from 'src/components/common/ui/IconSvg';

type Props = {
  onClick: () => void;
  title: string;
};

export default function MultiversEnterBtn({ onClick, title }: Props) {
  return (
    <button
      className={`flex items-center justify-center gap-2 py-2 rounded-md text-sm font-bold text-grayscale-200 bg-grayscale-700 w-[132px]`}
      onClick={onClick}
    >
      <span>{title}</span>

      <IconSvg icon="externalLinkS" />
    </button>
  );
}
