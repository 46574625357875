import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Button from 'src/components/button/Button';
import { Raffle } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

interface Props {
  raffle: Raffle;
  handleClick: (raffle: Raffle, buttonType: 'raffle') => void;
}

function RaffleTableRow({ raffle, handleClick }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const getIconName = (networkCurrency: string): Icons => {
    switch (networkCurrency) {
      case 'AVAX':
        return 'avaxM';
      case 'ETH':
        return 'ethM';
      case 'BNB':
        return 'bnbM';
      case 'SOL':
        return 'solM';
      case 'SUI':
        return 'suiM';
      default:
        return 'ethM'; // Default icon if currency is not listed
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  const icons: { icon: Icons; className: string; label: string }[] = [
    {
      icon: 'usersS',
      className: 'cursor-pointer text-brand-primary-light',
      label: 'Partners raffle',
    },
    { icon: 'starS', className: 'cursor-pointer', label: 'Looty points boost' },
    { icon: 'logoS', className: 'cursor-pointer text-brand-primary', label: 'Looty raffle' },
  ];

  const iconName = getIconName(raffle.network.networkCurrency);

  const startingSoon = raffle.raffleStages[0].isSoon;
  return (
    <>
      <div className="grid grid-cols-15 px-5 py-[17.5px] rounded-md bg-grayscale-800 mb-2.5 last-of-type:mb-0 items-center min-w-[1450px]">
        <div className="flex items-center col-span-4 text-sm gap-x-5 text-grayscale-300">
          <img
            src={
              !startingSoon
                ? raffle.logo
                : `/assets/images/blur-logo-${Math.floor(Math.random() * 4) + 1}.png`
            }
            className="w-[60px] h-[60px] bg-cover bg-center"
          />

          <div className="flex items-center gap-3.5">
            {!startingSoon ? (
              <h5 className="break-words text-brand-primary-light">{raffle?.name}</h5>
            ) : (
              <img
                src={`/assets/images/blur-collection-name-${Math.floor(Math.random() * 2) + 1}.png`}
                alt="blur collection name"
              />
            )}

            {!startingSoon && (
              <div className="flex items-center gap-[5px]">
                {icons.map(({ icon, label, className }, index) => (
                  <div key={index}>
                    <div key={index} data-tooltip-id={`raffle-icons-tooltip-${index}`}>
                      <IconSvg icon={icon} className={className} />
                    </div>

                    <Tooltip
                      id={`raffle-icons-tooltip-${index}`}
                      content={label}
                      place="top"
                      className="!py-1.5 !px-3 !text-sm !rounded !text-grayscale-700 !bg-brand-primary-light !z-50"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="col-span-2">
          {!startingSoon ? (
            <p className="text-lg font-semibold text-grayscale-100">≈ {raffle.ticketsTotalValue}</p>
          ) : (
            <img src={'/assets/images/blur-info.png'} alt="blur info" />
          )}
        </div>

        <div className="col-span-2 flex items-center gap-[9px]">
          {startingSoon ? (
            <>
              <IconSvg icon={iconName} />

              <p className="text-lg font-semibold text-grayscale-100">0,000</p>
            </>
          ) : (
            <img src={'/assets/images/blur-info-2.png'} alt="blur info" />
          )}
        </div>

        <div className="col-span-3 text-lg font-bold text-center text-grayscale-100">-</div>

        <div className="col-span-2 text-sm text-grayscale-300">
          {(() => {
            if (raffle.raffleStages[0].isExpired) {
              // Expired project
              return (
                <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="expired" />

                  <span className="text-sm text-grayscale-100">Expired</span>
                </div>
              );
            } else if (raffle.raffleStages[0].isLive) {
              // Live project
              return (
                <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="live2M" />
                  <span className="text-sm text-grayscale-100">Live</span>
                </div>
              );
            } else {
              // Soon project
              return (
                <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                  <IconSvg icon="comingSoon2M" />
                  <span className="text-sm text-grayscale-100">Soon</span>
                </div>
              );
            }
          })()}
        </div>

        <div className="flex items-center justify-end col-span-2">
          {startingSoon ? (
            <button
              onClick={toggleAccordion}
              className={`flex items-center p-2 rounded-md gap-x-2 w-[153px] justify-center ${
                isAccordionOpen
                  ? 'bg-brand-primary-light text-grayscale-800'
                  : 'bg-grayscale-700 text-grayscale-200'
              }`}
            >
              <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

              <IconSvg
                icon="arrowDownM"
                className={`transition-transform duration-300 ${
                  isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                }`}
              />
            </button>
          ) : (
            <img src={'/assets/images/blur-button.png'} alt="blur button" />
          )}
        </div>
      </div>

      {isAccordionOpen && (
        <div className="grid grid-cols-15 px-5 py-[17.5px] rounded-md bg-grayscale-900 mb-2.5 last-of-type:mb-0 items-center border-2 border-grayscale-700 min-w-[1450px]">
          <div className="flex items-center col-span-4 text-sm gap-x-5 text-grayscale-300">
            <img src={raffle.logo} className="w-[60px] h-[60px]" />

            <div className="flex items-center gap-3.5">
              <h5 className="text-brand-primary-light max-w-[255px] truncate">{raffle?.name}</h5>

              <div className="flex items-center gap-[5px]">
                {icons.map(({ icon, label, className }, index) => (
                  <div key={index}>
                    <div key={index} data-tooltip-id={`raffle-icons-tooltip-${index}`}>
                      <IconSvg icon={icon} className={className} />
                    </div>

                    <Tooltip
                      id={`raffle-icons-tooltip-${index}`}
                      content={label}
                      place="top"
                      className="!py-1.5 !px-3 !text-sm !rounded !text-grayscale-700 !bg-brand-primary-light !z-50"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <p className="text-lg font-semibold text-grayscale-100">≈ {raffle.ticketsTotalValue}</p>
          </div>

          <div className="col-span-2 flex items-center gap-[9px]">
            <IconSvg icon={iconName} />

            <p className="text-lg font-semibold text-grayscale-100">0,000</p>
          </div>

          <div className="col-span-3">
            <ProgressBar
              progress={0}
              size="Small"
              estimatedValue={0}
              totalValue={raffle.ticketsTotalValue}
            />
          </div>

          <div className="col-span-2 text-sm text-grayscale-300">
            {(() => {
              if (raffle.raffleStages[0].isExpired) {
                // Expired project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />

                    <span className="text-sm text-grayscale-100">Expired</span>
                  </div>
                );
              } else if (raffle.raffleStages[0].isLive) {
                // Live project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="live2M" />
                    <span className="text-sm text-grayscale-100">Live</span>
                  </div>
                );
              } else {
                // Soon project
                return (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning mb-3 flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoon2M" />
                    <span className="text-sm text-grayscale-100">Soon</span>
                  </div>
                );
              }
            })()}

            <div className="text-sm font-semibold text-grayscale-200">
              <Countdown stage={raffle?.raffleStages[0]} />
            </div>
          </div>

          <div className="flex items-center justify-end col-span-2">
            {(() => {
              if (raffle?.raffleStages[0].isExpired) {
                // Expired project
                return (
                  <Button type="ghost" size="m" onClick={() => handleClick(raffle, 'raffle')}>
                    Open Box
                  </Button>
                );
              } else if (raffle.name !== 'Qiibee' && raffle?.raffleStages[0].isLive) {
                // Live project
                return (
                  <Button type="primary" size="m" onClick={() => handleClick(raffle, 'raffle')}>
                    Play
                  </Button>
                );
              } else {
                // Soon project
                return (
                  <Button type="secondary" size="m" onClick={() => handleClick(raffle, 'raffle')}>
                    Join
                  </Button>
                );
              }
            })()}
          </div>
        </div>
      )}
    </>
  );
}

export default RaffleTableRow;
