import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { disconnect } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { config } from '../../providers/config';
import IconSvg from './ui/IconSvg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { Tooltip } from 'react-tooltip';
import SyncLoader from 'react-spinners/SyncLoader';
import * as path from 'node:path';
import { forwardRef, useImperativeHandle } from 'react';

import {
  useExtensionLogin,
  useWalletConnectV2Login,
  useLedgerLogin,
  useWebWalletLogin,
} from '@multiversx/sdk-dapp/hooks';
import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Network } from './cards/types/types';
import ChooseNetwork from './ChooseNetwork';

type ConnectWalletProps = {
  selectedNetwork: {
    id: string;
    chainId: number | null;
    networkCurrency: string;
  } | null;
};

export const ConnectWallet = forwardRef((props, ref) => {
  const { address } = useAccount();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isXLogedIn, setIsXLogedIn] = useState(false);
  const navigate = useNavigate();
  const { sendRequest } = useHttp();
  const [user, setUser] = useState<any>(null);
  const [accessToken, setAccessToken] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null); // Lifted state
  const [tooltipContent, setTooltipContent] = useState('Copy');
  const [initiateLogin] = useWebWalletLogin({
    callbackRoute: window.location.pathname,
    nativeAuth: false,
  });

  const { address: addressMultiversX, account: accountMultiversX } = useGetAccountInfo();

  useEffect(() => {
    console.log('accountMultiversX', accountMultiversX);
    console.log('addressMultiversX', addressMultiversX);
    if (accountMultiversX.address) {
      createXportalUser();
    }
  }, [accountMultiversX, addressMultiversX]);
  const createXportalUser = () => {
    const jwtString = localStorage.getItem('jwt');
    let token = '';
    let createMvxUser = true;
    if (jwtString) {
      const data = JSON.parse(jwtString);
      token = data.token;
      if (data.isMvxLoggedIn === true) createMvxUser = false;
    }

    if (createMvxUser) {
      sendRequest(
        {
          url: `user/multiversx`,
          accessToken: token,
          method: 'POST',
          payload: { xportalWalletAddress: addressMultiversX },
        },
        (data: any) => {
          if (data.success) {
            const jwtString = localStorage.getItem('jwt');
            const jdata = JSON.parse(jwtString);
            localStorage.setItem(
              'jwt',
              JSON.stringify({
                token: data.token,
                isXLoggedIn: jwtString && jdata.isXLoggedIn === true,
                isMLoggedIn: jwtString && jdata.isMLoggedIn === true,
                isMvxLoggedIn: true,
              }),
            );
          }
        },
      );
    }
  };

  useEffect(() => {
    console.log('Current selected network:', selectedNetwork);
    // Use selectedNetwork here for logic
  }, [selectedNetwork]);

  const getUserInfo = () => {
    console.log('uuserinfo: accessToken', accessToken);
    sendRequest(
      {
        url: `user/user-info`,
        accessToken,
      },
      (data: any) => {
        setUser(data);
        console.log('userdata', user);
      },
    );
  };
  useEffect(() => {
    accessToken && getUserInfo();
  }, [accessToken]);

  const handleCopy = async () => {
    if (address) {
      await navigator.clipboard.writeText(address);
      setTooltipContent('Copied');

      setTimeout(() => {
        setTooltipContent('Copy');
      }, 3000);
    }
  };
  // useEffect(() => {
  //   if (address) {
  //     console.log('metamask:: address', address);
  //     createMetamaskUser(address);
  //   }
  // }, [address]);

  const handleLogOut = () => {
    setIsXLogedIn(false);
    const jwtString = localStorage.getItem('jwt');
    const data = JSON.parse(jwtString);
    console.log('while x logout', data);
    if (data.isMvxLoggedIn && data.isMvxLoggedIn === true) {
      localStorage.setItem(
        'jwt',
        JSON.stringify({ token: data.token, isXLoggedIn: false, isMvxLoggedIn: true }),
      );
      // navigate('/');
      window.location.href = '/'
    } else {
      localStorage.removeItem('jwt');
      setAccessToken('');
      window.location.href = '/'
      // navigate('/');
    }
  };
  const handleConnectX = () => {
    // setIsEnterLoading(true);
    setIsLoggingIn(true);
    // if (address) {
    sendRequest(
      {
        url: `twitter/login`,
        method: 'GET',
      },
      (data: any) => {
        if (data) {
          // window.open(data);

          window.location.href = data;
        }
      },
    );
    // }
    console.log('Connect X button clicked');
    // Add your logic here
  };

  const handleLogOutMVX = () => {
    const jwtString = localStorage.getItem('jwt');
    const data = JSON.parse(jwtString);
    console.log('while mvx logout', data);
    if (data.isXLoggedIn && data.isXLoggedIn === true) {
      localStorage.setItem(
        'jwt',
        JSON.stringify({ token: data.token, isMvxLoggedIn: false, isXLoggedIn: true }),
      );
      navigate('/');
    } else {
      localStorage.removeItem('jwt');
      setAccessToken('');
      navigate('/');
    }
    logout();
  };
  const handleConnectMVX = () => {
    initiateLogin();
  };

  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1);
    if (jwtString || pathname == 'auth/twitter/callback') setIsLoggingIn(true);
    if (jwtString) {
      console.log('jwtString', jwtString);
      const data = JSON.parse(jwtString);
      const token = data.token;
      if (data.isXLoggedIn) setIsXLogedIn(true);

      console.log('jwtString: token', token);

      sendRequest(
        {
          url: 'auth/verify-token',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: JSON.stringify({ token }),
        },
        (responseData: any) => {
          setIsLoggingIn(false);
          console.log('verify-token', responseData);

          if (responseData.success) {
            setAccessToken(responseData.token);
          } else {
            localStorage.removeItem('jwt');
          }
        },
      );
    }
  }, [window.location.href]);
  
  useImperativeHandle(ref, () => ({
    logOutMVX: handleLogOutMVX,
    logOutTwitter: handleLogOut,
  }));
  return (
    <>
      <ChooseNetwork
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        handleLogOutMVX={handleLogOutMVX}
      />

      {isLoggingIn ? (
        <SyncLoader color="#fff" size={8} />
      ) : (
        <>
          {!isXLogedIn ? (
            <div
              className="font-semibold text-center cursor-pointer text-grayscale-200 px-4 py-2 lg:!p-0 text-sm lg:text-base bg-grayscale-900 rounded-md w-fit"
              onClick={handleConnectX}
            >
              Connect with X
            </div>
          ) : (
            <Listbox value={null}>
              <ListboxButton className="group px-4 bg-grayscale-800 flex justify-between items-center space-x-2 py-2 rounded-[11px]">
                <div className="text-base font-semibold text-center cursor-pointer text-grayscale-200">
                  {user?.twitterUsername}
                </div>
              </ListboxButton>

              <ListboxOptions
                anchor={{ to: 'bottom', gap: -3 }}
                className={`flex z-10 flex-col px-2 pb-2 cursor-pointer group w-[var(--button-width)] bg-grayscale-800 rounded-[11px] data-[open]:rounded-t-[0px]`}
              >
                <ListboxOption
                  className="px-4 py-2 cursor-pointer hover:bg-black"
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <span className="text-xs text-grayscale-200">Logout X</span>
                </ListboxOption>
              </ListboxOptions>
            </Listbox>
          )}
        </>
      )}
    </>
  );
});
