import IconSvg from 'src/components/common/ui/IconSvg';
interface LeaderBoardCardProps {
  place: number;
  wallet: string;
  points: number;
  loggedUserRank?: number;
  isCurrentUser?: boolean; // New prop
}

export default function LeaderBoardCard({
  place,
  wallet,
  points,
  loggedUserRank,
  isCurrentUser,
}: LeaderBoardCardProps) {
  return (
    <div
      className={`grid grid-cols-5 mb-3 lg:grid-cols-15 rounded-[10px] py-[15px] px-[20px] items-center border-[1.5px] border-transparent hover:border-brand-secondary hover:bg-[#FFBD3D1F] transition-all duration-300 mb-2.5 ${
        isCurrentUser ? 'you' : 'bg-grayscale-800'
      }`}
    >
      <div className="col-span-1 lg:col-span-2">
        <div className="text-base font-extrabold lg:text-lg text-grayscale-400">
          {isCurrentUser ? loggedUserRank : place}
        </div>
      </div>

      <div className="col-span-4 lg:col-span-11">
        <div className="flex flex-row-reverse gap-5 items-center lg:flex-row">
          <div className="w-[48px] h-[48px]">
            <IconSvg icon="profilePlaceholder" />
          </div>

          <div className="space-y-[2px]">
            <div className="flex gap-0 items-end lg:gap-1 lg:flex-col lg:items-start">
              <div className="text-base lg:text-lg text-brand-primary-light">{wallet}</div>

              {isCurrentUser && (
                <div className="text-base font-bold lg:text-lg text-brand-primary-light">YOU</div>
              )}
            </div>

            <div className="block text-right text-xss text-grayscale-400 lg:hidden lg:text-left">
              points: {points}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden col-span-2 lg:block">
        <div className="flex gap-3 justify-end items-center">
          <div className="text-[24px] font-bold text-grayscale-200">
            {Number(points).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}
