import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Collection } from 'src/components/common/cards/types/types';
import ArenaClaimedRewardModal from 'src/components/common/modal/ArenaClaimedRewardModal';
import IconSvg from 'src/components/common/ui/IconSvg';
import { rewardArenaCardData } from 'src/dummydata';
import { useHttp } from 'src/hooks';
import MintStages from './MintStages';
import ArenaMintSuccessModal from 'src/components/common/modal/ArenaMintSuccessModal';

import whitelist_dev from '../../whitelist_dev.json';
import whitelist_prod from '../../whitelist_prod.json';
import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import { useAccount, useSwitchChain } from 'wagmi';
import { ethers } from 'ethers';
import { getArenaBalanceOf } from 'src/utilities/looty';
import { formatEther } from 'viem';
import RewardArenaCard from 'src/components/common/cards/RewardArenaCard';

const environment = process.env.REACT_APP_ENVIRONMENT;

const whitelist = environment === 'production' ? whitelist_prod : whitelist_dev;
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface Whitelist {
  [address: string]:
    | string
    | {
        signature: string;
        baseTokenAmount: number;
        tokenAmount: string[];
        hash?: string; // Make `hash` optional
      };
}

interface WhitelistP1 {
  [address: string]:
    | string
    | {
        signature: string;
        keysOfEachType: number[];
      };
}

const whitelistTyped: Whitelist = whitelist;
const whitelistTypedP1: WhitelistP1 = whitelistP1;

function MintProject() {
  const { address } = useAccount();
  const [balanceOfArenaToken, setBalanceOfArenaToken] = useState<string | undefined>(undefined);
  const [showMintSuccessModal, setShowMintSuccessModal] = useState(false);
  const [isShowClaimMintSuccessModal, setShowClaimMintSuccessModal] = useState(false);

  const [collection, setCollection] = useState<Collection>();
  const { sendRequest } = useHttp();

  const { switchChain } = useSwitchChain();
  const { id } = useParams();
  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections/${id}`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
        console.log('data?.network.chainId', data?.network.chainId);
        switchChain({ chainId: data?.network.chainId });
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, [id]);

  useEffect(() => {
    const fetchBalance = async (): Promise<void> => {
      if (address) {
        const phase2Config = collection?.stages?.filter(stage => stage.order === 2)[0]?.metadata[
          environment
        ];

        const provider = new ethers.providers.Web3Provider(window.ethereum as any);
        const balance = await getArenaBalanceOf(
          provider,
          phase2Config?.LOOTY_ARENA_TOKEN?.address || '',
          address,
          phase2Config?.LOOTY_ARENA_TOKEN?.abi,
        );
        setBalanceOfArenaToken(balance);
      }
    };
    if (collection && whitelistTyped[address]) fetchBalance();
  }, [address, showMintSuccessModal, collection]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ArenaMintSuccessModal
        // isOpen={true}
        isOpen={isShowClaimMintSuccessModal}
        onClose={setShowClaimMintSuccessModal}
        mintedKeys={whitelistTypedP1[address?.toLowerCase()]?.keysOfEachType}
      />
      {/* <RewardInfoModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} /> */}
      <ArenaClaimedRewardModal
        balanceOfArenaToken={
          whitelistTyped[address] && formatEther(whitelistTyped[address.toLowerCase()]?.tokenAmount)
        }
        isOpen={showMintSuccessModal}
        onClose={setShowMintSuccessModal}
      />
      <section className="mb-[50px]">
        <div
          className="bg-gray-800 rounded-md h-[418px] w-full"
          style={{
            backgroundImage: `url(${collection?.image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>
      </section>

      <section className="mb-[72px]">
        <div className="flex justify-between">
          <div className="w-[44.76%]">
            <div className="flex gap-[19px] items-center mb-6">
              <img
                src={collection?.logo}
                className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"
              />

              <div className="space-y-[17px]">
                <h3 className="uppercase text-brand-primary-light">{collection?.name}</h3>

                <div className="flex gap-[9px]">
                  {collection?.webLink && (
                    <IconSvg
                      icon="webM"
                      onClick={() =>
                        window.open(collection.webLink, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400"
                    />
                  )}
                  {collection?.twitter && (
                    <IconSvg
                      icon="twitterM"
                      onClick={() =>
                        window.open(collection.twitter, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400"
                    />
                  )}

                  {/* <IconSvg
                    icon="discordM"
                    onClick={() =>
                      collection?.discord
                        ? window.open(collection.discord, '_blank', 'noopener,noreferrer')
                        : null
                    }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.twitter ? '' : 'opacity-50 !cursor-default'
                    }`}
                  />
                  <IconSvg
                    icon="hyperspaceM"
                    // onClick={() =>
                    //   collection?.webLink
                    //     ? window.open(
                    //         `https://twitter.com/${collection.twitter}`,
                    //         '_blank',
                    //         'noopener,noreferrer',
                    //       )
                    //     : null
                    // }
                    className={`cursor-pointer text-grayscale-400 ${
                      collection?.hyperspaceLink ? '' : 'opacity-50 !cursor-default'
                    }`}
                  /> */}
                </div>
              </div>
            </div>

            <div className="space-y-6 text-gray-300 mb-9">
              <p>
                Looty and The Arena have teamed up to bring an exciting new rewards program for
                $ARENA holders. Over the next two weeks, there will be $10,000 in $ARENA up for
                grabs each week, so a total of $20,000 in $ARENA! For every $2,000 worth of $ARENA
                staked, you&apos;ll receive one exclusive key, with no limit on how many you can earn.
                This partnership is the ultimate opportunity for $ARENA stakers to maximize their
                holdings and unlock valuable rewards.
              </p>

              <p>
                The campaign features three key types—Silver, Gold, and Platinum—each offering a
                chance to win incredible loot. Higher-tier keys come with re-roll options, allowing
                you to try your luck for even better rewards: Gold keys include 1 re-roll, and
                Platinum keys offer 2 re-rolls, while Silver keys do not include re-rolls. Don&apos;t
                miss out—stake your $ARENA, claim your keys, and enjoy this unique opportunity to
                win more $ARENA!
              </p>

              <div>
                <div className="mb-2 text-xs text-grayscale-400">Value of rewards</div>

                <div className="text-lg font-semibold text-grayscale-100">≈ 10,000</div>
              </div>
            </div>

            <div>
              <div className="mb-3 text-lg text-grayscale-100">Available keys:</div>

              <div className="flex gap-[15px]">
                <img
                  src={`/assets/images/keys/1.png`}
                  className="w-[96px] h-[96px] rounded mb-[18px]"
                />
                <img
                  src={`/assets/images/keys/2.png`}
                  className="w-[96px] h-[96px] rounded mb-[18px]"
                />
                <img
                  src={`/assets/images/keys/3.png`}
                  className="w-[96px] h-[96px] rounded mb-[18px]"
                />
                {/* <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div> */}
                {/* <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div> */}
                {/* <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div>
                <div className="w-[96px] h-[96px] bg-grayscale-800 rounded-md"></div> */}
              </div>
            </div>
          </div>
          {collection && (
            <MintStages
              stages={collection?.stages}
              setShowClaimMintSuccessModal={setShowClaimMintSuccessModal}
              setShowMintSuccessModal={setShowMintSuccessModal}
              networkCurrency={collection?.network?.networkCurrency}
            />
          )}
        </div>
      </section>

      <section>
        <div className="mb-[36px]">
          <h3 className="text-brand-primary-light">Rewards</h3>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {rewardArenaCardData.map((card, index) => (
            <RewardArenaCard
              key={index}
              name={card.name}
              estimatedValue={card.estimatedValue}
              rarity={card.rarity}
              image={card.image}
              size="medium"
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default MintProject;
