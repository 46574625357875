import React from 'react';
import IconSvg from '../ui/IconSvg';
interface Props {
  size?: 'small' | 'medium';
  name: string;
  estimatedValue?: string;
  rarity?: string;
  image: string;
}

const rarityToIconMap: Record<string, string> = {
  Common: 'commonM',
  Uncommon: 'uncommonM',
  Rare: 'rareM',
  Epic: 'epicM',
  Legendary: 'legendaryM',
};

const rarityToColorMap: Record<string, string> = {
  Common: "bg-grayscale-700 text-white", // Adjust colors as needed
  Uncommon: "bg-rarity-uncommon-dark text-white",
  Rare: "bg-rarity-rare-dark text-white",
  Epic: "bg-rarity-epic-dark text-white",
  Legendary: "bg-rarity-legendary-dark text-black",
};


function RewardCardQiibee({ size = 'small', name, estimatedValue, rarity, image }: Props) {
  const cardHeight = size === 'small' ? 430 : 444;
  const thumbnailSize = size === 'small' ? 271 : 286;

  return (
    <div
      className="p-[18px] border-2 border-transparent rounded-md bg-grayscale-800 hover:border-gray-700 hover:bg-grayscale-900"
      style={{ height: `${cardHeight}px` }}
    >
      <div
        className="bg-grayscale-700 rounded-md mb-[17px]"
        style={{ width: `${thumbnailSize}px`, height: `${thumbnailSize}px` }}
      >
        <img src={image} alt={name} />
      </div>

      <h5 className="text-brand-primary-light">{name}</h5>

      <div className="w-full border-b-2 border-grayscale-700 my-[17px]"></div>

      <div className="flex items-center justify-between">
        <div>
          <div className="mb-[5px] text-xs text-grayscale-300">Estimated value</div>
          <div className="font-semibold text-grayscale-100">≈ {estimatedValue}</div>
        </div>

        <button
          className={`py-[6px] text-grayscale-200 px-[16px] ${rarityToColorMap[rarity]} flex gap-2 font-semibold text-sm h-fit rounded-md items-center`}
        >
          <IconSvg icon={rarityToIconMap[rarity] as any} />
          <span>{rarity}</span>
        </button>
      </div>
    </div>
  );
}

export default RewardCardQiibee;
