import IconSvg from 'src/components/common/ui/IconSvg';
import MultiversClaimBtn from './MultiversClaimBtn';
import MultiversEnterBtn from './MultiversEnterBtn';
import SyncLoader from 'react-spinners/SyncLoader';
import { useState } from 'react';
import BuyEgldTutorialModal from 'src/components/common/modal/BuyEgldTutorialModal';
import XPortalTutorialModal from 'src/components/common/modal/XPortalTutorialModal';
import XPortalSwapModal from 'src/components/common/modal/XPortalSwapModal';

export type CardStatus = 'Done' | 'To Do' | 'claimable';
interface Props {
  status: CardStatus;
  bgImage: string;
  pointsToEarn: string;
  phase: string;
  title: string;
  description: string;
  slug: string; // Add slug to identify the card
  currentSlug: string; // Add slug to identify the card
  onEnter: (slug: string) => void; // Pass slug to handle the click
  handleConnectX: () => void; // Pass slug to handle the click
  onClaim: (slug: string) => void;
  isClaimed?: boolean;
  twitterConnected?: boolean;
  isEnterLoading?: boolean;
  isLoggedInMultiversX?: boolean;
  user?: any;
}

export default function MultiversPointsCard({
  status,
  bgImage,
  phase,
  title,
  description,
  slug,
  user,
  currentSlug,
  pointsToEarn,
  onEnter,
  handleConnectX,
  onClaim,
  isClaimed,
  twitterConnected,
  isEnterLoading,
  isLoggedInMultiversX,
}: Props) {
  console.log('pooints card user', user);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const [isEgldTutorialModalOpen, setIsEgldTutorialModalOpen] = useState(false);
  const [isSwapTutorialModalOpen, setIsSwapTutorialModalOpen] = useState(false);

  return (
    <>
      <XPortalTutorialModal
        isOpen={isTutorialModalOpen}
        onClose={setIsTutorialModalOpen}
        handleEnter={() => {
          onEnter(slug);
          setIsTutorialModalOpen(false);
        }}
        steps={[
          {
            step: 1,
            title: 'Download xPortal on iOS/Android',
            image: '/assets/images/tutorial/step-1.png',
            link: 'https://xportal.com/app',
            linkText: 'Download app',
          },
          {
            step: 2,
            title: 'Create or import your wallet on xPortal',
            image: '/assets/images/tutorial/step-2.png',
          },
          {
            step: 3,
            title: 'Connect Wallet to Looty',
            image: '/assets/images/tutorial/step-3.png',
            button: true,
            buttonText: !isLoggedInMultiversX ? 'Connect wallet' : 'Leaderboard',
          },
        ]}
      />

      <BuyEgldTutorialModal
        isOpen={isEgldTutorialModalOpen}
        handleEnter={() => {
          onEnter(slug);
          setIsEgldTutorialModalOpen(false);
        }}
        onClose={setIsEgldTutorialModalOpen}
        steps={[
          {
            step: 1,
            title: 'Buy $EGLD using xportal',
            image: '/assets/images/tutorial/egld-step-1.png',
          },
          {
            step: 2,
            title: 'The more you buy, the more you earn',
            image: '/assets/images/tutorial/egld-step-2.png',
          },
          {
            step: 3,
            title: 'Hold $EGLD for 72h to earn your points',
            image: '/assets/images/tutorial/egld-step-3.png',
            button: true,
            buttonText: 'Leaderboard',
          },
        ]}
      />

      <XPortalSwapModal
        isOpen={isSwapTutorialModalOpen}
        handleEnter={() => {
          onEnter(slug);
          setIsSwapTutorialModalOpen(false);
        }}
        onClose={setIsSwapTutorialModalOpen}
        steps={[
          {
            step: 1,
            title: 'SWAP WITHIN xportal',
            image: '/assets/images/tutorial/swap-1.png',
          },
          {
            step: 2,
            title: 'The more you swap, the more you earn',
            image: '/assets/images/tutorial/swap-2.png',
          },
          {
            step: 3,
            title: 'swap minimum $200 to start earning points',
            image: '/assets/images/tutorial/swap-3.png',
            button: true,
            buttonText: 'Leaderboard',
          },
        ]}
      />
      <div className="relative p-5 rounded-md bg-grayscale-800 pb-[101px] lg:min-h-[687px]">
        <div
          className="h-[271px] w-full p-4 mb-5"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className={`py-2 px-[15px] rounded-md mb-3 flex gap-1.5 items-center border w-fit ${
              status === 'To Do'
                ? 'bg-system-warning-dark border-system-warning'
                : 'bg-system-success-dark border-system-success'
            }`}
          >
            <IconSvg
              icon={status === 'To Do' ? 'comingSoonS' : 'liveS'}
              className={status === 'To Do' ? 'text-system-warning' : 'text-system-success'}
            />
            <span className="text-sm capitalize text-grayscale-100">{status}</span>
          </div>
        </div>

        <div className="mb-2.5">
          <div className="mb-1 text-xs text-grayscale-400">{phase}</div>
          <h4 className="text-lg text-brand-primary-light">{title}</h4>
        </div>

        <p className="overflow-hidden text-sm text-grayscale-400">{description}</p>

        <div className="absolute right-5 bottom-5 left-5">
          <div className="border-t-2 border-grayscale-700 my-[17px]"></div>

          <div className="flex justify-between items-center">
            <div className="space-y-[5px]">
              <div className="text-xs text-grayscale-400">Points to earn {isEnterLoading}</div>
              <div className="text-grayscale-100">{pointsToEarn}</div>
            </div>

            {isEnterLoading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <>
                {(slug === 'follow-multiversx-on-x' ||
                  slug === 'follow-looty-fi-on-x' ||
                  slug === 'follow-xalliancedao-on-x' ||
                  slug === 'follow-xportalapp-on-x') &&
                !isClaimed &&
                !twitterConnected ? (
                  <MultiversEnterBtn onClick={() => handleConnectX()} title={`Connect X`} />
                ) : slug === 'buy-egld' ||
                  slug === 'xportal-wallet-creation' ||
                  slug === 'xportal-swap' ? (
                  <>
                    {user?.buyEgldPending && slug === 'buy-egld' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          Processing...
                        </p>

                        <p className="text-brand-secondary text-[10px] font-normal text-right">
                          Task validated only if 5 $EGLD held for 72h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'buy-egld' ? (
                          <MultiversEnterBtn
                            onClick={() => setIsEgldTutorialModalOpen(true)}
                            title={'Enter'}
                          />
                        ) : null}
                      </>
                    )}

                    {user?.xPortalWalletCreationPending && slug === 'xportal-wallet-creation' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          Processing...
                        </p>
                        <p className="text-brand-secondary text-[10px] font-normal">
                          Task will be validated in less than 48h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'xportal-wallet-creation' ? (
                          <MultiversEnterBtn
                            onClick={() => setIsTutorialModalOpen(true)}
                            title={'Enter'}
                          />
                        ) : null}
                      </>
                    )}

                    {user?.xPortalSwapPending && slug === 'xportal-swap' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-brand-secondary text-sm font-bold text-right">
                          Processing...
                        </p>
                        <p className="text-brand-secondary text-[10px] font-normal">
                          Task will be validated in less than 48h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'xportal-swap' ? (
                          <MultiversEnterBtn
                            onClick={() => setIsSwapTutorialModalOpen(true)}
                            title={'Enter'}
                          />
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!isClaimed && status === 'To Do' && (
                      <MultiversEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                    )}
                  </>
                )}
              </>
            )}

            {!isClaimed && status === 'claimable' && (
              <MultiversClaimBtn status="claimable" onClick={() => onClaim(slug)} />
            )}

            {isClaimed && (
              <MultiversClaimBtn
                status="claimed"
                onClick={() => {
                  console.log('MultiversClaimBtn -> isClaimed');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
