import IconSvg from 'src/components/common/ui/IconSvg';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import React, { useEffect, useRef, useState } from 'react';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { ethers } from 'ethers';

import CollectionOpenBoxBanner from './components/CollectionOpenBoxBanner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { Collection } from 'src/components/common/cards/types/types';
import RaffleTimerModal from 'src/components/common/modal/RaffleTimerModal';
import RaffleClaimKeysModal from 'src/components/common/modal/RaffleClaimKeysModal';
import NoKeysToClaimModal from 'src/components/common/modal/NoKeysToClaimModal';
import RewardCardQiibee from 'src/components/common/cards/RewardCardQiibee';
import SuccessMintModal from 'src/components/common/modal/SuccessMintModal';
import SuccessRedeemedModal from 'src/components/common/modal/SuccessRedeemedModal';
import {
  useAccount,
  useSignMessage,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import { toast } from 'react-toastify';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';
import { ConnectWallet } from '../../components/common/ConnectWallet.tsx';

import LootyAbi from '../../contracts/LootyQiibeeABI.json';
import LootyKeyABI from '../../contracts/LootyKeyABI.json';
import PoolSelect from 'src/components/common/PoolSelect.tsx';

const lootyContractConfig = {
  address: process.env.REACT_APP_LOOTY_QIIBEE_CONTRACT_ADDRESS as `0x${string}`, // Use environment variable for contract address
  abi: LootyAbi, // The ABI of the contract
};

const lootyKeyContractConfig = {
  address: process.env.REACT_APP_LOOTY_KEY_CONTRACT_ADDRESS as `0x${string}`, // Use environment variable for contract address
  abi: LootyKeyABI, // The ABI of the contract
};

const KEY_NAMES = {
  0: 'Bronze Key',
  1: 'Silver Key',
  2: 'Gold Key',
  3: 'Platinum Key',
  4: 'Diamond Key',
} as const;

interface RewardEvent {
  user: string;
  requestId: string;
  reward: string;
}

function CollectionDetail() {
  const location = useLocation();
  const { address } = useAccount();

  const { sendRequest } = useHttp();
  const { id } = location.state || {};
  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [selectedKey, setSelectedKey] = useState<number | null>(null);

  const [isRaffleTimerModalOpen, setIsRaffleTimerModalOpen] = useState(false); // Track RaffleTimerModal state
  const [isRaffleClaimKeysModalOpen, setIsRaffleClaimKeysModalOpen] = useState(false); // Track RaffleClaimKeysModal state
  const [walletData, setWalletData] =
    useState<{ address: string; keys: Record<number, number> } | null>(null); // Store wallet data

  const [currentModalName, setCurrentModalName] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWalletAlreadyMinted, setIsWalletAlreadyMinted] = useState<boolean | null>(null);
  const [isMintSuccessModalOpen, setIsMintSuccessModalOpen] = useState(false);
  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);

  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage();

  const { isConnected, chain } = useAccount();
  const { switchChain } = useSwitchChain();
  const [transactionMessage, setTransactionMessage] = useState('');
  const [isMintingInProgress, setIsMintingInProgress] = useState(false);

  const [loading, setLoading] = useState(true); // Add loading state

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const [rewardEvents, setRewardEvents] = useState([]);
  const [rewardValue, setRewardValue] = useState('0'); // State to hold the reward value

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [formattedEvents, setFormattedEvents] = useState<RewardEvent[]>([]);

  const { data: hash, error, isPending, writeContract } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
  } = useWriteContract();

  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  useEffect(() => {
    if (isConfirmingOpenBox) {
      setTransactionMessage('Transaction in progress...'); // Update progress message
    }

    if (isConfirmedOpenBox && receiptOpenBox) {
      setTransactionMessage('Openbox Successful.');
      setIsMintSuccessModalOpen(true);
      toast.success('Openbox Successful.', { autoClose: 6000 });

      // Fetch Reward events for the user
      const fetchRewardEvents = async () => {
        try {
          const { ethereum } = window;
          const provider = new ethers.providers.Web3Provider(ethereum);
          const lootyQiibeeContract = new ethers.Contract(
            lootyContractConfig.address,
            lootyContractConfig.abi,
            provider,
          );

          const filter = lootyQiibeeContract.filters.Reward(address);
          const events = await lootyQiibeeContract.queryFilter(filter);

          const formattedEvents = events.map(event => ({
            user: event?.args?.roller,
            requestId: event?.args?.requestId.toString(),
            reward: event?.args?.reward.toString(),
          }));
          console.log('rrrrrrrrrrrrrr', formattedEvents.length);
          setRewardValue(formattedEvents[formattedEvents.length - 1]?.reward || '0'); // Set the first reward or default to '0'
          // TODO: assign a code on backend
          assignCodeToRequestHandler(formattedEvents[formattedEvents.length - 1]?.requestId);
        } catch (error) {
          console.error('Error fetching Reward events:', error);
        }
      };

      fetchRewardEvents();
    }
  }, [isConfirmingOpenBox, isConfirmedOpenBox, receiptOpenBox]);

  const isWalletMintedHandler = () => {
    setTimeout(() => {
      //TODO: we add a smart contract call to check the wallet is already minted or not
      setIsWalletAlreadyMinted(false);
    }, 2000);
  };

  useEffect(() => {
    if (address) {
      isWalletMintedHandler();
      getWalletKeysData();
      readRewardBalance(address);
    }
  }, [address]);

  useEffect(() => {
    // const futureDate = new Date(process.env.REACT_APP_RAFFLE_DATE || '');
    const futureDate = new Date('2024-09-12T04:19:00');
    const now = new Date();

    console.log('futureDate', futureDate);
    console.log('now', now);

    if (now >= futureDate) {
      if (isWalletAlreadyMinted != null && isWalletAlreadyMinted === false) {
        setCurrentModalName('mintModal');
        setIsRaffleClaimKeysModalOpen(true);
      }
    } else {
      setCurrentModalName('raffleModal');
      setIsRaffleTimerModalOpen(true);
    }

    getCollectionDetail();
  }, [id, isWalletAlreadyMinted]);

  const handleModalClose = (value: boolean) => {
    setIsModalOpen(false);
  };

  const data = [
    {
      cover: '/assets/images/credit.png',
      credits: 20,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 30,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 45,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 60,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 80,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 100,
    },
    {
      cover: '/assets/images/credit.png',
      credits: 150,
    },
  ];

  const getRarity = (reward: number) => {
    if (reward === 20 || reward === 30 || reward === 45) {
      return 'Common';
    } else if (reward === 60 || reward === 80) {
      return 'Uncommon';
    } else if (reward === 100 || reward === 150) {
      return 'Rare';
    }
    return 'Common'; // Default to Common if no match
  };

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections/${id}`,
        method: 'GET',
      },
      (data: any) => {
        setCollectionDetail(data);
      },
    );
  };

  useEffect(() => {
    getCollectionDetail();
  }, [id]);

  const openRedeemedModal = () => {
    setIsMintSuccessModalOpen(false);
    setIsSuccessRedeemedModalOpen(true);
  };

  useEffect(() => {
    if (isConfirming) {
      setTransactionMessage('Transaction in progress...'); // Update progress message
    }

    if (isConfirmed && receipt) {
      setTransactionMessage('Congratulations! Mint Successful.');
      setIsRaffleClaimKeysModalOpen(false);
      toast.success('Congratulations! Mint Successful.', { autoClose: 6000 });
    }
  }, [isConfirming, isConfirmed, receipt]);

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};

    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 5; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  useEffect(() => {
    if (isConnected) {
      fetchBlockchainKeyBalances();
    }
  }, [address, isConnected]);

  const handleMintKeys = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }

    if (!walletData || !walletData.keys) {
      toast.error('No keys available to mint.');
      return;
    }

    const keysOfEachType = Object.values(walletData.keys); // Extract keys from walletData state

    try {
      setIsMintingInProgress(true);
      setTransactionMessage('Transaction in progress...');

      const messageHash = ethers.utils.solidityKeccak256(
        ['address', 'uint256', 'uint256[]'],
        [walletData.address, keysOfEachType.length, keysOfEachType],
      );

      const signature = await signMessageAsync({
        message: ethers.utils.hexlify(ethers.utils.arrayify(messageHash)),
      });

      const response = await writeContract({
        ...lootyContractConfig,
        functionName: 'mint',
        args: [signature, keysOfEachType],
      });

      // TODO: how can we manage to reload the keys after the mint process is completed

      console.log('response', response);
      console.log('error', error);
    } catch (error: any) {
      console.error('Mint NFT error:', error);
      setTransactionMessage('Minting failed. Please try again.');
      toast.error(error?.message || 'Minting failed.');
      setIsMintingInProgress(false);
    }
  };

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        lootyKeyContractConfig.address,
        lootyKeyContractConfig.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);

      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const readRewardBalance = async (walletAddress: string) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const lootyQiibeeContract = new ethers.Contract(
        lootyContractConfig.address,
        lootyContractConfig.abi,
        provider,
      );

      const filter = lootyQiibeeContract.filters.Reward(walletAddress);
      const events = await lootyQiibeeContract.queryFilter(filter);

      console.log(
        events.map(event => ({
          user: event?.args?.roller,
          requestId: event?.args?.requestId.toString(),
          reward: event?.args?.reward.toString(),
        })),
      );

      const formattedEvents = events.map(event => ({
        user: event?.args?.roller,
        requestId: event?.args?.requestId.toString(),
        reward: event?.args?.reward.toString(),
      }));

      setFormattedEvents(formattedEvents);

      console.log('events', events);
    } catch (error) {
      console.error('Error reading rewards:', error);
      return 0;
    }
  };

  const getWalletKeysData = async () => {
    if (address) {
      sendRequest(
        {
          url: `get-wallet-data/${address}`, // Example endpoint
          method: 'GET',
        },
        async (response: any) => {
          setWalletData({ address: response.address, keys: response.keys });
        },
      );
    }
  };

  const assignCodeToRequestHandler = async (requestId: string) => {
    if (address) {
      sendRequest(
        {
          url: `codes/assign`, // Example endpoint
          method: 'POST',
          payload: { requestId },
        },
        async (response: any) => {
          setWalletData({ address: response.address, keys: response.keys });
        },
      );
    }
  };

  const handleRaffleTimeOver = async () => {
    if (address) {
      setIsRaffleTimerModalOpen(false); // Close RaffleTimerModal
      setIsRaffleClaimKeysModalOpen(true); // Open RaffleClaimKeysModal
      setCurrentModalName(!isWalletAlreadyMinted ? 'mintModal' : null);
    }
  };

  const startCarouselAnimation = (targetCredits: number) => {
    if (!stackedCarouselRef.current) return;

    const steps = 30; // Total steps to slow down
    const initialInterval = 50; // Start fast
    const finalInterval = 2000; // Slow end

    let currentStep = 0;
    const targetSlideIndex = data.findIndex(slide => slide.credits === targetCredits);

    if (targetSlideIndex === -1) {
      console.error('No slide found with the specified credits.');
      return;
    }

    // Calculate stopping index (3-4 slides before the target)
    const stoppingSlideIndex = targetSlideIndex;

    const moveCarousel = () => {
      stackedCarouselRef.current?.goNext();

      currentStep++;

      // Calculate the current visible slide using activeSlideIndex
      const currentVisibleIndex = (activeSlideIndex + currentStep) % data.length;

      // Stop when we reach the calculated stopping slide index
      // if (currentVisibleIndex === stoppingSlideIndex || currentStep > steps) {
      //   return; // Stop the carousel
      // }

      // Easing function for smooth slowdown
      const easingFactor = Math.pow(currentStep / steps, 2);
      const currentInterval = initialInterval + easingFactor * (finalInterval - initialInterval);

      setTimeout(moveCarousel, currentInterval);
    };

    moveCarousel();
  };

  const openLootbox = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }

    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    try {
      console.log(`Opening lootbox with key ID ${selectedKey}...`);
      startCarouselAnimation(100);
      const tx = await openLootboxContractWrite({
        ...lootyContractConfig,
        functionName: 'open',
        args: [selectedKey],
      });

      console.log('Lootbox opened. Receipt:', tx);
    } catch (error) {
      console.error('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  const startSpin = () => {
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    setSpinning(true);

    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  // Function to stop spinning at the target credits
  const stopSpin = (targetCredits: number) => {
    if (!spinning) return;
    if (spinIntervalRef.current) {
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }
    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      // Swipe to the specific slide
      const currentSlideIndex = activeSlideIndex;
      const steps = (targetSlideIndex - currentSlideIndex + data.length) % data.length; // Calculate correct steps
      stackedCarouselRef.current.swipeTo(steps);
    }
  };

  return (
    <>
      <NoKeysToClaimModal isOpen={false} onClose={() => console.log('No keys to claim')} />

      <SuccessMintModal
        openRedeemedModal={openRedeemedModal}
        isOpen={isMintSuccessModalOpen}
        onClose={() => setIsMintSuccessModalOpen(false)}
        reward={rewardValue}
      />

      <SuccessRedeemedModal
        isOpen={isSuccessRedeemedModalOpen}
        onClose={() => setIsSuccessRedeemedModalOpen(false)}
        selectedRequest={'123'} // TODO: make it dunamic
      />

      <section className="mb-[40px]">
        <CollectionOpenBoxBanner collection={collectionDetail} />
      </section>

      {isConnected ? (
        <>
          {currentModalName === 'mintModal' ? (
            <RaffleClaimKeysModal
              isPending={isPending}
              isConfirming={isConfirming}
              isOpen={isRaffleClaimKeysModalOpen}
              claimKeysHandler={handleMintKeys}
              onClose={() => setIsRaffleClaimKeysModalOpen(false)}
              walletData={walletData}
            />
          ) : null}

          {currentModalName === 'raffleModal' ? (
            <RaffleTimerModal
              isOpen={isRaffleTimerModalOpen}
              onClose={handleModalClose}
              onTimeOver={handleRaffleTimeOver}
            />
          ) : null}

          <section className="mb-[72px]">
            <div className="mb-10">
              <ResponsiveContainer
                carouselRef={stackedCarouselRef}
                render={(parentWidth, carouselRef) => {
                  const currentVisibleSlide = 7;

                  return (
                    <StackedCarousel
                      ref={carouselRef}
                      slideComponent={Card}
                      slideWidth={268}
                      carouselWidth={parentWidth}
                      data={data}
                      currentVisibleSlide={currentVisibleSlide}
                      maxVisibleSlide={7}
                      disableSwipe
                      onActiveSlideChange={activeSlide => {
                        setActiveSlideIndex(activeSlide);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="space-y-[32px] flex flex-col items-center justify-center">
              <div className="flex gap-[18px] items-center justify-center">
                {walletData &&
                  Object.keys(walletData.keys).length > 0 &&
                  Object.keys(walletData.keys).map((key, index) => (
                    <div
                      key={index}
                      onClick={() => handleCardClick(Number(key))}
                      className={`w-[268px] rounded-md bg-grayscale-800 p-[15px] pb-[17px] cursor-pointer ${
                        selectedKey === Number(key) ? 'border-2 border-purple-500' : ''
                      }`}
                    >
                      <img
                        src={`/assets/images/keys/${Number(key)}.png`}
                        className="w-[238px] h-[238px] rounded mb-[18px]"
                        alt={`Key ${key}`}
                      />

                      <div className="space-y-[9px]">
                        <h5 className="uppercase text-[#FAFAFA]">
                          {`${KEY_NAMES[key as unknown as keyof typeof KEY_NAMES]}`}
                        </h5>

                        <div className="text-xs text-gray-300 space-x-[7px]">
                          <span>Ready to use:</span>
                          <span className="font-semibold pl-[7px]">
                            {blockchainKeyBalances[Number(key)] ?? 0}{' '}
                            {/* Display blockchain balance */}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <p className="text-[#D7CCE0] text-center">
                Open box using your keys and get rewards from your favorite project. <br /> Your NFT
                will be burned once you use your first key. Every key will get you one reward.
              </p>

              <div className="mx-auto w-fit">
                {isConfirmingOpenBox ? (
                  <SyncLoader color="#B73FFF" size={10} />
                ) : (
                  <Button size="m" type="primary" onClick={() => openLootbox()}>
                    Open box
                  </Button>
                )}
              </div>
            </div>
          </section>

          <section>
            <div className="mb-[36px] flex justify-between items-center">
              <h3 className="text-brand-primary-light">Rewards</h3>

              <PoolSelect />
            </div>

            <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
              {formattedEvents.map((event, index) => (
                <RewardCardQiibee
                  key={index}
                  name={`${event.reward} credits`} // Pass reward value
                  estimatedValue={event.reward} // Use reward as estimated value
                  rarity={getRarity(Number(event.reward))} // Dynamically calculate rarity
                  image="/assets/images/credit.png" // Use a static or dynamic image path
                  size="medium"
                />
              ))}
            </div>
          </section>
        </>
      ) : (
        <div className={`text-center`}>
          <ConnectWallet />
        </div>
      )}
    </>
  );
}

export default CollectionDetail;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, credits } = data[dataIndex];

  return (
    <div className="w-[268px] h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="w-[238px] h-[238px] bg-grayscale-700 rounded relative"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          <IconSvg icon="rareS" className="text-rarity-rare-light" />
          <div>{credits} credits</div>
        </div>
      </div>
    </div>
  );
});
