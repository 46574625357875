import { Route, Routes, useLocation } from 'react-router-dom';
import AppLayout from 'src/layout/AppLayout';
import Home from 'src/pages/home/Home';
import MintList from 'src/pages/mint/MintList';
import MintProject from 'src/pages/mint/MintProject';
import OpenBox from 'src/pages/openbox/OpenBox';
import ProfileKey from 'src/pages/profile/ProfileKey';
import CollectionDetail from 'src/pages/openbox/CollectionDetail';
import RaffleList from 'src/pages/raffle/RaffleList';
import RaffleDetail from 'src/pages/raffle/detail/RaffleDetail';
import RaffleCollectionDetail from 'src/pages/raffle/openbox/RaffleCollectionDetail';
import QiibeeCollectionDetail from 'src/pages/qiibee/openbox/QiibeeCollectionDetail';
import AvaxCollectionDetail from 'src/pages/avax/openbox/AvaxCollectionDetail';
import ArenaCollectionDetail from 'src/pages/arena/openbox/ArenaCollectionDetail';
import Leaderboard from 'src/pages/multiverx/Leaderboard';
import MultiverxDetail from 'src/pages/multiverx/MultiverxDetail';
import { useLayoutEffect } from 'react';
import DiscordCallback from 'src/pages/discord/DiscordCallback';
import TwitterCallback from 'src/pages/twitter/TwitterCallback';
import ArenaMintProject from 'src/pages/mint/ArenaMintProject';
import YoutubeCallback from 'src/pages/youtube/YoutubeCallback';
import VideoPage from 'src/pages/youtube/video/VideoPage';
import VideoPage2 from "../pages/youtube/video/VideoPage2.tsx";
import VideoPage3 from 'src/pages/youtube/video/VideoPage3.tsx';
import VideoPage4 from 'src/pages/youtube/video/VideoPage4.tsx';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    const mainElement = document.getElementById('app-layout-main');

    if (mainElement) {
      mainElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }
  }, [location.pathname]);

  return children;
};

export default function AppRouter() {
  return (
    <Wrapper>
      <Routes>
        {/* <Route path="/" element={<ComingSoon />} /> */}

        <Route path="/" element={<AppLayout />}>
          <Route index element={<Home />} />

          <Route path="mint">
            <Route index element={<MintList />} />
            <Route path=":id" element={<MintProject />} />
          </Route>

          <Route path="arena">
            <Route path=":id/:stageId" element={<ArenaMintProject />} />
          </Route>

          <Route path="openbox">
            <Route index element={<OpenBox />} />
            <Route path=":collectionName" element={<CollectionDetail />} />
          </Route>

          <Route path="qiibee">
            <Route path="openbox/:collectionName" element={<QiibeeCollectionDetail />} />
          </Route>

          <Route path="avax">
            <Route path="openbox/:collectionName" element={<AvaxCollectionDetail />} />
          </Route>

          <Route path="raffle">
            <Route index element={<RaffleList />} />
            <Route path="list/:collectionName" element={<RaffleDetail />} />
            <Route path="openbox/:collectionName" element={<RaffleCollectionDetail />} />
          </Route>
          <Route path="arena">
            <Route path="openbox/:collectionName" element={<ArenaCollectionDetail />} />
          </Route>

          <Route path="profile">
            <Route path="keys" element={<ProfileKey />} />
          </Route>

          <Route path="leaderboards">
            <Route index element={<Leaderboard />} />
            <Route path=":collectionSlug" element={<MultiverxDetail />} />
          </Route>

          <Route path="auth/discord/callback" element={<DiscordCallback />} />
          <Route path="auth/twitter/callback" element={<TwitterCallback />} />
          <Route path="auth/youtube/callback" element={<YoutubeCallback />} />
          <Route path="/video/what-is-egld" element={<VideoPage />} />
          <Route path="/video/the-only-crypto-wallet-you-will-ever-need" element={<VideoPage2 />} />
          <Route path="/video/what-is-sharding" element={<VideoPage3 />} />
          <Route path="/video/how-ai-is-changing-the-world" element={<VideoPage4 />} />
        </Route>
      </Routes>
    </Wrapper>
  );
}
