import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Collection } from 'src/components/common/cards/types/types';
import Countdown from 'src/components/common/Countdown';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';

interface Props {
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
}

const getIconName = (networkCurrency: string): Icons => {
  switch (networkCurrency) {
    case 'AVAX':
      return 'avaxS';
    case 'ETH':
      return 'ethS';
    case 'BNB':
      return 'bnbS';
    case 'EGLD':
      return 'egld';
    case 'SOL':
      return 'solS';
    case 'SUI':
      return 'suiS';
    default:
      return 'ethS';
  }
};

function MintCard({ collection, handleClick }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const iconName = getIconName(collection.network.networkCurrency);
  const navigate = useNavigate();
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  // const isSoon = collection?.stages[0]?.isSoon;

  const randomNumber = Math.floor(Math.random() * 3) + 1;

  return (
    <>
      <div
        className={`py-5 px-[19.5px] rounded-md ${
          isAccordionOpen ? 'bg-grayscale-900 border-2 border-grayscale-800' : 'bg-grayscale-800'
        }`}
      >
        <div
          className="bg-grayscale-900 w-full h-[262px] rounded-md mb-[16px] relative p-3 flex justify-between items-end"
          style={{
            backgroundImage: `url(${
              collection?.isEarnBlured
                ? `/assets/images/blur-card-image-${randomNumber}.png`
                : collection.image
            })`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <img
            src={
              collection?.isEarnBlured
                ? `/assets/images/blur-card-logo-${randomNumber}.png`
                : collection?.logo
            }
            className="w-[60px] h-[60px]"
          />

          {!collection?.isEarnBlured ? (
            <button
              onClick={toggleAccordion}
              className={`flex items-center p-2 rounded-md gap-x-2 w-[153px] justify-center ${
                isAccordionOpen
                  ? 'bg-brand-primary-light text-grayscale-800'
                  : 'bg-grayscale-700 text-grayscale-200'
              }`}
            >
              <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

              <IconSvg
                icon="arrowRightM"
                className={`transition-transform duration-300 ${
                  isAccordionOpen ? '-rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                }`}
              />
            </button>
          ) : (
            <img src={`/assets/images/blur-button.png`} />
          )}
        </div>

        <div className="flex items-center space-x-2">
          {collection?.isEarnBlured ? (
            <img src={`/assets/images/blur-card-name.png`} alt="blur card name" />
          ) : (
            <>
              <h5 className="text-brand-primary-light">{collection?.name}</h5>

              <p className="mt-1 text-grayscale-200 text-xss">
                {collection?.network?.networkCurrency}
              </p>
            </>
          )}
        </div>

        <div className="w-full mt-4 mb-20 border-b-2 border-grayscale-700"></div>

        <div className="mb-[26px]"></div>

        <div className="flex items-end justify-between">
          {collection?.isEarnBlured ? (
            <img src={`/assets/images/blur-card-info-3.png`} alt="blur card rewards" />
          ) : (
            <>
              <div>
                <div className="mb-[5px] text-xs text-grayscale-300">Rewards value</div>

                <p className="font-semibold text-grayscale-100">
                  ≈ {Number(collection?.rewardsTotalValue).toLocaleString()}
                </p>
              </div>
            </>
          )}

          {collection?.isEarnBlured ? (
            <img src={`/assets/images/blur-card-info-2.png`} alt="blur card mint price" />
          ) : (
            <>
              <div>
                <div className="mb-[5px] text-xs text-grayscale-300">Mint price</div>

                <div className="flex items-center gap-[9px]">
                  <IconSvg icon={iconName} />

                  <p className="font-semibold text-grayscale-100">
                    Free
                    {/* ≈ {Number(collection?.rewardsTotalValue).toLocaleString()} */}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="flex flex-col items-end justify-end">
            {collection?.stages[0]?.isLive ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                <IconSvg icon="liveS" />
                <span className="font-normal text-xss text-grayscale-100">Live</span>
              </div>
            ) : collection?.stages[0]?.isSoon ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                <IconSvg icon="comingSoonS" />
                <span className="font-normal text-xss text-grayscale-100">Soon</span>
              </div>
            ) : collection?.stages[0]?.isExpired ? (
              <div className="mb-[5px] py-1 px-3 rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                <IconSvg icon="expired" />
                <span className="font-normal text-xss text-grayscale-100">Expired</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {isAccordionOpen && (
        <>
          {collection.stages
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((stage, index) => (
              <div
                key={index}
                className="bg-grayscale-900 py-5 px-[19.5px] rounded-md border-2 border-grayscale-800"
              >
                <div
                  className="bg-grayscale-900 w-full h-[262px] rounded-md mb-[16px] relative p-3 flex justify-between items-end"
                  style={{
                    backgroundImage: `url(${collection.image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                >
                  <img src={collection?.logo} className="w-[60px] h-[60px]" />

                  {/* {(() => {
                      if (stage.isExpired) {
                        // Expired project
                        return (
                          <Button type="ghost" size="xs" onClick={() => handleClick(collection, 'openbox')}>
                            Open Box
                          </Button>
                        );
                      } else if (collection.name === 'Qiibee' && stage.isLive) {
                        // Live project
                        return (
                          <Button type="ghost" size="xs">
                            Deposit
                          </Button>
                        );
                      } else if (collection.name !== 'Qiibee' && stage.isLive) {
                        // Live project
                        return (
                          <Button type="primary" size="xs" onClick={() => handleClick(collection, 'mint')}>
                            Mint
                          </Button>
                        );
                      } else {
                        // Soon project
                        return (
                          <Button
                            type="secondary"
                            size="xs"
                            onClick={() => handleClick(collection, 'mint')}
                          >
                            Join
                          </Button>
                        );
                      }
                    })()} */}
                  {stage?.isLive && (
                    <div className="flex items-center justify-end col-span-2">
                      <Button
                        onClick={() => navigate(`/arena/${collection.id}/${stage?.id}`)}
                        type="primary"
                        size="xs"
                        classNames="!min-w-[74px]"
                      >
                        Claim
                      </Button>
                    </div>
                  )}
                </div>

                <div className="flex items-center space-x-2">
                  <h5 className="text-brand-primary-light">{stage?.stageName}</h5>

                  <p className="mt-1 text-grayscale-200 text-xss">
                    {collection?.network?.networkCurrency}
                  </p>
                </div>

                <div className="w-full my-4 border-b-2 border-grayscale-700"></div>

                <div className="mb-[26px]">
                  <ProgressBar
                    progress={40}
                    size="Medium"
                    estimatedValue={0}
                    totalValue={collection?.rewardsTotalValue}
                  />
                </div>

                <div className="flex items-end justify-between">
                  <div>
                    <div className="mb-[5px] text-xs text-grayscale-300">Rewards value</div>

                    <p className="font-semibold text-grayscale-100">
                      ≈ {collection?.rewardsTotalValue}
                    </p>
                  </div>

                  <div>
                    <div className="mb-[5px] text-xs text-grayscale-300">Mint price</div>

                    <div className="flex items-center gap-[9px]">
                      <IconSvg icon={iconName} />

                      <p className="font-semibold text-grayscale-100">
                        ≈ {collection?.rewardsTotalValue}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col items-end justify-end">
                    {stage?.isLive ? (
                      <div className="mb-[5px] py-1 px-3 rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                        <IconSvg icon="liveS" />
                        <span className="font-normal text-xss text-grayscale-100">Live</span>
                      </div>
                    ) : stage?.isSoon ? (
                      <div className="mb-[5px] py-1 px-3 rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                        <IconSvg icon="comingSoonS" />
                        <span className="font-normal text-xss text-grayscale-100">Soon</span>
                      </div>
                    ) : stage?.isExpired ? (
                      <div className="mb-[5px] py-1 px-3 rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                        <IconSvg icon="expired" />
                        <span className="font-normal text-xss text-grayscale-100">Expired</span>
                      </div>
                    ) : null}

                    <div className="font-normal text-xss text-grayscale-300">
                      <Countdown stage={stage} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
}

export default MintCard;
